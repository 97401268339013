import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-400e0539"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "hello",
  style: {"color":"white"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.msg), 1),
    _createElementVNode("h2", null, _toDisplayString(_ctx.count), 1),
    _createElementVNode("span", null, "User Profile " + _toDisplayString(_ctx.userProfile), 1),
    _createElementVNode("p", null, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.count++))
      }, "test")
    ]),
    _createElementVNode("p", null, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setToken && _ctx.setToken(...args)))
      }, "test")
    ]),
    _createElementVNode("p", null, [
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.testnet && _ctx.testnet(...args)))
      }, "test network")
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.publicTyres, (tyre) => {
      return (_openBlock(), _createElementBlock("p", {
        key: tyre.name
      }, _toDisplayString(tyre), 1))
    }), 128)),
    _createTextVNode(" " + _toDisplayString(_ctx.token), 1)
  ]))
}