
import { CarLincence } from "@/network/api";
import { Options, Vue } from "vue-class-component";
import Spinner from "./Spinner.vue";
import SubmissionErrorModal from "@/components/SubmissionErrorModal.vue";
import {
  CarLicenceModelsQuoteSingleVendRequestModel,
  VehiclesModelsVehicleModel,
  ProfileModelsProfileModel,
} from "@/api-client";
import { store } from "@/store";

declare interface AddressType {
  id: number;
  name: string;
}

@Options({
  components: {
    SubmissionErrorModal,
    Spinner,
  },
  props: {
    vehicle: { default: null },
  },
  emits: ["showSpinner", "hideSpinner", "cancelShipping", "goToPayment"],
})
export default class RenewalShippingDetails extends Vue {
  visibleSpinner = false;

  payNowError?: string = "";

  vehicle?: VehiclesModelsVehicleModel;

  addressTypes: Array<AddressType> = [
    { id: 1, name: "Home" },
    { id: 2, name: "Business" },
  ];

  provinces: Array<string> = [
    "Eastern Cape",
    "Free State",
    "Gauteng",
    "Kwazulu-Natal",
    "Limpopo",
    "Mpumalanga",
    "Northern Cape",
    "North West",
    "Western Cape",
  ];

  selectedAddressType = this.addressTypes[0];

  vendRequest: CarLicenceModelsQuoteSingleVendRequestModel = {
    QuoteRequestModel: {},
    VendRequestModel: { NotifyCustomerBySMS: 3, Province: this.provinces[0] },
  };

  created() {
    this.payNowError = "";
  }

  cancel() {
    this.$emit("cancelShipping");
  }

  clearContainerMessages() {
    try {
      var saveErrors = document.getElementById(
        "shippingDetailsValidationErrorContainer"
      );
      if (saveErrors != null && saveErrors != undefined) {
        saveErrors.textContent = "";
      }
    } catch (errors) {
      console.log(errors);
    }
  }

  validateInput() {
    this.clearContainerMessages();
    var errorContainer = document.getElementById(
      "shippingDetailsValidationErrorContainer"
    );
    var errors = [];
    if (this.selectedAddressType.id == 2) {
      if (
        this.vendRequest.VendRequestModel?.BusinessName == undefined ||
        this.vendRequest.VendRequestModel?.BusinessName == ""
      ) {
        errors.push("Please enter your Business Name");
      }
    }
    if (
      this.vendRequest.VendRequestModel?.AddressLine2 == undefined ||
      this.vendRequest.VendRequestModel?.AddressLine2 == ""
    ) {
      errors.push("Please enter your Street Address");
    }
    if (
      this.vendRequest.VendRequestModel?.Town == undefined ||
      this.vendRequest.VendRequestModel?.Town == ""
    ) {
      errors.push("Please enter your City");
    }
    if (
      this.vendRequest.VendRequestModel?.PostalCode == undefined ||
      this.vendRequest.VendRequestModel?.PostalCode == ""
    ) {
      errors.push("Please enter your Postal Code");
    }
    if (
      this.vendRequest.VendRequestModel?.ContactNumber == undefined ||
      this.vendRequest.VendRequestModel?.ContactNumber == ""
    ) {
      errors.push("Please enter your Contact Number");
    }
    if (errors.length > 0) {
      for (var i = 0; i < errors.length; i++) {
        var p = document.createElement("p");
        p.style.color = "red";
        p.textContent = errors[i];
        errorContainer?.append(p);
      }
      return false;
    }
    return true;
  }

  handleForm() {
    this.payNowError = "";

    var userProfile = store.getters[
      "user/profile"
    ] as ProfileModelsProfileModel;
    var fullName = userProfile.FirstName + " " + userProfile.LastName;
    if (!this.vendRequest.VendRequestModel?.ContactNumber) {
      this.vendRequest.VendRequestModel!.ContactNumber =
        userProfile.ContactNumber;
    }
    if (!this.vendRequest.VendRequestModel?.RecepientContact) {
      this.vendRequest.VendRequestModel!.RecepientContact =
        this.vendRequest.VendRequestModel!.ContactNumber;
    }

    this.vendRequest.VendRequestModel!.ContactPerson = fullName;
    this.vendRequest.VendRequestModel!.RecepientName = fullName;
    this.vendRequest.VendRequestModel!.AddressType =
      this.selectedAddressType.id;

    this.vendRequest.QuoteRequestModel = {
      RegistrationNumber: this.vehicle?.LicenseNumber,
      Expiry: this.vehicle?.ExpiryDate,
      VehicleType: 0,
      Tare: this.vehicle?.Tare,
    };

    if (!this.validateInput()) {
      return;
    }

    CarLincence.apiPrivateCarLicenceSingleVendPost(this.vendRequest)
      .then((res) => {
        if (res) {
          CarLincence.apiPrivateCarLicencePaymentUrlByRegistrationNumberGet(
            this.vehicle!.LicenseNumber!,
            0
          )
            .then((res) => {
              if (res) {
                var redirectUrl = res.data;
                this.$emit("goToPayment", redirectUrl);
              }
            })
            .catch((error) => {
              console.error(error.response.data);
            });
        }
      })
      .catch((error) => {
        try {
          var rawError = error.response.data.split(";");
          this.payNowError = rawError.at(-1).trim();
        } catch (ex) {
          console.error(error, "catch error");
        }
        console.error(error.response.data);
      });
  }
}
