import { App } from "vue";
import { h } from "vue";

export interface FacebookSignInOptions {
  appId: string;
  cookie: boolean;
  xfbml: boolean;
  version: string;
}
/* eslint-disable no-console */
function err(msg: any) {
  if (typeof console !== "undefined") {
    console.error(`[vue-facebook-signin] ${msg}`);
  }
}

export default {
  install(app: App, options: FacebookSignInOptions) {
    // @ts-ignore
    let FB = window.FB;
    
    app.component("vue-facebook-signin", {
      name: "VueFacebookSignin",
      props: {
        className: {
          type: String,
          default: "vue-facebook-signin",
        },
        onResponse: {
          type: Function,
          default: null,
        },
      },
      computed: {
        dataBorder() {
          return this.border.toString();
        },
      },
      mounted() {
        const self = this;
        let facebookScript = document.createElement("script");
        facebookScript.setAttribute(
          "src",
          "https://connect.facebook.net/en_US/sdk.js"
        );
        document.head.appendChild(facebookScript);
        facebookScript.addEventListener("load", function () {
          // @ts-ignore
          FB = window.FB;
          if (!FB) {
            err(
              '"https://connect.facebook.net/en_US/sdk.js" needs to be included as a <script>'
            );
            return;
          }
          const { appId, cookie, xfbml, version } = options;

          if (!appId) {
            err("options.appId must be specified.");
            return;
          }

          FB.init({
            appId,
            cookie,
            xfbml,
            version,
          });
        });
      },
      methods: {
        login() {
          // @ts-ignore
          let FB = window.FB;
          var loginPromise = new Promise<any>(FB.login);
          loginPromise.then((authResponse) => {
              if(authResponse.accessToken){
                console.log("all good", authResponse);
                this.onResponse(authResponse);
              }
              this.onResponse(authResponse);
            })
            .catch((error) => {
              err(error);
              this.onResponse(error);
            });
        },
      },
      render() {
        return h(
          "div",
          {
            class: this.className,
            href: "#",
            onClick: () => {
              this.login();
            },
          },
          h("div", [this.$slots.default({})])
        );
      },
    });
  },
};
