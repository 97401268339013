
import {
  VehiclesModelsVehicleModel,
  CarLicenceModelsQuoteRequestModel,
  CarLicenceModelsQuoteResponseModel,
} from "@/api-client";
import { Vehicles, CarLincence } from "@/network/api";
import { Options, Vue } from "vue-class-component";
import RenewalQuote from "./RenewalQuote.vue";
import RenewalShippingDetails from "./RenewalShippingDetails.vue";
import RenewalPayment from "./RenewalPayment.vue";
import Spinner from "./Spinner.vue";
import SubmissionErrorModal from "@/components/SubmissionErrorModal.vue";

@Options({
  components: {
    RenewalQuote,
    RenewalShippingDetails,
    RenewalPayment,
    SubmissionErrorModal,
    Spinner,
  },
  props: {
    vehicleProp: { default: null },
    triggerNewVehicle: Number,
    newVehicle: Boolean,
    isMobile: Boolean,
  },
  emits: ["updateDetails", "deletedVehicle", "clearFields"],
})
export default class ViewVehicleDetails extends Vue {
  vehicleProp: VehiclesModelsVehicleModel = {
    Name: "",
    ExpiryDate: new Date().toISOString(),
    LicenseNumber: "",
    Tare: undefined,
  };
  vehicle: VehiclesModelsVehicleModel = { ...this.vehicleProp };
  buttonText = "Save";
  isMobile?: boolean;

  showQuote = false;
  quote?: CarLicenceModelsQuoteResponseModel;
  quoteError?: string = "";

  showShippingForm = false;

  showPaymentView = false;
  paymentRedirectUrl?: string;

  visibleSpinner = false;

  get parseDate() {
    return new Date(this.vehicle.ExpiryDate);
  }

  created() {
    this.$watch("vehicleProp", () => {
      this.showQuote = false;
      this.showShippingForm = false;
      this.showPaymentView = false;
      this.paymentRedirectUrl = undefined;
      this.quoteError = "";
      this.vehicle = { ...this.vehicleProp };
    });
  }

  saveForm() {
    this.saveVehicle();
  }

  acceptQuote() {
    this.showQuote = false;
    this.showShippingForm = true;
  }

  cancelShipping() {
    this.showQuote = true;
    this.showShippingForm = false;
  }

  goToPayment(url: string) {
    this.showShippingForm = false;
    window.open(url);
  }

  cancelPayment() {
    this.showShippingForm = true;
    this.showPaymentView = false;
  }

  clearErrorContainerMessages() {
    try {
      var container = document.getElementById(
        "renewLicenseErrorMessageContainer"
      );
      if (container != null && container != undefined) {
        container.textContent = "";
      }
    } catch (errors) {}
  }

  validateRenewalInput() {
    this.clearErrorContainerMessages();

    var errors = [];
    if (
      this.vehicle.Name == null ||
      this.vehicle.Name == undefined ||
      this.vehicle.Name == ""
    ) {
      errors.push("Please enter a valid Name for the vehicle");
    }
    if (
      this.vehicle.LicenseNumber == null ||
      this.vehicle.LicenseNumber == undefined ||
      this.vehicle.LicenseNumber == ""
    ) {
      errors.push("Please enter a valid Licence Number for the vehicle");
    }
    if (
      this.vehicle.ExpiryDate == null ||
      this.vehicle.ExpiryDate == undefined ||
      this.vehicle.ExpiryDate == "" ||
      this.vehicle.ExpiryDate == "T00:00:00"
    ) {
      errors.push("Please enter a valid date for the Licence Expiry");
    }
    if (
      this.vehicle.Tare == null ||
      this.vehicle.Tare == undefined ||
      this.vehicle.Tare.toString() == "" ||
      this.vehicle.Tare < 0
    ) {
      errors.push("Please enter a valid value for the vehicle's Tare");
    }

    if (errors.length > 0) {
      var errorContainer = document.getElementById(
        "renewLicenseErrorMessageContainer"
      );
      for (var i = 0; i < errors.length; i++) {
        var p = document.createElement("p");
        p.style.color = "red";
        p.textContent = errors[i];
        errorContainer?.append(p);
      }
      return false;
    }
    return true;
  }

  initRenewal() {
    if (!this.validateRenewalInput()) {
      return;
    }

    this.visibleSpinner = true;

    var quoteRequest = {
      RegistrationNumber: this.vehicle.LicenseNumber,
      Expiry: this.vehicle.ExpiryDate,
      VehicleType: 0,
      Tare: this.vehicle.Tare,
    };

    this.quoteError = "";

    CarLincence.apiPrivateCarLicenceQuotePost(
      quoteRequest as CarLicenceModelsQuoteRequestModel
    )
      .then((res) => {
        this.visibleSpinner = false;
        if (res) {
          this.quote = res.data as CarLicenceModelsQuoteResponseModel;
          if (this.quote) {
            this.showQuote = true;
          }
        } else {
          document.getElementById("triggerSubmissonModal")!.click();
        }
      })
      .catch((error) => {
        this.visibleSpinner = false;
        try {
          var rawError = error.response.data.split(";");
          this.quoteError = rawError.at(-1).trim();
          console.error(error.response.data);
        } catch (ex) {
          console.error(error);
        }
      });
  }

  expiryDateChanged(dateChangeEvent: InputEvent) {
    this.vehicle.ExpiryDate =
      (dateChangeEvent.target as HTMLInputElement).value + "T00:00:00";
  }

  saveVehicle() {
    this.visibleSpinner = true;
    Vehicles.apiPrivateVehiclesByIdPost(this.vehicle.Id!, this.vehicle)
      .then((res) => {
        console.log(res);
        if (res) {
          this.$emit("updateDetails");
        } else {
          document.getElementById("triggerSubmissonModal")!.click();
        }
      })
      .catch((error) => {
        console.error(error);
      });
    this.visibleSpinner = false;
  }

  cancelChanges() {
    this.clearErrorContainerMessages();
    this.$emit("clearFields");
  }
}
