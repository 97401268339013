
import {
  VehiclesModelsVehicleModel,
  VehiclesModelsVehicleModelWithId,
} from "@/api-client";
import { Vehicles } from "@/network/api";
import { Options, Vue } from "vue-class-component";
import ViewMileageLogs from "./ViewMileageLogs.vue";
import AddMileageLog from "./AddMileageLog.vue";
import ViewTyres from "./ViewTyres.vue";
import Spinner from "./Spinner.vue";
import router from "@/router";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal.vue";
import SubmissionErrorModal from "@/components/SubmissionErrorModal.vue";

@Options({
  components: {
    ViewMileageLogs,
    AddMileageLog,
    ViewTyres,
    DeleteConfirmationModal,
    SubmissionErrorModal,
    Spinner,
  },
  props: {
    vehicleProp: { default: null },
    triggerNewVehicle: Number,
    newVehicle: Boolean,
    isMobile: Boolean,
  },
  emits: ["updateDetails", "deletedVehicle", "clearFields"],
})
export default class ViewVehicleDetails extends Vue {
  vehicleProp: VehiclesModelsVehicleModel = {
    Name: "",
    ExpiryDate: new Date().toISOString(),
    LicenseNumber: "",
    VinNumber: "",
    Make: "",
    Model: "",
    Specification: "",
    Tare: undefined,
    Colour: "",
    EstimatedMonthlyMileage: undefined,
  };
  vehicle: VehiclesModelsVehicleModel = { ...this.vehicleProp };
  currentTab = "vehicle";
  newVehicle?: boolean;
  buttonText = "Save";
  isVehicleTab = true;
  triggerNewVehicle = 0;
  isMobile?: boolean;
  manufacturers: Array<VehiclesModelsVehicleModel> = [];
  models: Array<VehiclesModelsVehicleModelWithId> = [];
  specs: Array<VehiclesModelsVehicleModelWithId> = [];
  visibleSpinner = false;
  selectedManufacturer: any = {
    Id: 0,
    Name: "",
  };
  selectedModel: any = {
    Id: 0,
    Name: "",
  };
  selectedSpec: any = {
    Id: 0,
    Name: "",
  };

  get parseDate() {
    return new Date(this.vehicle.ExpiryDate);
  }

  get sortedMake() {
    //Sort manufacturers in alphabetical order
    return this.manufacturers.sort((a, b): number => {
      if (a.Name < b.Name) {
        return -1;
      }
      if (a.Name > b.Name) {
        return 1;
      }
      return 0;
    });
  }

  created() {
    this.getManufacturer();

    // Activate input fields when new vehicle selected
    this.$watch("triggerNewVehicle", () => {
      if (this.newVehicle) {
        this.setSaveButton();
        this.currentTab = "vehicle";
      }
    });

    this.$watch("vehicleProp", () => {
      this.vehicle = { ...this.vehicleProp };
    });

    this.$watch("selectedManufacturer", () => {
      // Clear previous models/ specs if vehicle changed
      this.models = [];
      this.specs = [];
      // Get models
      this.getModels();
    });

    this.$watch("selectedModel", () => {
      // Clear previous specs if vehicle changed
      this.specs = [];
      // Get specs
      this.getSpecs();
    });
  }

  handleTabChange(tab: string) {
    this.currentTab = tab;
    if (tab != 'vehicle') {
      this.buttonText = "Edit"
      this.isVehicleTab = false
    }
    else {
      this.buttonText = "Save";
      this.isVehicleTab = true
    }
  }

  setSaveButton() {
    this.buttonText == "Save";
  }

  cancelChanges() {
    this.$emit("clearFields");
    if (this.newVehicle) {
      this.selectedManufacturer = "";
      this.selectedModel = "";
      this.selectedSpec = "";
      this.getManufacturer();
    } else {
      this.vehicle = { ...this.vehicleProp };
    }
  }

  handleForm() {
    if (!this.newVehicle) {
      if (this.buttonText === "Edit") {
        // Enable fields & save/cancel buttons
        this.setSaveButton();
      } else if (this.buttonText === "Save") {
        // Save new vehicle/ update existing vehicle & reset buttons/ disable fields
        this.saveVehicle();
        this.setSaveButton();
      }
    } else {
      this.saveVehicle();
      this.setSaveButton();
    }
  }

  getManufacturer() {
    this.visibleSpinner = true;
    Vehicles.apiPrivateVehiclesManufacturersGet()
      .then((res) => {
        this.manufacturers = res.data as Array<VehiclesModelsVehicleModel>;
        this.visibleSpinner = false;
        console.log(this.manufacturers, "manufacturers");
      })
      .catch((error) => {
        console.log(error);
        this.visibleSpinner = false;
      });
  }

  getModels() {
    if (this.manufacturers.length) {
      this.visibleSpinner = true;
      Vehicles.apiPrivateVehiclesModelsGet(this.selectedManufacturer.Id)
        .then((res) => {
          this.models = res.data as Array<VehiclesModelsVehicleModelWithId>;
          this.visibleSpinner = false;
        })
        .catch((error) => {
          console.log(error);
          this.visibleSpinner = false;
        });
    }
  }

  getSpecs() {
    if (this.models.length) {
      this.visibleSpinner = true;
      Vehicles.apiPrivateVehiclesSpecificationsGet(
        Number(this.selectedModel.Id)
      )
        .then((res) => {
          this.specs = res.data as Array<VehiclesModelsVehicleModelWithId>;
          this.visibleSpinner = false;
          //console.log("specs", res);
        })
        .catch((error) => {
          console.log(error);
          this.visibleSpinner = false;
        });
    }
  }

  expiryDateChanged(dateChangeEvent: InputEvent) {
    this.vehicle.ExpiryDate =
      (dateChangeEvent.target as HTMLInputElement).value + "T00:00:00";
  }

  saveVehicle() {
    if (this.newVehicle) {
      if (
        this.selectedManufacturer.Name &&
        this.selectedModel.Name &&
        this.selectedSpec.Name
      ) {
        this.visibleSpinner = true;

        let newCreatedVehicle = {
          ...this.vehicle,
          Make: this.selectedManufacturer.Name,
          Model: this.selectedModel.Name,
          Specification: this.selectedSpec.Name,
        } as VehiclesModelsVehicleModel;

        Vehicles.apiPrivateVehiclesPut("", newCreatedVehicle)
          .then((res) => {
            console.log(res);
            if (res) {
              this.cancelChanges();
              if (this.isMobile) {
                router.push({ name: "MyVehicles" });
              }
              this.$emit("updateDetails");
            } else {
              document.getElementById("triggerSubmissonModal")!.click();
            }
          })
          .catch((error) => {
            console.error(error);
          });
        this.visibleSpinner = false;
      } else {
        document.getElementById("triggerSubmissonModal")!.click();
      }
    } else {
      this.visibleSpinner = true;
      Vehicles.apiPrivateVehiclesByIdPost(this.vehicle.Id!, this.vehicle)
        .then((res) => {
          console.log(res);
          if (res) {
            this.$emit("updateDetails");
          } else {
            this.buttonText = "Save";
            document.getElementById("triggerSubmissonModal")!.click();
          }
        })
        .catch((error) => {
          console.error(error);
        });
      this.visibleSpinner = false;
    }
    this.$emit("clearFields");
  }

  deleteVehicle() {
    this.visibleSpinner = true;
    Vehicles.apiPrivateVehiclesByIdDelete(this.vehicle.Id!)
      .then((res) => {
        console.log(res);
        this.selectedManufacturer = "";
        this.selectedModel = "";
        this.selectedSpec = "";
        this.buttonText = "Save";
        this.$emit("deletedVehicle");
        if (this.isMobile) {
          router.push({ name: "MyVehicles" });
        }
      })
      .catch((error) => {
        console.error(error);
      });
    this.visibleSpinner = false;
    this.$emit("clearFields");
  }
}
