
import { Options, Vue } from "vue-class-component";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import { store } from "@/store";
import { SocialProviders } from "@/store/modules/auth";

@Options({
  components: {
    HelloWorld,
  },
})
export default class Debug extends Vue {}
