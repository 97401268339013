
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    redirectUrl: String,
  },
  emits: ["cancelPayment", "completePayment"],
})
export default class RenewalPayment extends Vue {
  redirectUrl!: string;

  error?: string;
  frameHeight = "300px";

  created() {}

  cancel() {
    this.$emit("cancelPayment");
  }

  completePayment() {
    this.$emit("completePayment");
  }
}
