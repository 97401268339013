
import {
  DealersModelsDealerForMapModel,
  ProfileModelsProfileModel,
  WarrantyModelCurrency,
  WarrantyModelWarrantyMaintenanceModel,
  WarrantyModelWarrantyModel,
} from "@/api-client";
import { Warranty, Dealers } from "@/network/api";
import { Options, Vue } from "vue-class-component";
import SimpleTypeahead from "./SimpleTypeahead.vue";
import UploadImage from "./UploadImage.vue";
import SuccessModal from "@/components/SuccessModal.vue";
import { store } from "@/store";

@Options({
  components: {
    SuccessModal,
    UploadImage,
    SimpleTypeahead,
  },
  props: {
    selectedWarranty: { default: null },
    isMobile: Boolean,
  },
  emits: ["setVisibleSpinner", "maintenanceLogged", "cancelLog"],
})
export default class MaintenanceLogView extends Vue {
  user: ProfileModelsProfileModel = { FirstName: "", Email: "" };
  isMobile?: boolean;

  currencies: Array<WarrantyModelCurrency> = [];
  dealers: Array<DealersModelsDealerForMapModel> = [];

  currency?: WarrantyModelCurrency = undefined;
  dealer?: DealersModelsDealerForMapModel = {};

  maintenanceDate = "";

  selectedWarranty?: WarrantyModelWarrantyModel | null = null;

  maintenanceUpload: WarrantyModelWarrantyMaintenanceModel = {};

  created() {
    this.getCurrencies();

    this.user = store.getters["user/profile"] as ProfileModelsProfileModel;

    // this.$watch("selectedWarranty", () => {
    //   this.edit = false;
    //   this.getWarranty();
    // });
  }

  maintenanceDateChanged(dateChangeEvent: InputEvent) {
    this.maintenanceUpload.MaintenaceDate =
      (dateChangeEvent.target as HTMLInputElement).value + "T00:00:00";
  }

  updateInvoiceImagePath(path: string) {
    this.maintenanceUpload.InvoicePhotoUrl = path;
  }

  updateAlignmentCertImagePath(path: string) {
    this.maintenanceUpload.WheelAlignmentCertificatePhotoUrl = path;
  }

  onCountrySelect() {
    this.getDealers();
  }

  onDealerSelect(item: DealersModelsDealerForMapModel) {
    this.dealer = item;
  }

  onDealerInput(item: any) {
    if (item.input != undefined && item.input.length > 0) {
      var dealer = this.validateSelectedDealer(item.input);
      if (dealer != null && dealer != undefined) {
        this.dealer = dealer;
      } else {
        this.dealer = {};
      }
    }
  }

  cancelLog() {
    this.$emit("cancelLog");
  }

  validateSelectedDealer(dealerName?: string) {
    for (var i = 0; i < this.dealers.length; i++) {
      if (this.dealers[i].Name === dealerName) {
        return this.dealers[i];
      }
    }
    return null;
  }

  clearErrorContainer() {
    var container = document.getElementById("maintenanceLogErrorContainer");
    if (container != null && container != undefined) {
      container.textContent = "";
    }
  }

  validMaintenanceLog(maintenanceLog: WarrantyModelWarrantyMaintenanceModel) {
    var container = document.getElementById("maintenanceLogErrorContainer");
    if (container != null && container != undefined) {
      container.textContent = "";
    }
    var errors = [];

    if (
      this.maintenanceUpload.DealerId == null ||
      this.maintenanceUpload.DealerId == undefined ||
      this.maintenanceUpload.DealerId < 1
    ) {
      errors.push("Please select a Dealer from the list of provided Dealers");
    }
    if (
      this.maintenanceDate == null ||
      this.maintenanceDate == undefined ||
      this.maintenanceDate == ""
    ) {
      errors.push("Please enter a valid date for the date of maintenance");
    }
    if (
      this.maintenanceUpload.CurrentVehicleMileage == null ||
      this.maintenanceUpload.CurrentVehicleMileage == undefined ||
      this.maintenanceUpload.CurrentVehicleMileage.toString() == "" ||
      this.maintenanceUpload.CurrentVehicleMileage < 0
    ) {
      errors.push("Please enter a valid value for the current vehicle mileage");
    }
    if (
      this.maintenanceUpload.InvoicePhotoUrl == null ||
      this.maintenanceUpload.InvoicePhotoUrl == undefined ||
      this.maintenanceUpload.InvoicePhotoUrl == ""
    ) {
      errors.push("Please upload a photo of your invoice");
    }
    if (
      this.maintenanceUpload.WheelAlignmentCertificatePhotoUrl == null ||
      this.maintenanceUpload.WheelAlignmentCertificatePhotoUrl == undefined ||
      this.maintenanceUpload.WheelAlignmentCertificatePhotoUrl == ""
    ) {
      errors.push("Please upload a photo of your wheel alignment certificate");
    }

    if (errors.length > 0) {
      for (var i = 0; i < errors.length; i++) {
        var p = document.createElement("p");
        p.style.color = "red";
        p.textContent = errors[i];
        container?.append(p);
      }
      return false;
    }
    return true;
  }

  logMaintenance() {
    this.maintenanceUpload.WarrantyId = this.selectedWarranty?.Id;
    this.maintenanceUpload.DealerId = this.dealer?.SourceId;
    this.maintenanceUpload.DealerName = this.dealer?.Name;

    var apiDiv = document.getElementById("warrantySubmissionMessagesContainer");
    if (apiDiv != null && apiDiv != undefined) {
      apiDiv.textContent = "";
    }

    if (!this.validMaintenanceLog(this.maintenanceUpload)) {
      return;
    }

    this.$emit("setVisibleSpinner", true);

    Warranty.apiPrivateWarrantiesMaintenancesCreatePost(this.maintenanceUpload)
      .then((res) => {
        this.$emit("setVisibleSpinner", false);
        console.log(res);
        if (res) {
          this.$emit("maintenanceLogged");
        } else {
          document.getElementById("triggerSubmissonModal")!.click();
        }
        if (apiDiv != undefined) {
          var p = document.createElement("p");
          p.textContent = "Maintenace log created!";
          p.style.fontWeight = "bold";
          apiDiv?.append(p);
        }
      })
      .catch((error) => {
        this.$emit("setVisibleSpinner", false);
        console.error(error);
        if (apiDiv != undefined) {
          var p = document.createElement("p");
          p.style.color = "red";
          p.style.fontWeight = "bold";
          p.textContent = "Failed to create maintenance log";
          if (error.response?.data) {
            try {
              var msg = JSON.parse(error.response?.data);
              p.textContent += ": " + msg.Message;
            } catch (jsonParseError) {
              console.error(jsonParseError);
            }
          }
          apiDiv?.append(p);
        }
      });
  }

  getCurrencies() {
    this.$emit("setVisibleSpinner", true);
    Warranty.apiPrivateWarrantiesCurrenciesGetGet()
      .then((res) => {
        this.currencies = res.data as Array<WarrantyModelCurrency>;
        this.$emit("setVisibleSpinner", false);
        this.currency = this.currencies[3] ?? undefined;
        if (this.selectedWarranty?.CountryId) {
          this.currencies.forEach((c) => {
            if (c.CountryId == this.selectedWarranty?.CountryId) {
              this.currency = c;
            }
          });
        }
        this.getDealers();
      })
      .catch((error) => {
        console.log(error);
        this.$emit("setVisibleSpinner", false);
      });
  }

  getDealers() {
    if (!this.currency) {
      return;
    }
    this.$emit("setVisibleSpinner", true);
    Dealers.apiPublicCountriesByCountryIdCountryDealersGet(
      this.currency?.MyTyresCountryId!,
      undefined,
      undefined,
      true
    )
      .then((res) => {
        this.dealers = res.data.Items as Array<DealersModelsDealerForMapModel>;
        this.$emit("setVisibleSpinner", false);
        if (this.selectedWarranty?.DealerId) {
          this.dealers.forEach((d) => {
            if (d.Id == this.selectedWarranty?.DealerId) {
              this.dealer = d;
            }
          });
        }
        console.log(this.dealers, "dealers");
      })
      .catch((error) => {
        console.log(error);
        this.$emit("setVisibleSpinner", false);
      });
  }
}
