
import {
  TyreTreadPatternsTyreSku,
  TyreTreadPatternsTyreTreadPattern,
  VehicleTyresModelsVehicleTyreModelWithId,
} from "@/api-client";
import { TyreTreadPatterns, VehicleTyres } from "@/network/api";
import { Options, Vue } from "vue-class-component";

@Options({
  props: { vehicleId: String },
  emits: ["cancel"],
})
export default class AddTYres extends Vue {
  tyreFitmentDateUtc: string = new Date().toISOString();
  vehicleId?: string;
  selectedTyreRange = "";
  tyreTreads: Array<TyreTreadPatternsTyreTreadPattern> = [];
  selectedTyreTread: TyreTreadPatternsTyreTreadPattern = {};
  tyreSizes: Array<TyreTreadPatternsTyreSku> = [];
  selectedTyreSize: TyreTreadPatternsTyreSku = {};
  selectedSectionWidth = "";
  selectedAspectRatio = "";
  selectedRimSize = "";

  created() {
    this.$watch("selectedTyreRange", () => {
      this.tyreTreads = [];
      this.tyreSizes = [];
      this.getTyreTreads();
      console.log(this.selectedTyreRange, "selected tyre range change");
    });

    this.$watch("selectedTyreTread", () => {
      this.tyreSizes = [];
      this.getTyreSizes();
      console.log(this.selectedTyreTread.Name, "selected tyre tread change");
    });

    this.$watch("selectedTyreSize", () => {
      this.selectedSectionWidth = String(this.selectedTyreSize.SectionWidth);
      this.selectedAspectRatio = String(this.selectedTyreSize.AspectRatio);
      this.selectedRimSize = String(this.selectedTyreSize.RimSize);
      console.log(this.selectedSectionWidth, "selected tyre size change");
    });
  }

  get sortedModel() {
    //Sort tyre models in alphabetical order
    return this.tyreTreads.sort((a, b): number => {
      if (a.BrandDesc! < b.BrandDesc!) {
        return -1;
      }
      if (a.BrandDesc! > b.BrandDesc!) {
        return 1;
      }
      return 0;
    });
  }

  get parseDate() {
    console.log("returning parsed tyre date");
    return new Date(this.tyreFitmentDateUtc!);
  }

  expiryDateChanged(dateChangeEvent: InputEvent) {
    this.tyreFitmentDateUtc =
      (dateChangeEvent.target as HTMLInputElement).value + "T00:00:00";
  }

  getTyreTreads() {
    if (this.selectedTyreRange.length) {
      TyreTreadPatterns.apiPublicTyreTreadPatternsGet(
        undefined,
        undefined,
        this.selectedTyreRange
      )
        .then((res) => {
          console.log(res, "tyre treads");
          this.tyreTreads = res.data
            .Items as Array<TyreTreadPatternsTyreTreadPattern>;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getTyreSizes() {
    if (this.tyreTreads.length) {
      TyreTreadPatterns.apiPublicTyreTreadPatternsByTyreTreadPatternIdGet(
        this.selectedTyreTread.Id!
      )
        .then((res) => {
          console.log(res, "single tyre treads");
          this.tyreSizes = res.data.TyreSkus as Array<TyreTreadPatternsTyreSku>;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  addTyre() {
    if (this.vehicleId && this.selectedTyreSize) {
      let newTyre: VehicleTyresModelsVehicleTyreModelWithId = {
        RangeName: this.selectedTyreTread.Name!,
        AspectRatio: this.selectedAspectRatio,
        SectionWidth: this.selectedSectionWidth,
        RimSize: this.selectedRimSize,
        FitmentDateUtc: this.tyreFitmentDateUtc,
      };
      VehicleTyres.apiPrivateVehiclesByVehicleIdTyresPut(
        this.vehicleId,
        newTyre
      )
        .then((res) => {
          console.log(res);
          if (res) {
            this.$emit("cancel");
          } else {
            document.getElementById("triggerSubmissonModal")!.click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      document.getElementById("triggerSubmissonModal")!.click();
    }
  }
}
