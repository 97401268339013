
import { Options, Vue } from "vue-class-component";
import DisclaimerModal from "./DisclaimerModal.vue";

@Options({
  components: { DisclaimerModal },
})
export default class Footer extends Vue {
  get version() {
    return `v${process.env.__APPVERSION__} ${process.env.__BUILDTIME__}`;
  }

  get dunlopTyresURL() {
    return process.env.VUE_APP_DUNLOP_TYRES_URL;
  }

  get currentYear() {
    const current = new Date();
    return current.getFullYear();
  }
}
