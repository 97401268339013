
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    title: String,
    lightSrc: String,
    darkSrc: String,
    routeName: String,
    className: String,
    comingSoon: Boolean,
  },
})
export default class DashboardCard extends Vue {
  title!: string;
  lightSrc!: string;
  darkSrc!: string;
  routeName!: string;
  className!: string;
  comingSoon!: boolean;

  isExternal(path: string) {
    return /^(https?:|mailto:|tel:)/.test(path);
  }
}
