
import { VehiclesModelsVehicleModel } from "@/api-client";
import { Vehicles } from "@/network/api";
import { Options, Vue } from "vue-class-component";
import LicenceDetails from "../components/LicenceRenewal.vue";

@Options({
  props: {
    id: { default: null },
  },
  components: { LicenceDetails },
})
export default class ViewLicenceMobilePage extends Vue {
  id: any;
  vehicle: VehiclesModelsVehicleModel = {
    Name: "",
    ExpiryDate: "",
    LicenseNumber: "",
    VinNumber: "",
    Make: "",
    Model: "",
    Specification: "",
    Tare: 0,
    Colour: "",
    EstimatedMonthlyMileage: 0,
  };
  isMobile = true;
  visibleSpinner = false;
  newVehicle = true;

  created() {
    this.loadInitalData();
  }

  loadInitalData() {
    if (this.id !== "new_licence") {
      Vehicles.apiPrivateVehiclesGet()
        .then((res) => {
          console.log(res);
          this.vehicle = res.data.Items![this.id] as VehiclesModelsVehicleModel;
          this.newVehicle = false;
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.vehicle = {
        Name: "",
        ExpiryDate: "",
        LicenseNumber: "",
        VinNumber: "",
        Make: "",
        Model: "",
        Specification: "",
        Tare: 0,
        Colour: "",
        EstimatedMonthlyMileage: 0,
      };
      this.newVehicle = true;
    }
  }
}
