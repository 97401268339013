
import { Options, Vue } from "vue-class-component";

@Options({
  props: { modalId: String, item: String },
  emits: ["confirmed"],
})
export default class DeleteConfirmationModal extends Vue {
  modalId = "";
  item = "item";
}
