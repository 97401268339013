import {
  createRouter,
  createWebHistory,
  RouteLocation,
  RouteRecordRaw,
} from "vue-router";
import DebugPage from "../views/DebugPage.vue";
import LoginPage from "../views/LoginPage.vue";
import RegistrationPage from "../views/RegistrationPage.vue";
import ForgotPasswordPage from "../views/ForgotPasswordPage.vue";
import DashboardPage from "../views/DashboardPage.vue";
import MyProfilePage from "../views/MyProfilePage.vue";
import LicenceRenewalPage from "../views/LicenceRenewalPage.vue";
import InsurancePage from "../views/InsurancePage.vue";
import WarrantyPage from "../views/WarrantyPage.vue";
import MyVehiclesPage from "../views/MyVehiclesPage.vue";
import ViewVehicleMobilePage from "../views/ViewVehicleMobilePage.vue";
import ViewInsuranceMobilePage from "../views/ViewInsuranceMobilePage.vue";
import ViewLicenceMobilePage from "../views/ViewLicenceMobilePage.vue";
import ViewWarrantyMobilePage from "../views/ViewWarrantyMobilePage.vue";
import TncsPage from "../views/TncsPage.vue";
import { store } from "@/store";

function authGuard(to: RouteLocation) {
  const isLoggedIn = store.getters["auth/isLoggedIn"];
  if (isLoggedIn) {
    // logged in so return true
    return true;
  }
  // not logged in so redirect to login page with the return url
  router.push({ path: "/login", query: { returnUrl: to.fullPath } });
  return false;
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Dashboard",
    component: DashboardPage,
    beforeEnter: authGuard,
  },
  {
    path: "/debug",
    name: "Debug",
    component: DebugPage,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutPage.vue"),
  // },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/register",
    name: "Register",
    component: RegistrationPage,
  },
  {
    path: "/forgot_password",
    name: "ForgotPassword",
    component: ForgotPasswordPage,
  },
  {
    path: "/my_profile",
    name: "MyProfile",
    component: MyProfilePage,
    beforeEnter: authGuard,
  },
  {
    path: "/my_vehicles",
    name: "MyVehicles",
    component: MyVehiclesPage,
    beforeEnter: authGuard,
  },
  {
    path: "/my_vehicles/view_vehicle/:id?",
    name: "ViewVehicle",
    component: ViewVehicleMobilePage,
    props: true,
  },
  {
    path: "/licence_renewal",
    name: "LicenceRenewal",
    component: LicenceRenewalPage,
    beforeEnter: authGuard,
  },
  {
    path: "/licence_renewal/view_licence/:id?",
    name: "ViewLicence",
    component: ViewLicenceMobilePage,
    props: true,
  },
  {
    path: "/dunlop_sure",
    name: "DunlopSure",
    component: InsurancePage,
    beforeEnter: authGuard,
  },
  {
    path: "/dunlop_sure/view_insurance/:id?",
    name: "ViewInsurance",
    component: ViewInsuranceMobilePage,
    props: true,
  },
  {
    path: "/mileage_warranty",
    name: "Warranty",
    component: WarrantyPage,
    beforeEnter: authGuard,
  },
  {
    path: "/mileage_warranty/view_warranty/:id?",
    name: "ViewWarranty",
    component: ViewWarrantyMobilePage,
    props: true,
  },
  {
    path: "/",
    beforeEnter(to, from, next) {
      window.location.replace(process.env.VUE_APP_DUNLOP_TYRES_URL ?? "/");
    },
    name: "DunlopTyresUrl",
    component: DashboardPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  document.getElementById("navbarCollapse")!.classList.remove("show");
});

export default router;
