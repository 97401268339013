import { App } from "vue";
import { h } from "vue";

export interface AppleSignInOptions {
  clientId: string;
  scope: string;
  redirectURI: string;
  state: string;
  usePopup: boolean;
}
/* eslint-disable no-console */
function err(msg: any) {
  if (typeof console !== "undefined") {
    console.error(`[vue-apple-signin] ${msg}`);
  }
}

export default {
  install(app: App, options: AppleSignInOptions) {
    app.component("vue-apple-login", {
      name: "VueAppleLogin",
      props: {
        mode: {
          type: String,
          default: "center-align",
          validator(value: string) {
            return (
              ["center-align", "left-align", "logo-only"].indexOf(value) > -1
            );
          },
        },
        type: {
          type: String,
          default: "sign in",
          validator(value: string) {
            return (
              ["sign in", "sign up", "apple", "continue"].indexOf(value) > -1
            );
          },
        },
        color: {
          type: String,
          default: "black",
          validator(value: string) {
            return ["white", "black"].indexOf(value) > -1;
          },
        },
        border: {
          type: Boolean,
          default: true,
        },
        radius: {
          type: Number,
          default: 15,
          validator(value: number) {
            return value >= 0 && value <= 50;
          },
        },
        width: {
          type: String,
          default: "100%",
        },
        height: {
          type: String,
          default: "100%",
        },
        logoSize: {
          type: String,
          default: "medium",
          validator(value: string) {
            return ["small", "medium", "large"].indexOf(value) > -1;
          },
        },
        logoPosition: {
          type: Number,
          default: 0,
        },
        labelPosition: {
          type: Number,
          default: 0,
        },
        className: {
          type: String,
          default: "vue-apple-signin",
        },
        onSuccess: {
          type: Function,
          default: null,
        },
        onFailure: {
          type: Function,
          default: null,
        },
      },
      computed: {
        dataBorder() {
          return this.border.toString();
        },
      },
      mounted() {
        const self = this;
        let appleScript = document.createElement("script");
        appleScript.setAttribute(
          "src",
          "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
        );
        document.head.appendChild(appleScript);

        appleScript.addEventListener("load", function () {
          console.log("script loaded");

          // @ts-ignore
          let AppleId = window.AppleID;
          if (!AppleId) {
            err(
              '"https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js" needs to be included as a <script>'
            );
            return;
          }

          const {
            clientId,
            scope = "name email",
            redirectURI,
            state,
            usePopup,
          } = options;

          if (!clientId) {
            err("options.clientId must be specified.");
            return;
          }

          if (!redirectURI) {
            err("options.redirectURI must be specified.");
            return;
          }

          if (!state) {
            err("options.state must be specified.");
            return;
          }

          AppleId.auth.init({
            clientId,
            scope,
            redirectURI,
            state,
            //   nonce,
            usePopup,
          });

          document.addEventListener(
            "AppleIDSignInOnSuccess",
            self.callOnSuccess
          );
          document.addEventListener(
            "AppleIDSignInOnFailure",
            self.callOnFailure
          );
        });
      },
      beforeDestroy() {
        const self = this;
        document.removeEventListener(
          "AppleIDSignInOnSuccess",
          self.callOnSuccess
        );
        document.removeEventListener(
          "AppleIDSignInOnFailure",
          self.callOnFailure
        );
      },
      methods: {
        callOnSuccess(data: any) {
          if (this.onSuccess) {
            const appleUserData = this.getAppleDataFromToken(
              data.detail.authorization.id_token
            );
            this.onSuccess({
              authorization: data.detail.authorization,
              userData: data.detail.user,
            });
          }
        },
        callOnFailure(error: any) {
          if (this.onFailure) {
            this.onFailure(error);
          }
        },
        getAppleDataFromToken(token: any) {
          var base64Url = token.split(".")[1];
          var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
          var jsonPayload = decodeURIComponent(
            atob(base64)
              .split("")
              .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join("")
          );
          return JSON.parse(jsonPayload);
        },
      },
      render() {
        return h("div", {
          id: "appleid-signin",
          "data-mode": this.mode,
          "data-type": this.type,
          "data-color": this.color,
          "data-border": this.dataBorder,
          "data-radius": this.radius,
          "data-width": this.width,
          "data-height": this.height,
          "data-logo-size": this.logoSize,
          "data-logo-position": this.logoPosition,
          "data-label-position": this.labelPosition,
          class: this.className,
        });
      },
    });
  },
};
