/* tslint:disable */
/* eslint-disable */
/**
 * SRSA My Tyres API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CarLicenceModelsBlockResponseModel
 */
export interface CarLicenceModelsBlockResponseModel {
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsBlockResponseModel
     */
    'BlockAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsBlockResponseModel
     */
    'BlockGUID'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CarLicenceModelsBlockResponseModel
     */
    'BlockMustBePaid'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsBlockResponseModel
     */
    'BlockServiceFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsBlockResponseModel
     */
    'BlockTotalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsBlockResponseModel
     */
    'BlockType'?: CarLicenceModelsBlockResponseModelBlockTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsBlockResponseModel
     */
    'Error'?: string;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsBlockResponseModel
     */
    'ErrorCode'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsBlockResponseModel
     */
    'PaymentTransactionStatus'?: number;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsBlockResponseModel
     */
    'BlockDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsBlockResponseModel
     */
    'BlockLongDescription'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CarLicenceModelsBlockResponseModelBlockTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_12 = 12,
    NUMBER_13 = 13,
    NUMBER_14 = 14,
    NUMBER_15 = 15,
    NUMBER_16 = 16
}

/**
 * 
 * @export
 * @interface CarLicenceModelsQuoteRequestModel
 */
export interface CarLicenceModelsQuoteRequestModel {
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsQuoteRequestModel
     */
    'RegistrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsQuoteRequestModel
     */
    'Expiry'?: string;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsQuoteRequestModel
     */
    'VehicleType'?: CarLicenceModelsQuoteRequestModelVehicleTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsQuoteRequestModel
     */
    'Tare'?: number;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsQuoteRequestModel
     */
    'MembershipNumber'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CarLicenceModelsQuoteRequestModelVehicleTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9
}

/**
 * 
 * @export
 * @interface CarLicenceModelsQuoteResponseModel
 */
export interface CarLicenceModelsQuoteResponseModel {
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsQuoteResponseModel
     */
    'DeliveryFee'?: number;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsQuoteResponseModel
     */
    'Expiry'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsQuoteResponseModel
     */
    'Make'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsQuoteResponseModel
     */
    'Model'?: string;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsQuoteResponseModel
     */
    'PenaltyFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsQuoteResponseModel
     */
    'Amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsQuoteResponseModel
     */
    'Error'?: string;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsQuoteResponseModel
     */
    'ErrorCode'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsQuoteResponseModel
     */
    'ServiceFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsQuoteResponseModel
     */
    'TotalAmount'?: number;
}
/**
 * 
 * @export
 * @interface CarLicenceModelsQuoteSingleVendRequestModel
 */
export interface CarLicenceModelsQuoteSingleVendRequestModel {
    /**
     * 
     * @type {CarLicenceModelsQuoteRequestModel}
     * @memberof CarLicenceModelsQuoteSingleVendRequestModel
     */
    'QuoteRequestModel'?: CarLicenceModelsQuoteRequestModel;
    /**
     * 
     * @type {CarLicenceModelsSingleVendRequestModel}
     * @memberof CarLicenceModelsQuoteSingleVendRequestModel
     */
    'VendRequestModel'?: CarLicenceModelsSingleVendRequestModel;
}
/**
 * 
 * @export
 * @interface CarLicenceModelsSetBlocksForPaymentRequest
 */
export interface CarLicenceModelsSetBlocksForPaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsSetBlocksForPaymentRequest
     */
    'Registration'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CarLicenceModelsSetBlocksForPaymentRequest
     */
    'BlockIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CarLicenceModelsSingleVendRequestModel
 */
export interface CarLicenceModelsSingleVendRequestModel {
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsSingleVendRequestModel
     */
    'ContactNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsSingleVendRequestModel
     */
    'Town'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsSingleVendRequestModel
     */
    'Province'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsSingleVendRequestModel
     */
    'PostalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsSingleVendRequestModel
     */
    'ContactPerson'?: string;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsSingleVendRequestModel
     */
    'AddressType'?: number;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsSingleVendRequestModel
     */
    'BusinessName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsSingleVendRequestModel
     */
    'RecepientName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsSingleVendRequestModel
     */
    'RecepientContact'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsSingleVendRequestModel
     */
    'AddressLine1'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsSingleVendRequestModel
     */
    'AddressLine2'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsSingleVendRequestModel
     */
    'AddressLine3'?: string;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsSingleVendRequestModel
     */
    'NotifyCustomerBySMS'?: CarLicenceModelsSingleVendRequestModelNotifyCustomerBySMSEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CarLicenceModelsSingleVendRequestModelNotifyCustomerBySMSEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

/**
 * 
 * @export
 * @interface CarLicenceModelsSingleVendResponseModel
 */
export interface CarLicenceModelsSingleVendResponseModel {
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsSingleVendResponseModel
     */
    'DeliveryFee'?: number;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsSingleVendResponseModel
     */
    'PaymentRefNo'?: string;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsSingleVendResponseModel
     */
    'Status'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsSingleVendResponseModel
     */
    'Amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsSingleVendResponseModel
     */
    'Error'?: string;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsSingleVendResponseModel
     */
    'ErrorCode'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsSingleVendResponseModel
     */
    'ServiceFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsSingleVendResponseModel
     */
    'TotalAmount'?: number;
}
/**
 * 
 * @export
 * @interface CarLicenceModelsTrackAndTraceResposeModel
 */
export interface CarLicenceModelsTrackAndTraceResposeModel {
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsTrackAndTraceResposeModel
     */
    'Error'?: string;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsTrackAndTraceResposeModel
     */
    'ErrorCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsTrackAndTraceResposeModel
     */
    'TrackAndTraceJSON'?: string;
}
/**
 * 
 * @export
 * @interface CarLicenceModelsUserVendStatusesResponseModel
 */
export interface CarLicenceModelsUserVendStatusesResponseModel {
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsUserVendStatusesResponseModel
     */
    'VehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsUserVendStatusesResponseModel
     */
    'VehicleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsUserVendStatusesResponseModel
     */
    'VehicleLicenceNumber'?: string;
    /**
     * 
     * @type {CarLicenceModelsVendStatusResponseModel}
     * @memberof CarLicenceModelsUserVendStatusesResponseModel
     */
    'Stataus'?: CarLicenceModelsVendStatusResponseModel;
}
/**
 * 
 * @export
 * @interface CarLicenceModelsVendBlockRequestItem
 */
export interface CarLicenceModelsVendBlockRequestItem {
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsVendBlockRequestItem
     */
    'Registration'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsVendBlockRequestItem
     */
    'BlockId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsVendBlockRequestItem
     */
    'BlockType'?: CarLicenceModelsVendBlockRequestItemBlockTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CarLicenceModelsVendBlockRequestItem
     */
    'BlockMustBePaid'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsVendBlockRequestItem
     */
    'BlockTotalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsVendBlockRequestItem
     */
    'PaymentTransactionStatus'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CarLicenceModelsVendBlockRequestItemBlockTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_12 = 12,
    NUMBER_13 = 13,
    NUMBER_14 = 14,
    NUMBER_15 = 15,
    NUMBER_16 = 16
}

/**
 * 
 * @export
 * @interface CarLicenceModelsVendBlocksRequest
 */
export interface CarLicenceModelsVendBlocksRequest {
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsVendBlocksRequest
     */
    'Registration'?: string;
    /**
     * 
     * @type {Array<CarLicenceModelsVendBlockRequestItem>}
     * @memberof CarLicenceModelsVendBlocksRequest
     */
    'VendBlocks'?: Array<CarLicenceModelsVendBlockRequestItem>;
}
/**
 * 
 * @export
 * @interface CarLicenceModelsVendStatusResponseModel
 */
export interface CarLicenceModelsVendStatusResponseModel {
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsVendStatusResponseModel
     */
    'Make'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsVendStatusResponseModel
     */
    'Model'?: string;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsVendStatusResponseModel
     */
    'Status'?: CarLicenceModelsVendStatusResponseModelStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsVendStatusResponseModel
     */
    'Amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CarLicenceModelsVendStatusResponseModel
     */
    'Error'?: string;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsVendStatusResponseModel
     */
    'ErrorCode'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsVendStatusResponseModel
     */
    'ServiceFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarLicenceModelsVendStatusResponseModel
     */
    'TotalAmount'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CarLicenceModelsVendStatusResponseModelStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_12 = 12,
    NUMBER_13 = 13,
    NUMBER_14 = 14,
    NUMBER_15 = 15,
    NUMBER_16 = 16,
    NUMBER_17 = 17,
    NUMBER_18 = 18,
    NUMBER_19 = 19,
    NUMBER_20 = 20,
    NUMBER_21 = 21,
    NUMBER_22 = 22,
    NUMBER_23 = 23,
    NUMBER_24 = 24,
    NUMBER_25 = 25,
    NUMBER_26 = 26,
    NUMBER_27 = 27
}

/**
 * 
 * @export
 * @interface DealerModelsInviteCustomer
 */
export interface DealerModelsInviteCustomer {
    /**
     * 
     * @type {string}
     * @memberof DealerModelsInviteCustomer
     */
    'EmailAddress'?: string;
    /**
     * 
     * @type {VehiclesModelsVehicleModel}
     * @memberof DealerModelsInviteCustomer
     */
    'VehicleModel'?: VehiclesModelsVehicleModel;
}
/**
 * 
 * @export
 * @interface DealersModelsDealerForMapModel
 */
export interface DealersModelsDealerForMapModel {
    /**
     * 
     * @type {string}
     * @memberof DealersModelsDealerForMapModel
     */
    'Id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsDealerForMapModel
     */
    'SourceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DealersModelsDealerForMapModel
     */
    'Name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsDealerForMapModel
     */
    'PositionLatitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsDealerForMapModel
     */
    'PositionLongitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof DealersModelsDealerForMapModel
     */
    'MapIconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DealersModelsDealerForMapModel
     */
    'DealerType'?: string;
}
/**
 * 
 * @export
 * @interface DealersModelsDealerForMapResponse
 */
export interface DealersModelsDealerForMapResponse {
    /**
     * 
     * @type {Array<DealersModelsDealerForMapModel>}
     * @memberof DealersModelsDealerForMapResponse
     */
    'Items'?: Array<DealersModelsDealerForMapModel>;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsDealerForMapResponse
     */
    'ItemCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsDealerForMapResponse
     */
    'Skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsDealerForMapResponse
     */
    'Take'?: number;
}
/**
 * 
 * @export
 * @interface DealersModelsDealerModel
 */
export interface DealersModelsDealerModel {
    /**
     * 
     * @type {string}
     * @memberof DealersModelsDealerModel
     */
    'Id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsDealerModel
     */
    'SourceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DealersModelsDealerModel
     */
    'Name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsDealerModel
     */
    'PositionLatitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsDealerModel
     */
    'PositionLongitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof DealersModelsDealerModel
     */
    'MapIconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DealersModelsDealerModel
     */
    'DealerType'?: string;
}
/**
 * 
 * @export
 * @interface DealersModelsDealerResponse
 */
export interface DealersModelsDealerResponse {
    /**
     * 
     * @type {Array<DealersModelsDealerModel>}
     * @memberof DealersModelsDealerResponse
     */
    'Items'?: Array<DealersModelsDealerModel>;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsDealerResponse
     */
    'ItemCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsDealerResponse
     */
    'Skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsDealerResponse
     */
    'Take'?: number;
}
/**
 * 
 * @export
 * @interface DealersModelsLocationModel
 */
export interface DealersModelsLocationModel {
    /**
     * 
     * @type {string}
     * @memberof DealersModelsLocationModel
     */
    'Id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsLocationModel
     */
    'SourceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DealersModelsLocationModel
     */
    'Name'?: string;
}
/**
 * 
 * @export
 * @interface DealersModelsLocationResponse
 */
export interface DealersModelsLocationResponse {
    /**
     * 
     * @type {Array<DealersModelsLocationModel>}
     * @memberof DealersModelsLocationResponse
     */
    'Items'?: Array<DealersModelsLocationModel>;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsLocationResponse
     */
    'ItemCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsLocationResponse
     */
    'Skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsLocationResponse
     */
    'Take'?: number;
}
/**
 * 
 * @export
 * @interface DealersModelsSingleDealerModel
 */
export interface DealersModelsSingleDealerModel {
    /**
     * 
     * @type {string}
     * @memberof DealersModelsSingleDealerModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DealersModelsSingleDealerModel
     */
    'Name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsSingleDealerModel
     */
    'PositionLatitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsSingleDealerModel
     */
    'PositionLongitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof DealersModelsSingleDealerModel
     */
    'MapIconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DealersModelsSingleDealerModel
     */
    'ProfileBannerImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DealersModelsSingleDealerModel
     */
    'DisplayAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DealersModelsSingleDealerModel
     */
    'DealerType'?: string;
    /**
     * 
     * @type {string}
     * @memberof DealersModelsSingleDealerModel
     */
    'Email'?: string;
    /**
     * 
     * @type {string}
     * @memberof DealersModelsSingleDealerModel
     */
    'PhoneNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsSingleDealerModel
     */
    'Rating'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DealersModelsSingleDealerModel
     */
    'OpeningHours'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DealersModelsSingleDealerModel
     */
    'GooglePlacesId'?: string;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsSingleDealerModel
     */
    'RaqCountryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsSingleDealerModel
     */
    'RaqProvinceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DealersModelsSingleDealerModel
     */
    'RaqArea'?: string;
    /**
     * 
     * @type {number}
     * @memberof DealersModelsSingleDealerModel
     */
    'RaqDealerId'?: number;
}
/**
 * 
 * @export
 * @interface MileageLogsModelsMileageLogModel
 */
export interface MileageLogsModelsMileageLogModel {
    /**
     * 
     * @type {string}
     * @memberof MileageLogsModelsMileageLogModel
     */
    'Name': string;
    /**
     * 
     * @type {number}
     * @memberof MileageLogsModelsMileageLogModel
     */
    'DistanceInKm': number;
    /**
     * 
     * @type {string}
     * @memberof MileageLogsModelsMileageLogModel
     */
    'TripDate': string;
    /**
     * 
     * @type {number}
     * @memberof MileageLogsModelsMileageLogModel
     */
    'OdometerReading'?: number;
    /**
     * 
     * @type {string}
     * @memberof MileageLogsModelsMileageLogModel
     */
    'Note'?: string;
}
/**
 * 
 * @export
 * @interface MileageLogsModelsMileageLogModelWithId
 */
export interface MileageLogsModelsMileageLogModelWithId {
    /**
     * 
     * @type {string}
     * @memberof MileageLogsModelsMileageLogModelWithId
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MileageLogsModelsMileageLogModelWithId
     */
    'Name': string;
    /**
     * 
     * @type {number}
     * @memberof MileageLogsModelsMileageLogModelWithId
     */
    'DistanceInKm': number;
    /**
     * 
     * @type {string}
     * @memberof MileageLogsModelsMileageLogModelWithId
     */
    'TripDate': string;
    /**
     * 
     * @type {number}
     * @memberof MileageLogsModelsMileageLogModelWithId
     */
    'OdometerReading'?: number;
    /**
     * 
     * @type {string}
     * @memberof MileageLogsModelsMileageLogModelWithId
     */
    'Note'?: string;
}
/**
 * 
 * @export
 * @interface MileageLogsModelsMileageLogsResponse
 */
export interface MileageLogsModelsMileageLogsResponse {
    /**
     * 
     * @type {Array<MileageLogsModelsMileageLogModelWithId>}
     * @memberof MileageLogsModelsMileageLogsResponse
     */
    'Items'?: Array<MileageLogsModelsMileageLogModelWithId>;
    /**
     * 
     * @type {number}
     * @memberof MileageLogsModelsMileageLogsResponse
     */
    'ItemCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof MileageLogsModelsMileageLogsResponse
     */
    'Skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof MileageLogsModelsMileageLogsResponse
     */
    'Take'?: number;
}
/**
 * 
 * @export
 * @interface ModelsApiErrorModel
 */
export interface ModelsApiErrorModel {
    /**
     * 
     * @type {number}
     * @memberof ModelsApiErrorModel
     */
    'ErrorCount'?: number;
    /**
     * 
     * @type {Array<ModelsApiErrorModelItem>}
     * @memberof ModelsApiErrorModel
     */
    'Errors'?: Array<ModelsApiErrorModelItem>;
    /**
     * 
     * @type {number}
     * @memberof ModelsApiErrorModel
     */
    'ErrorType'?: ModelsApiErrorModelErrorTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ModelsApiErrorModelErrorTypeEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 * 
 * @export
 * @interface ModelsApiErrorModelItem
 */
export interface ModelsApiErrorModelItem {
    /**
     * 
     * @type {string}
     * @memberof ModelsApiErrorModelItem
     */
    'Description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsApiErrorModelItem
     */
    'Code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsApiErrorModelItem
     */
    'FieldName'?: string;
}
/**
 * 
 * @export
 * @interface ModelsRegisterAppUserRequest
 */
export interface ModelsRegisterAppUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ModelsRegisterAppUserRequest
     */
    'FirstName': string;
    /**
     * 
     * @type {string}
     * @memberof ModelsRegisterAppUserRequest
     */
    'LastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsRegisterAppUserRequest
     */
    'Email': string;
    /**
     * 
     * @type {string}
     * @memberof ModelsRegisterAppUserRequest
     */
    'Password': string;
    /**
     * 
     * @type {string}
     * @memberof ModelsRegisterAppUserRequest
     */
    'CountryId': string;
    /**
     * 
     * @type {string}
     * @memberof ModelsRegisterAppUserRequest
     */
    'DateOfBirth': string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsRegisterAppUserRequest
     */
    'IsMyDunlopDashboardRequest'?: boolean;
}
/**
 * 
 * @export
 * @interface ProfileModelsCustomerResponseToDealer
 */
export interface ProfileModelsCustomerResponseToDealer {
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsCustomerResponseToDealer
     */
    'DealerId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileModelsCustomerResponseToDealer
     */
    'Accepted'?: boolean;
}
/**
 * 
 * @export
 * @interface ProfileModelsDealerRequestCustomer
 */
export interface ProfileModelsDealerRequestCustomer {
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsDealerRequestCustomer
     */
    'DealerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsDealerRequestCustomer
     */
    'DealerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsDealerRequestCustomer
     */
    'DateInitialized'?: string;
}
/**
 * 
 * @export
 * @interface ProfileModelsProfileModel
 */
export interface ProfileModelsProfileModel {
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModel
     */
    'ImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModel
     */
    'Alias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModel
     */
    'FirstName': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModel
     */
    'LastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModel
     */
    'Email': string;
    /**
     * 
     * @type {number}
     * @memberof ProfileModelsProfileModel
     */
    'UserType'?: ProfileModelsProfileModelUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModel
     */
    'PreferredDealerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModel
     */
    'PreferredDealerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModel
     */
    'ContactNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModel
     */
    'DealerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModel
     */
    'DealerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModel
     */
    'CountryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModel
     */
    'Gender'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ProfileModelsProfileModelUserTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 * 
 * @export
 * @interface ProfileModelsProfileModelWithIdModel
 */
export interface ProfileModelsProfileModelWithIdModel {
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModelWithIdModel
     */
    'ProfileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModelWithIdModel
     */
    'ImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModelWithIdModel
     */
    'Alias'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModelWithIdModel
     */
    'FirstName': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModelWithIdModel
     */
    'LastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModelWithIdModel
     */
    'Email': string;
    /**
     * 
     * @type {number}
     * @memberof ProfileModelsProfileModelWithIdModel
     */
    'UserType'?: ProfileModelsProfileModelWithIdModelUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModelWithIdModel
     */
    'PreferredDealerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModelWithIdModel
     */
    'PreferredDealerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModelWithIdModel
     */
    'ContactNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModelWithIdModel
     */
    'DealerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModelWithIdModel
     */
    'DealerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileModelWithIdModel
     */
    'CountryId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ProfileModelsProfileModelWithIdModelUserTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 * 
 * @export
 * @interface ProfileModelsProfileSmallModel
 */
export interface ProfileModelsProfileSmallModel {
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileSmallModel
     */
    'ProfileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileSmallModel
     */
    'Name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileSmallModel
     */
    'Make'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileSmallModel
     */
    'Model'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileModelsProfileSmallModel
     */
    'Colour'?: string;
}
/**
 * 
 * @export
 * @interface SecurityModelsConfirmEmailResponse
 */
export interface SecurityModelsConfirmEmailResponse {
    /**
     * 
     * @type {string}
     * @memberof SecurityModelsConfirmEmailResponse
     */
    'Message'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SecurityModelsConfirmEmailResponse
     */
    'EmailConfirmed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecurityModelsConfirmEmailResponse
     */
    'EmailValid'?: boolean;
}
/**
 * 
 * @export
 * @interface SecurityModelsEmailConfirmedResponse
 */
export interface SecurityModelsEmailConfirmedResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SecurityModelsEmailConfirmedResponse
     */
    'EmailConfirmed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecurityModelsEmailConfirmedResponse
     */
    'EmailValid'?: boolean;
}
/**
 * 
 * @export
 * @interface SecurityModelsEncryptRequest
 */
export interface SecurityModelsEncryptRequest {
    /**
     * 
     * @type {string}
     * @memberof SecurityModelsEncryptRequest
     */
    'Data'?: string;
}
/**
 * 
 * @export
 * @interface SecurityModelsForgotPasswordCompleteRequest
 */
export interface SecurityModelsForgotPasswordCompleteRequest {
    /**
     * 
     * @type {string}
     * @memberof SecurityModelsForgotPasswordCompleteRequest
     */
    'Email'?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityModelsForgotPasswordCompleteRequest
     */
    'Code'?: string;
}
/**
 * 
 * @export
 * @interface SecurityModelsForgotPasswordRequest
 */
export interface SecurityModelsForgotPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof SecurityModelsForgotPasswordRequest
     */
    'Email'?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityModelsForgotPasswordRequest
     */
    'Password'?: string;
}
/**
 * 
 * @export
 * @interface SecurityModelsLoginModel
 */
export interface SecurityModelsLoginModel {
    /**
     * 
     * @type {string}
     * @memberof SecurityModelsLoginModel
     */
    'Username': string;
    /**
     * 
     * @type {string}
     * @memberof SecurityModelsLoginModel
     */
    'Password': string;
}
/**
 * 
 * @export
 * @interface SecurityModelsRefreshTokenModel
 */
export interface SecurityModelsRefreshTokenModel {
    /**
     * 
     * @type {string}
     * @memberof SecurityModelsRefreshTokenModel
     */
    'refresh_token': string;
}
/**
 * 
 * @export
 * @interface SocialAuthModelsSocialAuthTokenRequest
 */
export interface SocialAuthModelsSocialAuthTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof SocialAuthModelsSocialAuthTokenRequest
     */
    'Token': string;
    /**
     * 
     * @type {string}
     * @memberof SocialAuthModelsSocialAuthTokenRequest
     */
    'FirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialAuthModelsSocialAuthTokenRequest
     */
    'LastName'?: string;
}
/**
 * 
 * @export
 * @interface TwelveMonthTyreInsuranceModelsCountryModel
 */
export interface TwelveMonthTyreInsuranceModelsCountryModel {
    /**
     * 
     * @type {number}
     * @memberof TwelveMonthTyreInsuranceModelsCountryModel
     */
    'Id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsCountryModel
     */
    'Name'?: string;
}
/**
 * 
 * @export
 * @interface TwelveMonthTyreInsuranceModelsCustomerModel
 */
export interface TwelveMonthTyreInsuranceModelsCustomerModel {
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsCustomerModel
     */
    'IdOrPassportNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsCustomerModel
     */
    'FirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsCustomerModel
     */
    'LastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsCustomerModel
     */
    'EmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsCustomerModel
     */
    'CellPhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsCustomerModel
     */
    'MyTyresProfileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsCustomerModel
     */
    'Title'?: string;
}
/**
 * 
 * @export
 * @interface TwelveMonthTyreInsuranceModelsDealerListModel
 */
export interface TwelveMonthTyreInsuranceModelsDealerListModel {
    /**
     * 
     * @type {number}
     * @memberof TwelveMonthTyreInsuranceModelsDealerListModel
     */
    'Id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsDealerListModel
     */
    'Name'?: string;
}
/**
 * 
 * @export
 * @interface TwelveMonthTyreInsuranceModelsDealerModel
 */
export interface TwelveMonthTyreInsuranceModelsDealerModel {
    /**
     * 
     * @type {number}
     * @memberof TwelveMonthTyreInsuranceModelsDealerModel
     */
    'Id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsDealerModel
     */
    'CounterhandName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsDealerModel
     */
    'CounterHandCellNumber'?: string;
}
/**
 * 
 * @export
 * @interface TwelveMonthTyreInsuranceModelsPolicyListModel
 */
export interface TwelveMonthTyreInsuranceModelsPolicyListModel {
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyListModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyListModel
     */
    'PolicyNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyListModel
     */
    'Vehicle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyListModel
     */
    'Status'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyListModel
     */
    'CustomerEmail'?: string;
}
/**
 * 
 * @export
 * @interface TwelveMonthTyreInsuranceModelsPolicyModel
 */
export interface TwelveMonthTyreInsuranceModelsPolicyModel {
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyModel
     */
    'Id'?: string;
    /**
     * 
     * @type {TwelveMonthTyreInsuranceModelsDealerModel}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyModel
     */
    'Dealer'?: TwelveMonthTyreInsuranceModelsDealerModel;
    /**
     * 
     * @type {TwelveMonthTyreInsuranceModelsCustomerModel}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyModel
     */
    'Customer'?: TwelveMonthTyreInsuranceModelsCustomerModel;
    /**
     * 
     * @type {TwelveMonthTyreInsuranceModelsTyreInformationModel}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyModel
     */
    'TyreInformation'?: TwelveMonthTyreInsuranceModelsTyreInformationModel;
    /**
     * 
     * @type {TwelveMonthTyreInsuranceModelsVehicleModel}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyModel
     */
    'Vehicle'?: TwelveMonthTyreInsuranceModelsVehicleModel;
    /**
     * 
     * @type {Array<TwelveMonthTyreInsuranceModelsTyreModel>}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyModel
     */
    'Tyres'?: Array<TwelveMonthTyreInsuranceModelsTyreModel>;
    /**
     * 
     * @type {TwelveMonthTyreInsuranceModelsCountryModel}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyModel
     */
    'Country'?: TwelveMonthTyreInsuranceModelsCountryModel;
    /**
     * 
     * @type {TwelveMonthTyreInsuranceModelsStatusModel}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyModel
     */
    'Status'?: TwelveMonthTyreInsuranceModelsStatusModel;
    /**
     * 
     * @type {number}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyModel
     */
    'TotalPurchasePriceExcludingVat'?: number;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyModel
     */
    'DateOfPurchase'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyModel
     */
    'PolicyNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyModel
     */
    'InvoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyModel
     */
    'UploadedInvoiceAbsolutePath'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyModel
     */
    'TermsAndConditionsAccepted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TwelveMonthTyreInsuranceModelsPolicyModel
     */
    'CustomerAcceptsEnteredDetails'?: boolean;
}
/**
 * 
 * @export
 * @interface TwelveMonthTyreInsuranceModelsStatusModel
 */
export interface TwelveMonthTyreInsuranceModelsStatusModel {
    /**
     * 
     * @type {number}
     * @memberof TwelveMonthTyreInsuranceModelsStatusModel
     */
    'Id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsStatusModel
     */
    'Name'?: string;
}
/**
 * 
 * @export
 * @interface TwelveMonthTyreInsuranceModelsTyreInformationModel
 */
export interface TwelveMonthTyreInsuranceModelsTyreInformationModel {
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsTyreInformationModel
     */
    'Range'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsTyreInformationModel
     */
    'Pattern'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsTyreInformationModel
     */
    'Size'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsTyreInformationModel
     */
    'Sku'?: string;
}
/**
 * 
 * @export
 * @interface TwelveMonthTyreInsuranceModelsTyreModel
 */
export interface TwelveMonthTyreInsuranceModelsTyreModel {
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsTyreModel
     */
    'SerialNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsTyreModel
     */
    'ManufactureDate'?: string;
}
/**
 * 
 * @export
 * @interface TwelveMonthTyreInsuranceModelsTyrePatternListModel
 */
export interface TwelveMonthTyreInsuranceModelsTyrePatternListModel {
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsTyrePatternListModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsTyrePatternListModel
     */
    'Name'?: string;
}
/**
 * 
 * @export
 * @interface TwelveMonthTyreInsuranceModelsTyreRangeListModel
 */
export interface TwelveMonthTyreInsuranceModelsTyreRangeListModel {
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsTyreRangeListModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsTyreRangeListModel
     */
    'Name'?: string;
}
/**
 * 
 * @export
 * @interface TwelveMonthTyreInsuranceModelsTyreSizeModel
 */
export interface TwelveMonthTyreInsuranceModelsTyreSizeModel {
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsTyreSizeModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsTyreSizeModel
     */
    'Name'?: string;
    /**
     * 
     * @type {TwelveMonthTyreInsuranceModelsTyreSkuListModel}
     * @memberof TwelveMonthTyreInsuranceModelsTyreSizeModel
     */
    'Sku'?: TwelveMonthTyreInsuranceModelsTyreSkuListModel;
}
/**
 * 
 * @export
 * @interface TwelveMonthTyreInsuranceModelsTyreSkuListModel
 */
export interface TwelveMonthTyreInsuranceModelsTyreSkuListModel {
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsTyreSkuListModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsTyreSkuListModel
     */
    'Name'?: string;
}
/**
 * 
 * @export
 * @interface TwelveMonthTyreInsuranceModelsVehicleModel
 */
export interface TwelveMonthTyreInsuranceModelsVehicleModel {
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsVehicleModel
     */
    'Name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsVehicleModel
     */
    'RegistrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceModelsVehicleModel
     */
    'MyTyresVehicleId'?: string;
}
/**
 * 
 * @export
 * @interface TwelveMonthTyreInsuranceSubmitPolicyViewModel
 */
export interface TwelveMonthTyreInsuranceSubmitPolicyViewModel {
    /**
     * 
     * @type {TwelveMonthTyreInsuranceModelsDealerModel}
     * @memberof TwelveMonthTyreInsuranceSubmitPolicyViewModel
     */
    'Dealer'?: TwelveMonthTyreInsuranceModelsDealerModel;
    /**
     * 
     * @type {TwelveMonthTyreInsuranceModelsCustomerModel}
     * @memberof TwelveMonthTyreInsuranceSubmitPolicyViewModel
     */
    'Customer'?: TwelveMonthTyreInsuranceModelsCustomerModel;
    /**
     * 
     * @type {TwelveMonthTyreInsuranceModelsTyreInformationModel}
     * @memberof TwelveMonthTyreInsuranceSubmitPolicyViewModel
     */
    'TyreInformation'?: TwelveMonthTyreInsuranceModelsTyreInformationModel;
    /**
     * 
     * @type {TwelveMonthTyreInsuranceModelsVehicleModel}
     * @memberof TwelveMonthTyreInsuranceSubmitPolicyViewModel
     */
    'Vehicle'?: TwelveMonthTyreInsuranceModelsVehicleModel;
    /**
     * 
     * @type {Array<TwelveMonthTyreInsuranceModelsTyreModel>}
     * @memberof TwelveMonthTyreInsuranceSubmitPolicyViewModel
     */
    'Tyres'?: Array<TwelveMonthTyreInsuranceModelsTyreModel>;
    /**
     * 
     * @type {TwelveMonthTyreInsuranceModelsCountryModel}
     * @memberof TwelveMonthTyreInsuranceSubmitPolicyViewModel
     */
    'Country'?: TwelveMonthTyreInsuranceModelsCountryModel;
    /**
     * 
     * @type {TwelveMonthTyreInsuranceModelsStatusModel}
     * @memberof TwelveMonthTyreInsuranceSubmitPolicyViewModel
     */
    'Status'?: TwelveMonthTyreInsuranceModelsStatusModel;
    /**
     * 
     * @type {number}
     * @memberof TwelveMonthTyreInsuranceSubmitPolicyViewModel
     */
    'TotalPurchasePriceExcludingVat'?: number;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceSubmitPolicyViewModel
     */
    'DateOfPurchase'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceSubmitPolicyViewModel
     */
    'PolicyNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceSubmitPolicyViewModel
     */
    'InvoiceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwelveMonthTyreInsuranceSubmitPolicyViewModel
     */
    'UploadedInvoiceRelativePath'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TwelveMonthTyreInsuranceSubmitPolicyViewModel
     */
    'TermsAndConditionsAccepted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TwelveMonthTyreInsuranceSubmitPolicyViewModel
     */
    'CustomerAcceptsEnteredDetails'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TwelveMonthTyreInsuranceSubmitPolicyViewModel
     */
    'IsMyDunlopDashboardRequest'?: boolean;
}
/**
 * 
 * @export
 * @interface TyreTreadPatternsSingleTyreTreadPattern
 */
export interface TyreTreadPatternsSingleTyreTreadPattern {
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternsSingleTyreTreadPattern
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternsSingleTyreTreadPattern
     */
    'Name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternsSingleTyreTreadPattern
     */
    'TyreRange'?: string;
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternsSingleTyreTreadPattern
     */
    'Description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternsSingleTyreTreadPattern
     */
    'ImageUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof TyreTreadPatternsSingleTyreTreadPattern
     */
    'WetGripRating'?: number;
    /**
     * 
     * @type {number}
     * @memberof TyreTreadPatternsSingleTyreTreadPattern
     */
    'DryGripRating'?: number;
    /**
     * 
     * @type {number}
     * @memberof TyreTreadPatternsSingleTyreTreadPattern
     */
    'MileageRating'?: number;
    /**
     * 
     * @type {number}
     * @memberof TyreTreadPatternsSingleTyreTreadPattern
     */
    'NoiseRating'?: number;
    /**
     * 
     * @type {number}
     * @memberof TyreTreadPatternsSingleTyreTreadPattern
     */
    'ComfortRating'?: number;
    /**
     * 
     * @type {number}
     * @memberof TyreTreadPatternsSingleTyreTreadPattern
     */
    'ValueRating'?: number;
    /**
     * 
     * @type {Array<TyreTreadPatternsTyreSku>}
     * @memberof TyreTreadPatternsSingleTyreTreadPattern
     */
    'TyreSkus'?: Array<TyreTreadPatternsTyreSku>;
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternsSingleTyreTreadPattern
     */
    'TyreRangeSlug'?: string;
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternsSingleTyreTreadPattern
     */
    'BrandDesc'?: string;
}
/**
 * 
 * @export
 * @interface TyreTreadPatternsTyreSku
 */
export interface TyreTreadPatternsTyreSku {
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternsTyreSku
     */
    'TyreSkuId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternsTyreSku
     */
    'DisplaySize'?: string;
    /**
     * 
     * @type {number}
     * @memberof TyreTreadPatternsTyreSku
     */
    'RimSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof TyreTreadPatternsTyreSku
     */
    'AspectRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof TyreTreadPatternsTyreSku
     */
    'SectionWidth'?: number;
}
/**
 * 
 * @export
 * @interface TyreTreadPatternsTyreTreadPattern
 */
export interface TyreTreadPatternsTyreTreadPattern {
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternsTyreTreadPattern
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternsTyreTreadPattern
     */
    'Name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternsTyreTreadPattern
     */
    'BrandDesc'?: string;
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternsTyreTreadPattern
     */
    'TyreRange'?: string;
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternsTyreTreadPattern
     */
    'ThumbImageUrl'?: string;
}
/**
 * 
 * @export
 * @interface TyreTreadPatternsTyreTreadPatternResponse
 */
export interface TyreTreadPatternsTyreTreadPatternResponse {
    /**
     * 
     * @type {Array<TyreTreadPatternsTyreTreadPattern>}
     * @memberof TyreTreadPatternsTyreTreadPatternResponse
     */
    'Items'?: Array<TyreTreadPatternsTyreTreadPattern>;
    /**
     * 
     * @type {number}
     * @memberof TyreTreadPatternsTyreTreadPatternResponse
     */
    'ItemCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TyreTreadPatternsTyreTreadPatternResponse
     */
    'Skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof TyreTreadPatternsTyreTreadPatternResponse
     */
    'Take'?: number;
}
/**
 * 
 * @export
 * @interface TyreTreadPatternsTyresRange
 */
export interface TyreTreadPatternsTyresRange {
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternsTyresRange
     */
    'Slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof TyreTreadPatternsTyresRange
     */
    'Name'?: string;
}
/**
 * 
 * @export
 * @interface TyreTreadPatternsTyresRangesResponse
 */
export interface TyreTreadPatternsTyresRangesResponse {
    /**
     * 
     * @type {Array<TyreTreadPatternsTyresRange>}
     * @memberof TyreTreadPatternsTyresRangesResponse
     */
    'Items'?: Array<TyreTreadPatternsTyresRange>;
    /**
     * 
     * @type {number}
     * @memberof TyreTreadPatternsTyresRangesResponse
     */
    'ItemCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TyreTreadPatternsTyresRangesResponse
     */
    'Skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof TyreTreadPatternsTyresRangesResponse
     */
    'Take'?: number;
}
/**
 * 
 * @export
 * @interface VehicleTyresModelsVehicleTyreModel
 */
export interface VehicleTyresModelsVehicleTyreModel {
    /**
     * 
     * @type {string}
     * @memberof VehicleTyresModelsVehicleTyreModel
     */
    'RangeName': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTyresModelsVehicleTyreModel
     */
    'AspectRatio': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTyresModelsVehicleTyreModel
     */
    'RimSize': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTyresModelsVehicleTyreModel
     */
    'SectionWidth': string;
    /**
     * 
     * @type {number}
     * @memberof VehicleTyresModelsVehicleTyreModel
     */
    'SetCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleTyresModelsVehicleTyreModel
     */
    'ImageRelativeUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTyresModelsVehicleTyreModel
     */
    'FitmentDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTyresModelsVehicleTyreModel
     */
    'LicenceDiskRaw'?: string;
}
/**
 * 
 * @export
 * @interface VehicleTyresModelsVehicleTyreModelWithId
 */
export interface VehicleTyresModelsVehicleTyreModelWithId {
    /**
     * 
     * @type {string}
     * @memberof VehicleTyresModelsVehicleTyreModelWithId
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTyresModelsVehicleTyreModelWithId
     */
    'RangeName': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTyresModelsVehicleTyreModelWithId
     */
    'AspectRatio': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTyresModelsVehicleTyreModelWithId
     */
    'RimSize': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTyresModelsVehicleTyreModelWithId
     */
    'SectionWidth': string;
    /**
     * 
     * @type {number}
     * @memberof VehicleTyresModelsVehicleTyreModelWithId
     */
    'SetCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleTyresModelsVehicleTyreModelWithId
     */
    'ImageRelativeUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTyresModelsVehicleTyreModelWithId
     */
    'FitmentDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTyresModelsVehicleTyreModelWithId
     */
    'LicenceDiskRaw'?: string;
}
/**
 * 
 * @export
 * @interface VehicleTyresModelsVehicleTyresResponse
 */
export interface VehicleTyresModelsVehicleTyresResponse {
    /**
     * 
     * @type {Array<VehicleTyresModelsVehicleTyreModelWithId>}
     * @memberof VehicleTyresModelsVehicleTyresResponse
     */
    'Items'?: Array<VehicleTyresModelsVehicleTyreModelWithId>;
    /**
     * 
     * @type {number}
     * @memberof VehicleTyresModelsVehicleTyresResponse
     */
    'ItemCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleTyresModelsVehicleTyresResponse
     */
    'Skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleTyresModelsVehicleTyresResponse
     */
    'Take'?: number;
}
/**
 * 
 * @export
 * @interface VehiclesModelsVehicleModel
 */
export interface VehiclesModelsVehicleModel {
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModel
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModel
     */
    'LicenseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModel
     */
    'VinNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModel
     */
    'ExpiryDate': string;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModel
     */
    'NextReminderDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModel
     */
    'ImageRelativeUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModel
     */
    'Make'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehiclesModelsVehicleModel
     */
    'MakeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModel
     */
    'Model'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehiclesModelsVehicleModel
     */
    'ModelId'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModel
     */
    'Specification'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehiclesModelsVehicleModel
     */
    'SpecificationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehiclesModelsVehicleModel
     */
    'EstimatedMonthlyMileage'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModel
     */
    'Colour'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehiclesModelsVehicleModel
     */
    'VehicleType'?: VehiclesModelsVehicleModelVehicleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModel
     */
    'EngineNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehiclesModelsVehicleModel
     */
    'Tare'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VehiclesModelsVehicleModelVehicleTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9
}

/**
 * 
 * @export
 * @interface VehiclesModelsVehicleModelWithId
 */
export interface VehiclesModelsVehicleModelWithId {
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'ImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'ImageRelativeUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'LicenseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'VinNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'ExpiryDate': string;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'NextReminderDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'Make'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'MakeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'Model'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'ModelId'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'Specification'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'SpecificationId'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'EstimatedMonthlyMileage'?: number;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'Colour'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'VehicleType'?: VehiclesModelsVehicleModelWithIdVehicleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'EngineNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehiclesModelsVehicleModelWithId
     */
    'Tare'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VehiclesModelsVehicleModelWithIdVehicleTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9
}

/**
 * 
 * @export
 * @interface VehiclesModelsVehiclesResponse
 */
export interface VehiclesModelsVehiclesResponse {
    /**
     * 
     * @type {Array<VehiclesModelsVehicleModelWithId>}
     * @memberof VehiclesModelsVehiclesResponse
     */
    'Items'?: Array<VehiclesModelsVehicleModelWithId>;
    /**
     * 
     * @type {number}
     * @memberof VehiclesModelsVehiclesResponse
     */
    'ItemCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehiclesModelsVehiclesResponse
     */
    'Skip'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehiclesModelsVehiclesResponse
     */
    'Take'?: number;
}
/**
 * 
 * @export
 * @interface WarrantyModelAddWarrantyModel
 */
export interface WarrantyModelAddWarrantyModel {
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'MyTyresUserProfileId'?: string;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'CountryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'DealerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'TotalPurchasePrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'DateOfPurchase'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'CounterHandName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'CounterHandCellNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'UserEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'VehicleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'VehicleLicenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'VehicleId'?: string;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'CurrentVehicleMileage'?: number;
    /**
     * 
     * @type {WarrantyModelUploadFile}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'InvoicePhoto'?: WarrantyModelUploadFile;
    /**
     * 
     * @type {WarrantyModelUploadFile}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'WheelAlignmentCertificatePhoto'?: WarrantyModelUploadFile;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'WheelAlignmentCertificatePhotoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'InvoicePhotoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'TreadPattern'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'TyreSize'?: string;
    /**
     * 
     * @type {Array<WarrantyModelWarrantyTyre>}
     * @memberof WarrantyModelAddWarrantyModel
     */
    'TyreInfo'?: Array<WarrantyModelWarrantyTyre>;
}
/**
 * 
 * @export
 * @interface WarrantyModelCurrency
 */
export interface WarrantyModelCurrency {
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelCurrency
     */
    'CountryId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelCurrency
     */
    'MyTyresCountryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelCurrency
     */
    'CurrencyFormat'?: string;
}
/**
 * 
 * @export
 * @interface WarrantyModelTreadPatternLookUpModel
 */
export interface WarrantyModelTreadPatternLookUpModel {
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelTreadPatternLookUpModel
     */
    'Sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelTreadPatternLookUpModel
     */
    'Tread'?: string;
}
/**
 * 
 * @export
 * @interface WarrantyModelTyreSizeLookupModel
 */
export interface WarrantyModelTyreSizeLookupModel {
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelTyreSizeLookupModel
     */
    'Sku'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelTyreSizeLookupModel
     */
    'Size'?: string;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelTyreSizeLookupModel
     */
    'MileageWarranty'?: number;
}
/**
 * 
 * @export
 * @interface WarrantyModelUploadFile
 */
export interface WarrantyModelUploadFile {
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelUploadFile
     */
    'FileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelUploadFile
     */
    'ContentType'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelUploadFile
     */
    'FileBytes'?: string;
}
/**
 * 
 * @export
 * @interface WarrantyModelWarrantyClaimModel
 */
export interface WarrantyModelWarrantyClaimModel {
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyClaimModel
     */
    'Id'?: string;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelWarrantyClaimModel
     */
    'DealerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelWarrantyClaimModel
     */
    'CountryId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WarrantyModelWarrantyClaimModel
     */
    'IsClaimedInDifferentCountry'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyClaimModel
     */
    'DateOfClaim'?: string;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelWarrantyClaimModel
     */
    'CurrentVehicleMileage'?: number;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyClaimModel
     */
    'WarrantyClaimStatusId'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof WarrantyModelWarrantyClaimModel
     */
    'TreadDepths'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelWarrantyClaimModel
     */
    'ClaimValue'?: number;
    /**
     * 
     * @type {Array<WarrantyModelUploadFile>}
     * @memberof WarrantyModelWarrantyClaimModel
     */
    'TyreWearPhotos'?: Array<WarrantyModelUploadFile>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WarrantyModelWarrantyClaimModel
     */
    'TyreWearPhotoUrls'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyClaimModel
     */
    'WarrantyId'?: string;
    /**
     * 
     * @type {Array<WarrantyModelWarrantyTyre>}
     * @memberof WarrantyModelWarrantyClaimModel
     */
    'TyreInfo'?: Array<WarrantyModelWarrantyTyre>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WarrantyModelWarrantyClaimModel
     */
    'TyreWearUrls'?: Array<string>;
}
/**
 * 
 * @export
 * @interface WarrantyModelWarrantyMaintenanceModel
 */
export interface WarrantyModelWarrantyMaintenanceModel {
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyMaintenanceModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyMaintenanceModel
     */
    'WarrantyId'?: string;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelWarrantyMaintenanceModel
     */
    'DealerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyMaintenanceModel
     */
    'DealerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyMaintenanceModel
     */
    'MaintenaceDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelWarrantyMaintenanceModel
     */
    'CurrentVehicleMileage'?: number;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelWarrantyMaintenanceModel
     */
    'InvoiceSimpleFileId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelWarrantyMaintenanceModel
     */
    'WheelAlignmentCertificateSimpleFileId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyMaintenanceModel
     */
    'InvoicePhotoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyMaintenanceModel
     */
    'WheelAlignmentCertificatePhotoUrl'?: string;
    /**
     * 
     * @type {WarrantyModelUploadFile}
     * @memberof WarrantyModelWarrantyMaintenanceModel
     */
    'InvoicePhoto'?: WarrantyModelUploadFile;
    /**
     * 
     * @type {WarrantyModelUploadFile}
     * @memberof WarrantyModelWarrantyMaintenanceModel
     */
    'WheelAlignmentCertificatePhoto'?: WarrantyModelUploadFile;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyMaintenanceModel
     */
    'WheelAlignmentCertificateUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyMaintenanceModel
     */
    'InvoiceUrl'?: string;
}
/**
 * 
 * @export
 * @interface WarrantyModelWarrantyModel
 */
export interface WarrantyModelWarrantyModel {
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'CountryName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'DealerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'ReferenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'StatusId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'Status'?: string;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelWarrantyModel
     */
    'InvoiceSimpleFileId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelWarrantyModel
     */
    'WheelAlignmentCertificateSimpleFileId'?: number;
    /**
     * 
     * @type {Array<WarrantyModelWarrantyMaintenanceModel>}
     * @memberof WarrantyModelWarrantyModel
     */
    'WarrantyMaintenances'?: Array<WarrantyModelWarrantyMaintenanceModel>;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelWarrantyModel
     */
    'TyreMileageWarranty'?: number;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'Comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'Username'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'WheelAlignmentCertificateUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'InvoiceUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'MyTyresUserProfileId'?: string;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelWarrantyModel
     */
    'CountryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelWarrantyModel
     */
    'DealerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelWarrantyModel
     */
    'TotalPurchasePrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'DateOfPurchase'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'CounterHandName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'CounterHandCellNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'UserEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'VehicleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'VehicleLicenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'VehicleId'?: string;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelWarrantyModel
     */
    'CurrentVehicleMileage'?: number;
    /**
     * 
     * @type {WarrantyModelUploadFile}
     * @memberof WarrantyModelWarrantyModel
     */
    'InvoicePhoto'?: WarrantyModelUploadFile;
    /**
     * 
     * @type {WarrantyModelUploadFile}
     * @memberof WarrantyModelWarrantyModel
     */
    'WheelAlignmentCertificatePhoto'?: WarrantyModelUploadFile;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'WheelAlignmentCertificatePhotoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'InvoicePhotoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'TreadPattern'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyModel
     */
    'TyreSize'?: string;
    /**
     * 
     * @type {Array<WarrantyModelWarrantyTyre>}
     * @memberof WarrantyModelWarrantyModel
     */
    'TyreInfo'?: Array<WarrantyModelWarrantyTyre>;
}
/**
 * 
 * @export
 * @interface WarrantyModelWarrantyTyre
 */
export interface WarrantyModelWarrantyTyre {
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyTyre
     */
    'SerialNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyTyre
     */
    'ManufactureDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof WarrantyModelWarrantyTyre
     */
    'TreadDepth'?: number;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyTyre
     */
    'Id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyTyre
     */
    'TyreSize'?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyModelWarrantyTyre
     */
    'TreadPattern'?: string;
}

/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ModelsRegisterAppUserRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicAccountsPost: async (request?: ModelsRegisterAppUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/public/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ModelsRegisterAppUserRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicAccountsPost(request?: ModelsRegisterAppUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicAccountsPost(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * 
         * @param {ModelsRegisterAppUserRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicAccountsPost(request?: ModelsRegisterAppUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiPublicAccountsPost(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * 
     * @param {ModelsRegisterAppUserRequest} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public apiPublicAccountsPost(request?: ModelsRegisterAppUserRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).apiPublicAccountsPost(request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CarLincenceApi - axios parameter creator
 * @export
 */
export const CarLincenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [registrationNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicenceBlocksGet: async (registrationNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/car-licence/blocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (registrationNumber !== undefined) {
                localVarQueryParameter['registrationNumber'] = registrationNumber;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CarLicenceModelsSetBlocksForPaymentRequest} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicenceBlocksSetForPaymentPost: async (model?: CarLicenceModelsSetBlocksForPaymentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/car-licence/blocks/setForPayment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(model, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CarLicenceModelsVendBlocksRequest} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicenceBlocksVendPost: async (model?: CarLicenceModelsVendBlocksRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/car-licence/blocks/vend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(model, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicenceMineVendStatusesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/car-licence/mine/vend/statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} registrationNumber 
         * @param {number} [paymentFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicencePaymentUrlByRegistrationNumberGet: async (registrationNumber: string, paymentFor?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationNumber' is not null or undefined
            assertParamExists('apiPrivateCarLicencePaymentUrlByRegistrationNumberGet', 'registrationNumber', registrationNumber)
            const localVarPath = `/api/private/car-licence/payment/url/{registrationNumber}`
                .replace(`{${"registrationNumber"}}`, encodeURIComponent(String(registrationNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (paymentFor !== undefined) {
                localVarQueryParameter['paymentFor'] = paymentFor;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CarLicenceModelsQuoteRequestModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicenceQuotePost: async (model?: CarLicenceModelsQuoteRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/car-licence/quote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(model, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CarLicenceModelsQuoteSingleVendRequestModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicenceSingleVendPost: async (model?: CarLicenceModelsQuoteSingleVendRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/car-licence/single/vend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(model, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} registrationNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicenceTrackAndTraceByRegistrationNumberGet: async (registrationNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationNumber' is not null or undefined
            assertParamExists('apiPrivateCarLicenceTrackAndTraceByRegistrationNumberGet', 'registrationNumber', registrationNumber)
            const localVarPath = `/api/private/car-licence/track-and-trace/{registrationNumber}`
                .replace(`{${"registrationNumber"}}`, encodeURIComponent(String(registrationNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} registrationNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicenceVendStatusByRegistrationNumberGet: async (registrationNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registrationNumber' is not null or undefined
            assertParamExists('apiPrivateCarLicenceVendStatusByRegistrationNumberGet', 'registrationNumber', registrationNumber)
            const localVarPath = `/api/private/car-licence/vend/status/{registrationNumber}`
                .replace(`{${"registrationNumber"}}`, encodeURIComponent(String(registrationNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CarLincenceApi - functional programming interface
 * @export
 */
export const CarLincenceApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = CarLincenceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [registrationNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateCarLicenceBlocksGet(registrationNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarLicenceModelsBlockResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateCarLicenceBlocksGet(registrationNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CarLicenceModelsSetBlocksForPaymentRequest} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateCarLicenceBlocksSetForPaymentPost(model?: CarLicenceModelsSetBlocksForPaymentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarLicenceModelsSingleVendResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateCarLicenceBlocksSetForPaymentPost(model, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CarLicenceModelsVendBlocksRequest} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateCarLicenceBlocksVendPost(model?: CarLicenceModelsVendBlocksRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarLicenceModelsSingleVendResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateCarLicenceBlocksVendPost(model, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateCarLicenceMineVendStatusesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarLicenceModelsUserVendStatusesResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateCarLicenceMineVendStatusesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} registrationNumber 
         * @param {number} [paymentFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateCarLicencePaymentUrlByRegistrationNumberGet(registrationNumber: string, paymentFor?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateCarLicencePaymentUrlByRegistrationNumberGet(registrationNumber, paymentFor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CarLicenceModelsQuoteRequestModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateCarLicenceQuotePost(model?: CarLicenceModelsQuoteRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarLicenceModelsQuoteResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateCarLicenceQuotePost(model, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CarLicenceModelsQuoteSingleVendRequestModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateCarLicenceSingleVendPost(model?: CarLicenceModelsQuoteSingleVendRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarLicenceModelsSingleVendResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateCarLicenceSingleVendPost(model, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} registrationNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateCarLicenceTrackAndTraceByRegistrationNumberGet(registrationNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarLicenceModelsTrackAndTraceResposeModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateCarLicenceTrackAndTraceByRegistrationNumberGet(registrationNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} registrationNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateCarLicenceVendStatusByRegistrationNumberGet(registrationNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarLicenceModelsVendStatusResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateCarLicenceVendStatusByRegistrationNumberGet(registrationNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CarLincenceApi - factory interface
 * @export
 */
export const CarLincenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CarLincenceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [registrationNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicenceBlocksGet(registrationNumber?: string, options?: any): AxiosPromise<Array<CarLicenceModelsBlockResponseModel>> {
            return localVarFp.apiPrivateCarLicenceBlocksGet(registrationNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CarLicenceModelsSetBlocksForPaymentRequest} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicenceBlocksSetForPaymentPost(model?: CarLicenceModelsSetBlocksForPaymentRequest, options?: any): AxiosPromise<CarLicenceModelsSingleVendResponseModel> {
            return localVarFp.apiPrivateCarLicenceBlocksSetForPaymentPost(model, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CarLicenceModelsVendBlocksRequest} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicenceBlocksVendPost(model?: CarLicenceModelsVendBlocksRequest, options?: any): AxiosPromise<CarLicenceModelsSingleVendResponseModel> {
            return localVarFp.apiPrivateCarLicenceBlocksVendPost(model, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicenceMineVendStatusesGet(options?: any): AxiosPromise<Array<CarLicenceModelsUserVendStatusesResponseModel>> {
            return localVarFp.apiPrivateCarLicenceMineVendStatusesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} registrationNumber 
         * @param {number} [paymentFor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicencePaymentUrlByRegistrationNumberGet(registrationNumber: string, paymentFor?: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiPrivateCarLicencePaymentUrlByRegistrationNumberGet(registrationNumber, paymentFor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CarLicenceModelsQuoteRequestModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicenceQuotePost(model?: CarLicenceModelsQuoteRequestModel, options?: any): AxiosPromise<CarLicenceModelsQuoteResponseModel> {
            return localVarFp.apiPrivateCarLicenceQuotePost(model, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CarLicenceModelsQuoteSingleVendRequestModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicenceSingleVendPost(model?: CarLicenceModelsQuoteSingleVendRequestModel, options?: any): AxiosPromise<CarLicenceModelsSingleVendResponseModel> {
            return localVarFp.apiPrivateCarLicenceSingleVendPost(model, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} registrationNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicenceTrackAndTraceByRegistrationNumberGet(registrationNumber: string, options?: any): AxiosPromise<CarLicenceModelsTrackAndTraceResposeModel> {
            return localVarFp.apiPrivateCarLicenceTrackAndTraceByRegistrationNumberGet(registrationNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} registrationNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateCarLicenceVendStatusByRegistrationNumberGet(registrationNumber: string, options?: any): AxiosPromise<CarLicenceModelsVendStatusResponseModel> {
            return localVarFp.apiPrivateCarLicenceVendStatusByRegistrationNumberGet(registrationNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CarLincenceApi - object-oriented interface
 * @export
 * @class CarLincenceApi
 * @extends {BaseAPI}
 */
export class CarLincenceApi extends BaseAPI {
    /**
     * 
     * @param {string} [registrationNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarLincenceApi
     */
    public apiPrivateCarLicenceBlocksGet(registrationNumber?: string, options?: AxiosRequestConfig) {
        return CarLincenceApiFp(this.configuration).apiPrivateCarLicenceBlocksGet(registrationNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CarLicenceModelsSetBlocksForPaymentRequest} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarLincenceApi
     */
    public apiPrivateCarLicenceBlocksSetForPaymentPost(model?: CarLicenceModelsSetBlocksForPaymentRequest, options?: AxiosRequestConfig) {
        return CarLincenceApiFp(this.configuration).apiPrivateCarLicenceBlocksSetForPaymentPost(model, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CarLicenceModelsVendBlocksRequest} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarLincenceApi
     */
    public apiPrivateCarLicenceBlocksVendPost(model?: CarLicenceModelsVendBlocksRequest, options?: AxiosRequestConfig) {
        return CarLincenceApiFp(this.configuration).apiPrivateCarLicenceBlocksVendPost(model, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarLincenceApi
     */
    public apiPrivateCarLicenceMineVendStatusesGet(options?: AxiosRequestConfig) {
        return CarLincenceApiFp(this.configuration).apiPrivateCarLicenceMineVendStatusesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} registrationNumber 
     * @param {number} [paymentFor] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarLincenceApi
     */
    public apiPrivateCarLicencePaymentUrlByRegistrationNumberGet(registrationNumber: string, paymentFor?: number, options?: AxiosRequestConfig) {
        return CarLincenceApiFp(this.configuration).apiPrivateCarLicencePaymentUrlByRegistrationNumberGet(registrationNumber, paymentFor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CarLicenceModelsQuoteRequestModel} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarLincenceApi
     */
    public apiPrivateCarLicenceQuotePost(model?: CarLicenceModelsQuoteRequestModel, options?: AxiosRequestConfig) {
        return CarLincenceApiFp(this.configuration).apiPrivateCarLicenceQuotePost(model, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CarLicenceModelsQuoteSingleVendRequestModel} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarLincenceApi
     */
    public apiPrivateCarLicenceSingleVendPost(model?: CarLicenceModelsQuoteSingleVendRequestModel, options?: AxiosRequestConfig) {
        return CarLincenceApiFp(this.configuration).apiPrivateCarLicenceSingleVendPost(model, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} registrationNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarLincenceApi
     */
    public apiPrivateCarLicenceTrackAndTraceByRegistrationNumberGet(registrationNumber: string, options?: AxiosRequestConfig) {
        return CarLincenceApiFp(this.configuration).apiPrivateCarLicenceTrackAndTraceByRegistrationNumberGet(registrationNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} registrationNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarLincenceApi
     */
    public apiPrivateCarLicenceVendStatusByRegistrationNumberGet(registrationNumber: string, options?: AxiosRequestConfig) {
        return CarLincenceApiFp(this.configuration).apiPrivateCarLicenceVendStatusByRegistrationNumberGet(registrationNumber, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DealerApi - axios parameter creator
 * @export
 */
export const DealerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateDealerCustomersByCustomerIdGet: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('apiPrivateDealerCustomersByCustomerIdGet', 'customerId', customerId)
            const localVarPath = `/api/private/dealer/customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateDealerCustomersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/dealer/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DealerModelsInviteCustomer} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateDealerRequestDealerCustomerPost: async (request?: DealerModelsInviteCustomer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/dealer/request-dealer-customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DealerApi - functional programming interface
 * @export
 */
export const DealerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = DealerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateDealerCustomersByCustomerIdGet(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileModelsProfileModelWithIdModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateDealerCustomersByCustomerIdGet(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateDealerCustomersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfileModelsProfileSmallModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateDealerCustomersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DealerModelsInviteCustomer} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateDealerRequestDealerCustomerPost(request?: DealerModelsInviteCustomer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateDealerRequestDealerCustomerPost(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DealerApi - factory interface
 * @export
 */
export const DealerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DealerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateDealerCustomersByCustomerIdGet(customerId: string, options?: any): AxiosPromise<ProfileModelsProfileModelWithIdModel> {
            return localVarFp.apiPrivateDealerCustomersByCustomerIdGet(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateDealerCustomersGet(options?: any): AxiosPromise<Array<ProfileModelsProfileSmallModel>> {
            return localVarFp.apiPrivateDealerCustomersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DealerModelsInviteCustomer} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateDealerRequestDealerCustomerPost(request?: DealerModelsInviteCustomer, options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateDealerRequestDealerCustomerPost(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DealerApi - object-oriented interface
 * @export
 * @class DealerApi
 * @extends {BaseAPI}
 */
export class DealerApi extends BaseAPI {
    /**
     * 
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealerApi
     */
    public apiPrivateDealerCustomersByCustomerIdGet(customerId: string, options?: AxiosRequestConfig) {
        return DealerApiFp(this.configuration).apiPrivateDealerCustomersByCustomerIdGet(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealerApi
     */
    public apiPrivateDealerCustomersGet(options?: AxiosRequestConfig) {
        return DealerApiFp(this.configuration).apiPrivateDealerCustomersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DealerModelsInviteCustomer} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealerApi
     */
    public apiPrivateDealerRequestDealerCustomerPost(request?: DealerModelsInviteCustomer, options?: AxiosRequestConfig) {
        return DealerApiFp(this.configuration).apiPrivateDealerRequestDealerCustomerPost(request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DealersApi - axios parameter creator
 * @export
 */
export const DealersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} countryId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {boolean} [showIndependents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCountriesByCountryIdCountryDealersGet: async (countryId: string, skip?: number, take?: number, showIndependents?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('apiPublicCountriesByCountryIdCountryDealersGet', 'countryId', countryId)
            const localVarPath = `/api/public/countries/{countryId}/country-dealers`
                .replace(`{${"countryId"}}`, encodeURIComponent(String(countryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (showIndependents !== undefined) {
                localVarQueryParameter['showIndependents'] = showIndependents;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} countryId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCountriesByCountryIdDealersGet: async (countryId: string, skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('apiPublicCountriesByCountryIdDealersGet', 'countryId', countryId)
            const localVarPath = `/api/public/countries/{countryId}/dealers`
                .replace(`{${"countryId"}}`, encodeURIComponent(String(countryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} countryId 
         * @param {string} provinceId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {boolean} [competitions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCountriesByCountryIdProvincesByProvinceIdDealersGet: async (countryId: string, provinceId: string, skip?: number, take?: number, competitions?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('apiPublicCountriesByCountryIdProvincesByProvinceIdDealersGet', 'countryId', countryId)
            // verify required parameter 'provinceId' is not null or undefined
            assertParamExists('apiPublicCountriesByCountryIdProvincesByProvinceIdDealersGet', 'provinceId', provinceId)
            const localVarPath = `/api/public/countries/{countryId}/provinces/{provinceId}/dealers`
                .replace(`{${"countryId"}}`, encodeURIComponent(String(countryId)))
                .replace(`{${"provinceId"}}`, encodeURIComponent(String(provinceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (competitions !== undefined) {
                localVarQueryParameter['competitions'] = competitions;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} countryId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCountriesByCountryIdProvincesGet: async (countryId: string, skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            assertParamExists('apiPublicCountriesByCountryIdProvincesGet', 'countryId', countryId)
            const localVarPath = `/api/public/countries/{countryId}/provinces`
                .replace(`{${"countryId"}}`, encodeURIComponent(String(countryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCountriesGet: async (skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/public/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dealerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicDealersByDealerIdGet: async (dealerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dealerId' is not null or undefined
            assertParamExists('apiPublicDealersByDealerIdGet', 'dealerId', dealerId)
            const localVarPath = `/api/public/dealers/{dealerId}`
                .replace(`{${"dealerId"}}`, encodeURIComponent(String(dealerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DealersApi - functional programming interface
 * @export
 */
export const DealersApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = DealersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} countryId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {boolean} [showIndependents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicCountriesByCountryIdCountryDealersGet(countryId: string, skip?: number, take?: number, showIndependents?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealersModelsDealerForMapResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicCountriesByCountryIdCountryDealersGet(countryId, skip, take, showIndependents, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} countryId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicCountriesByCountryIdDealersGet(countryId: string, skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealersModelsDealerForMapResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicCountriesByCountryIdDealersGet(countryId, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} countryId 
         * @param {string} provinceId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {boolean} [competitions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicCountriesByCountryIdProvincesByProvinceIdDealersGet(countryId: string, provinceId: string, skip?: number, take?: number, competitions?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealersModelsDealerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicCountriesByCountryIdProvincesByProvinceIdDealersGet(countryId, provinceId, skip, take, competitions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} countryId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicCountriesByCountryIdProvincesGet(countryId: string, skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealersModelsLocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicCountriesByCountryIdProvincesGet(countryId, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicCountriesGet(skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealersModelsLocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicCountriesGet(skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dealerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicDealersByDealerIdGet(dealerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealersModelsSingleDealerModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicDealersByDealerIdGet(dealerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DealersApi - factory interface
 * @export
 */
export const DealersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DealersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} countryId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {boolean} [showIndependents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCountriesByCountryIdCountryDealersGet(countryId: string, skip?: number, take?: number, showIndependents?: boolean, options?: any): AxiosPromise<DealersModelsDealerForMapResponse> {
            return localVarFp.apiPublicCountriesByCountryIdCountryDealersGet(countryId, skip, take, showIndependents, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} countryId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCountriesByCountryIdDealersGet(countryId: string, skip?: number, take?: number, options?: any): AxiosPromise<DealersModelsDealerForMapResponse> {
            return localVarFp.apiPublicCountriesByCountryIdDealersGet(countryId, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} countryId 
         * @param {string} provinceId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {boolean} [competitions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCountriesByCountryIdProvincesByProvinceIdDealersGet(countryId: string, provinceId: string, skip?: number, take?: number, competitions?: boolean, options?: any): AxiosPromise<DealersModelsDealerResponse> {
            return localVarFp.apiPublicCountriesByCountryIdProvincesByProvinceIdDealersGet(countryId, provinceId, skip, take, competitions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} countryId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCountriesByCountryIdProvincesGet(countryId: string, skip?: number, take?: number, options?: any): AxiosPromise<DealersModelsLocationResponse> {
            return localVarFp.apiPublicCountriesByCountryIdProvincesGet(countryId, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCountriesGet(skip?: number, take?: number, options?: any): AxiosPromise<DealersModelsLocationResponse> {
            return localVarFp.apiPublicCountriesGet(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dealerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicDealersByDealerIdGet(dealerId: string, options?: any): AxiosPromise<DealersModelsSingleDealerModel> {
            return localVarFp.apiPublicDealersByDealerIdGet(dealerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DealersApi - object-oriented interface
 * @export
 * @class DealersApi
 * @extends {BaseAPI}
 */
export class DealersApi extends BaseAPI {
    /**
     * 
     * @param {string} countryId 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {boolean} [showIndependents] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealersApi
     */
    public apiPublicCountriesByCountryIdCountryDealersGet(countryId: string, skip?: number, take?: number, showIndependents?: boolean, options?: AxiosRequestConfig) {
        return DealersApiFp(this.configuration).apiPublicCountriesByCountryIdCountryDealersGet(countryId, skip, take, showIndependents, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} countryId 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealersApi
     */
    public apiPublicCountriesByCountryIdDealersGet(countryId: string, skip?: number, take?: number, options?: AxiosRequestConfig) {
        return DealersApiFp(this.configuration).apiPublicCountriesByCountryIdDealersGet(countryId, skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} countryId 
     * @param {string} provinceId 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {boolean} [competitions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealersApi
     */
    public apiPublicCountriesByCountryIdProvincesByProvinceIdDealersGet(countryId: string, provinceId: string, skip?: number, take?: number, competitions?: boolean, options?: AxiosRequestConfig) {
        return DealersApiFp(this.configuration).apiPublicCountriesByCountryIdProvincesByProvinceIdDealersGet(countryId, provinceId, skip, take, competitions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} countryId 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealersApi
     */
    public apiPublicCountriesByCountryIdProvincesGet(countryId: string, skip?: number, take?: number, options?: AxiosRequestConfig) {
        return DealersApiFp(this.configuration).apiPublicCountriesByCountryIdProvincesGet(countryId, skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealersApi
     */
    public apiPublicCountriesGet(skip?: number, take?: number, options?: AxiosRequestConfig) {
        return DealersApiFp(this.configuration).apiPublicCountriesGet(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dealerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DealersApi
     */
    public apiPublicDealersByDealerIdGet(dealerId: string, options?: AxiosRequestConfig) {
        return DealersApiFp(this.configuration).apiPublicDealersByDealerIdGet(dealerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExternalAccessApi - axios parameter creator
 * @export
 */
export const ExternalAccessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateExternalaccessGetcustomerdetailsByProfileIdGet: async (profileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            assertParamExists('apiPrivateExternalaccessGetcustomerdetailsByProfileIdGet', 'profileId', profileId)
            const localVarPath = `/api/private/externalaccess/getcustomerdetails/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalAccessApi - functional programming interface
 * @export
 */
export const ExternalAccessApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ExternalAccessApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateExternalaccessGetcustomerdetailsByProfileIdGet(profileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateExternalaccessGetcustomerdetailsByProfileIdGet(profileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExternalAccessApi - factory interface
 * @export
 */
export const ExternalAccessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExternalAccessApiFp(configuration)
    return {
        /**
         * 
         * @param {string} profileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateExternalaccessGetcustomerdetailsByProfileIdGet(profileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateExternalaccessGetcustomerdetailsByProfileIdGet(profileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExternalAccessApi - object-oriented interface
 * @export
 * @class ExternalAccessApi
 * @extends {BaseAPI}
 */
export class ExternalAccessApi extends BaseAPI {
    /**
     * 
     * @param {string} profileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalAccessApi
     */
    public apiPrivateExternalaccessGetcustomerdetailsByProfileIdGet(profileId: string, options?: AxiosRequestConfig) {
        return ExternalAccessApiFp(this.configuration).apiPrivateExternalaccessGetcustomerdetailsByProfileIdGet(profileId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MileageLogsApi - axios parameter creator
 * @export
 */
export const MileageLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdMileageLogsByIdDelete: async (id: string, vehicleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPrivateVehiclesByVehicleIdMileageLogsByIdDelete', 'id', id)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiPrivateVehiclesByVehicleIdMileageLogsByIdDelete', 'vehicleId', vehicleId)
            const localVarPath = `/api/private/vehicles/{vehicleId}/mileage-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} vehicleId 
         * @param {MileageLogsModelsMileageLogModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdMileageLogsByIdPost: async (id: string, vehicleId: string, request?: MileageLogsModelsMileageLogModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPrivateVehiclesByVehicleIdMileageLogsByIdPost', 'id', id)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiPrivateVehiclesByVehicleIdMileageLogsByIdPost', 'vehicleId', vehicleId)
            const localVarPath = `/api/private/vehicles/{vehicleId}/mileage-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdMileageLogsGet: async (vehicleId: string, skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiPrivateVehiclesByVehicleIdMileageLogsGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/private/vehicles/{vehicleId}/mileage-logs`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {MileageLogsModelsMileageLogModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdMileageLogsPut: async (vehicleId: string, request?: MileageLogsModelsMileageLogModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiPrivateVehiclesByVehicleIdMileageLogsPut', 'vehicleId', vehicleId)
            const localVarPath = `/api/private/vehicles/{vehicleId}/mileage-logs`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {string} [emailAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdSendMileageReportPost: async (vehicleId: string, emailAddress?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiPrivateVehiclesByVehicleIdSendMileageReportPost', 'vehicleId', vehicleId)
            const localVarPath = `/api/private/vehicles/{vehicleId}/send-mileage-report`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MileageLogsApi - functional programming interface
 * @export
 */
export const MileageLogsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MileageLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateVehiclesByVehicleIdMileageLogsByIdDelete(id: string, vehicleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateVehiclesByVehicleIdMileageLogsByIdDelete(id, vehicleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} vehicleId 
         * @param {MileageLogsModelsMileageLogModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateVehiclesByVehicleIdMileageLogsByIdPost(id: string, vehicleId: string, request?: MileageLogsModelsMileageLogModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateVehiclesByVehicleIdMileageLogsByIdPost(id, vehicleId, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateVehiclesByVehicleIdMileageLogsGet(vehicleId: string, skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MileageLogsModelsMileageLogsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateVehiclesByVehicleIdMileageLogsGet(vehicleId, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {MileageLogsModelsMileageLogModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateVehiclesByVehicleIdMileageLogsPut(vehicleId: string, request?: MileageLogsModelsMileageLogModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MileageLogsModelsMileageLogModelWithId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateVehiclesByVehicleIdMileageLogsPut(vehicleId, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {string} [emailAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateVehiclesByVehicleIdSendMileageReportPost(vehicleId: string, emailAddress?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateVehiclesByVehicleIdSendMileageReportPost(vehicleId, emailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MileageLogsApi - factory interface
 * @export
 */
export const MileageLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MileageLogsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} vehicleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdMileageLogsByIdDelete(id: string, vehicleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateVehiclesByVehicleIdMileageLogsByIdDelete(id, vehicleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} vehicleId 
         * @param {MileageLogsModelsMileageLogModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdMileageLogsByIdPost(id: string, vehicleId: string, request?: MileageLogsModelsMileageLogModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateVehiclesByVehicleIdMileageLogsByIdPost(id, vehicleId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdMileageLogsGet(vehicleId: string, skip?: number, take?: number, options?: any): AxiosPromise<MileageLogsModelsMileageLogsResponse> {
            return localVarFp.apiPrivateVehiclesByVehicleIdMileageLogsGet(vehicleId, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {MileageLogsModelsMileageLogModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdMileageLogsPut(vehicleId: string, request?: MileageLogsModelsMileageLogModel, options?: any): AxiosPromise<MileageLogsModelsMileageLogModelWithId> {
            return localVarFp.apiPrivateVehiclesByVehicleIdMileageLogsPut(vehicleId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {string} [emailAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdSendMileageReportPost(vehicleId: string, emailAddress?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateVehiclesByVehicleIdSendMileageReportPost(vehicleId, emailAddress, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MileageLogsApi - object-oriented interface
 * @export
 * @class MileageLogsApi
 * @extends {BaseAPI}
 */
export class MileageLogsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {string} vehicleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MileageLogsApi
     */
    public apiPrivateVehiclesByVehicleIdMileageLogsByIdDelete(id: string, vehicleId: string, options?: AxiosRequestConfig) {
        return MileageLogsApiFp(this.configuration).apiPrivateVehiclesByVehicleIdMileageLogsByIdDelete(id, vehicleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} vehicleId 
     * @param {MileageLogsModelsMileageLogModel} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MileageLogsApi
     */
    public apiPrivateVehiclesByVehicleIdMileageLogsByIdPost(id: string, vehicleId: string, request?: MileageLogsModelsMileageLogModel, options?: AxiosRequestConfig) {
        return MileageLogsApiFp(this.configuration).apiPrivateVehiclesByVehicleIdMileageLogsByIdPost(id, vehicleId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vehicleId 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MileageLogsApi
     */
    public apiPrivateVehiclesByVehicleIdMileageLogsGet(vehicleId: string, skip?: number, take?: number, options?: AxiosRequestConfig) {
        return MileageLogsApiFp(this.configuration).apiPrivateVehiclesByVehicleIdMileageLogsGet(vehicleId, skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vehicleId 
     * @param {MileageLogsModelsMileageLogModel} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MileageLogsApi
     */
    public apiPrivateVehiclesByVehicleIdMileageLogsPut(vehicleId: string, request?: MileageLogsModelsMileageLogModel, options?: AxiosRequestConfig) {
        return MileageLogsApiFp(this.configuration).apiPrivateVehiclesByVehicleIdMileageLogsPut(vehicleId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vehicleId 
     * @param {string} [emailAddress] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MileageLogsApi
     */
    public apiPrivateVehiclesByVehicleIdSendMileageReportPost(vehicleId: string, emailAddress?: string, options?: AxiosRequestConfig) {
        return MileageLogsApiFp(this.configuration).apiPrivateVehiclesByVehicleIdSendMileageReportPost(vehicleId, emailAddress, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateProfileDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateProfileGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateProfileGetPendingDealerRequestsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/profile/get-pending-dealer-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProfileModelsProfileModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateProfilePost: async (request?: ProfileModelsProfileModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProfileModelsCustomerResponseToDealer} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateProfileRespondToDealerRequestPost: async (request?: ProfileModelsCustomerResponseToDealer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/profile/respond-to-dealer-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateProfileDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateProfileDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateProfileGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileModelsProfileModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateProfileGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateProfileGetPendingDealerRequestsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfileModelsDealerRequestCustomer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateProfileGetPendingDealerRequestsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProfileModelsProfileModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateProfilePost(request?: ProfileModelsProfileModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileModelsProfileModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateProfilePost(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProfileModelsCustomerResponseToDealer} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateProfileRespondToDealerRequestPost(request?: ProfileModelsCustomerResponseToDealer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateProfileRespondToDealerRequestPost(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateProfileDelete(options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateProfileDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateProfileGet(options?: any): AxiosPromise<ProfileModelsProfileModel> {
            return localVarFp.apiPrivateProfileGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateProfileGetPendingDealerRequestsGet(options?: any): AxiosPromise<Array<ProfileModelsDealerRequestCustomer>> {
            return localVarFp.apiPrivateProfileGetPendingDealerRequestsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfileModelsProfileModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateProfilePost(request?: ProfileModelsProfileModel, options?: any): AxiosPromise<ProfileModelsProfileModel> {
            return localVarFp.apiPrivateProfilePost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfileModelsCustomerResponseToDealer} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateProfileRespondToDealerRequestPost(request?: ProfileModelsCustomerResponseToDealer, options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateProfileRespondToDealerRequestPost(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public apiPrivateProfileDelete(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).apiPrivateProfileDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public apiPrivateProfileGet(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).apiPrivateProfileGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public apiPrivateProfileGetPendingDealerRequestsGet(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).apiPrivateProfileGetPendingDealerRequestsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileModelsProfileModel} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public apiPrivateProfilePost(request?: ProfileModelsProfileModel, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).apiPrivateProfilePost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileModelsCustomerResponseToDealer} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public apiPrivateProfileRespondToDealerRequestPost(request?: ProfileModelsCustomerResponseToDealer, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).apiPrivateProfileRespondToDealerRequestPost(request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SecurityApi - axios parameter creator
 * @export
 */
export const SecurityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSecurityEmailConfirmCompletePost: async (token?: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/security/email/confirm/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSecurityEmailConfirmRequestPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/security/email/confirm/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSecurityEmailConfirmedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/security/email/confirmed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SecurityModelsEncryptRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSecurityEncryptTestingPurposesOnlyPost: async (request?: SecurityModelsEncryptRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/security/encrypt-testing-purposes-only`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SecurityModelsForgotPasswordCompleteRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSecurityForgotPasswordCompletePost: async (request?: SecurityModelsForgotPasswordCompleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/security/forgot-password/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SecurityModelsForgotPasswordRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSecurityForgotPasswordRequestPost: async (request?: SecurityModelsForgotPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/security/forgot-password/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SecurityModelsRefreshTokenModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSecurityRefreshPost: async (request?: SecurityModelsRefreshTokenModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/security/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SecurityModelsLoginModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSecurityTokenPost: async (request?: SecurityModelsLoginModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/security/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecurityApi - functional programming interface
 * @export
 */
export const SecurityApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = SecurityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSecurityEmailConfirmCompletePost(token?: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityModelsConfirmEmailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSecurityEmailConfirmCompletePost(token, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSecurityEmailConfirmRequestPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityModelsConfirmEmailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSecurityEmailConfirmRequestPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSecurityEmailConfirmedGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityModelsEmailConfirmedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSecurityEmailConfirmedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SecurityModelsEncryptRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSecurityEncryptTestingPurposesOnlyPost(request?: SecurityModelsEncryptRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecurityModelsEncryptRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSecurityEncryptTestingPurposesOnlyPost(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SecurityModelsForgotPasswordCompleteRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSecurityForgotPasswordCompletePost(request?: SecurityModelsForgotPasswordCompleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSecurityForgotPasswordCompletePost(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SecurityModelsForgotPasswordRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSecurityForgotPasswordRequestPost(request?: SecurityModelsForgotPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSecurityForgotPasswordRequestPost(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SecurityModelsRefreshTokenModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSecurityRefreshPost(request?: SecurityModelsRefreshTokenModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSecurityRefreshPost(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SecurityModelsLoginModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSecurityTokenPost(request?: SecurityModelsLoginModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSecurityTokenPost(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SecurityApi - factory interface
 * @export
 */
export const SecurityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecurityApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSecurityEmailConfirmCompletePost(token?: string, id?: string, options?: any): AxiosPromise<SecurityModelsConfirmEmailResponse> {
            return localVarFp.apiSecurityEmailConfirmCompletePost(token, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSecurityEmailConfirmRequestPost(options?: any): AxiosPromise<SecurityModelsConfirmEmailResponse> {
            return localVarFp.apiSecurityEmailConfirmRequestPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSecurityEmailConfirmedGet(options?: any): AxiosPromise<SecurityModelsEmailConfirmedResponse> {
            return localVarFp.apiSecurityEmailConfirmedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SecurityModelsEncryptRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSecurityEncryptTestingPurposesOnlyPost(request?: SecurityModelsEncryptRequest, options?: any): AxiosPromise<SecurityModelsEncryptRequest> {
            return localVarFp.apiSecurityEncryptTestingPurposesOnlyPost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SecurityModelsForgotPasswordCompleteRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSecurityForgotPasswordCompletePost(request?: SecurityModelsForgotPasswordCompleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiSecurityForgotPasswordCompletePost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SecurityModelsForgotPasswordRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSecurityForgotPasswordRequestPost(request?: SecurityModelsForgotPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiSecurityForgotPasswordRequestPost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SecurityModelsRefreshTokenModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSecurityRefreshPost(request?: SecurityModelsRefreshTokenModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiSecurityRefreshPost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SecurityModelsLoginModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSecurityTokenPost(request?: SecurityModelsLoginModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiSecurityTokenPost(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SecurityApi - object-oriented interface
 * @export
 * @class SecurityApi
 * @extends {BaseAPI}
 */
export class SecurityApi extends BaseAPI {
    /**
     * 
     * @param {string} [token] 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public apiSecurityEmailConfirmCompletePost(token?: string, id?: string, options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).apiSecurityEmailConfirmCompletePost(token, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public apiSecurityEmailConfirmRequestPost(options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).apiSecurityEmailConfirmRequestPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public apiSecurityEmailConfirmedGet(options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).apiSecurityEmailConfirmedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SecurityModelsEncryptRequest} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public apiSecurityEncryptTestingPurposesOnlyPost(request?: SecurityModelsEncryptRequest, options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).apiSecurityEncryptTestingPurposesOnlyPost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SecurityModelsForgotPasswordCompleteRequest} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public apiSecurityForgotPasswordCompletePost(request?: SecurityModelsForgotPasswordCompleteRequest, options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).apiSecurityForgotPasswordCompletePost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SecurityModelsForgotPasswordRequest} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public apiSecurityForgotPasswordRequestPost(request?: SecurityModelsForgotPasswordRequest, options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).apiSecurityForgotPasswordRequestPost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SecurityModelsRefreshTokenModel} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public apiSecurityRefreshPost(request?: SecurityModelsRefreshTokenModel, options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).apiSecurityRefreshPost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SecurityModelsLoginModel} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public apiSecurityTokenPost(request?: SecurityModelsLoginModel, options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).apiSecurityTokenPost(request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SocialAuthApi - axios parameter creator
 * @export
 */
export const SocialAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SocialAuthModelsSocialAuthTokenRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicSocialAuthApplePost: async (request?: SocialAuthModelsSocialAuthTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/public/social-auth/apple-direct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SocialAuthModelsSocialAuthTokenRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicSocialAuthFacebookPost: async (request?: SocialAuthModelsSocialAuthTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/public/social-auth/facebook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SocialAuthModelsSocialAuthTokenRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicSocialAuthGooglePost: async (request?: SocialAuthModelsSocialAuthTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/public/social-auth/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SocialAuthApi - functional programming interface
 * @export
 */
export const SocialAuthApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = SocialAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SocialAuthModelsSocialAuthTokenRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicSocialAuthApplePost(request?: SocialAuthModelsSocialAuthTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicSocialAuthApplePost(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SocialAuthModelsSocialAuthTokenRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicSocialAuthFacebookPost(request?: SocialAuthModelsSocialAuthTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicSocialAuthFacebookPost(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SocialAuthModelsSocialAuthTokenRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicSocialAuthGooglePost(request?: SocialAuthModelsSocialAuthTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicSocialAuthGooglePost(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SocialAuthApi - factory interface
 * @export
 */
export const SocialAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SocialAuthApiFp(configuration)
    return {
        /**
         * 
         * @param {SocialAuthModelsSocialAuthTokenRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicSocialAuthApplePost(request?: SocialAuthModelsSocialAuthTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiPublicSocialAuthApplePost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SocialAuthModelsSocialAuthTokenRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicSocialAuthFacebookPost(request?: SocialAuthModelsSocialAuthTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiPublicSocialAuthFacebookPost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SocialAuthModelsSocialAuthTokenRequest} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicSocialAuthGooglePost(request?: SocialAuthModelsSocialAuthTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiPublicSocialAuthGooglePost(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SocialAuthApi - object-oriented interface
 * @export
 * @class SocialAuthApi
 * @extends {BaseAPI}
 */
export class SocialAuthApi extends BaseAPI {
    /**
     * 
     * @param {SocialAuthModelsSocialAuthTokenRequest} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialAuthApi
     */
    public apiPublicSocialAuthApplePost(request?: SocialAuthModelsSocialAuthTokenRequest, options?: AxiosRequestConfig) {
        return SocialAuthApiFp(this.configuration).apiPublicSocialAuthApplePost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SocialAuthModelsSocialAuthTokenRequest} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialAuthApi
     */
    public apiPublicSocialAuthFacebookPost(request?: SocialAuthModelsSocialAuthTokenRequest, options?: AxiosRequestConfig) {
        return SocialAuthApiFp(this.configuration).apiPublicSocialAuthFacebookPost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SocialAuthModelsSocialAuthTokenRequest} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialAuthApi
     */
    public apiPublicSocialAuthGooglePost(request?: SocialAuthModelsSocialAuthTokenRequest, options?: AxiosRequestConfig) {
        return SocialAuthApiFp(this.configuration).apiPublicSocialAuthGooglePost(request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TwelveMonthTyreInsuranceApi - axios parameter creator
 * @export
 */
export const TwelveMonthTyreInsuranceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateTwelvemonthtyreinsuranceGetCountriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/twelvemonthtyreinsurance/GetCountries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateTwelvemonthtyreinsuranceGetDealersGet: async (countryId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/twelvemonthtyreinsurance/GetDealers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (countryId !== undefined) {
                localVarQueryParameter['countryId'] = countryId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateTwelvemonthtyreinsuranceGetPoliciesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/twelvemonthtyreinsurance/GetPolicies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [policyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateTwelvemonthtyreinsuranceGetPolicyGet: async (policyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/twelvemonthtyreinsurance/GetPolicy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (policyId !== undefined) {
                localVarQueryParameter['policyId'] = policyId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateTwelvemonthtyreinsuranceGetTyrePatternsGet: async (range?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/twelvemonthtyreinsurance/GetTyrePatterns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateTwelvemonthtyreinsuranceGetTyreRangesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/twelvemonthtyreinsurance/GetTyreRanges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [range] 
         * @param {string} [pattern] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateTwelvemonthtyreinsuranceGetTyreSizesGet: async (range?: string, pattern?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/twelvemonthtyreinsurance/GetTyreSizes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (pattern !== undefined) {
                localVarQueryParameter['pattern'] = pattern;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TwelveMonthTyreInsuranceSubmitPolicyViewModel} [policy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateTwelvemonthtyreinsuranceSubmitPolicyPost: async (policy?: TwelveMonthTyreInsuranceSubmitPolicyViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/twelvemonthtyreinsurance/SubmitPolicy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(policy, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TwelveMonthTyreInsuranceApi - functional programming interface
 * @export
 */
export const TwelveMonthTyreInsuranceApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = TwelveMonthTyreInsuranceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateTwelvemonthtyreinsuranceGetCountriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TwelveMonthTyreInsuranceModelsCountryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateTwelvemonthtyreinsuranceGetCountriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateTwelvemonthtyreinsuranceGetDealersGet(countryId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TwelveMonthTyreInsuranceModelsDealerListModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateTwelvemonthtyreinsuranceGetDealersGet(countryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateTwelvemonthtyreinsuranceGetPoliciesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TwelveMonthTyreInsuranceModelsPolicyListModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateTwelvemonthtyreinsuranceGetPoliciesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [policyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateTwelvemonthtyreinsuranceGetPolicyGet(policyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwelveMonthTyreInsuranceModelsPolicyModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateTwelvemonthtyreinsuranceGetPolicyGet(policyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateTwelvemonthtyreinsuranceGetTyrePatternsGet(range?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TwelveMonthTyreInsuranceModelsTyrePatternListModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateTwelvemonthtyreinsuranceGetTyrePatternsGet(range, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateTwelvemonthtyreinsuranceGetTyreRangesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TwelveMonthTyreInsuranceModelsTyreRangeListModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateTwelvemonthtyreinsuranceGetTyreRangesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [range] 
         * @param {string} [pattern] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateTwelvemonthtyreinsuranceGetTyreSizesGet(range?: string, pattern?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TwelveMonthTyreInsuranceModelsTyreSizeModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateTwelvemonthtyreinsuranceGetTyreSizesGet(range, pattern, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TwelveMonthTyreInsuranceSubmitPolicyViewModel} [policy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateTwelvemonthtyreinsuranceSubmitPolicyPost(policy?: TwelveMonthTyreInsuranceSubmitPolicyViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwelveMonthTyreInsuranceModelsPolicyModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateTwelvemonthtyreinsuranceSubmitPolicyPost(policy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TwelveMonthTyreInsuranceApi - factory interface
 * @export
 */
export const TwelveMonthTyreInsuranceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TwelveMonthTyreInsuranceApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateTwelvemonthtyreinsuranceGetCountriesGet(options?: any): AxiosPromise<Array<TwelveMonthTyreInsuranceModelsCountryModel>> {
            return localVarFp.apiPrivateTwelvemonthtyreinsuranceGetCountriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [countryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateTwelvemonthtyreinsuranceGetDealersGet(countryId?: number, options?: any): AxiosPromise<Array<TwelveMonthTyreInsuranceModelsDealerListModel>> {
            return localVarFp.apiPrivateTwelvemonthtyreinsuranceGetDealersGet(countryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateTwelvemonthtyreinsuranceGetPoliciesGet(options?: any): AxiosPromise<Array<TwelveMonthTyreInsuranceModelsPolicyListModel>> {
            return localVarFp.apiPrivateTwelvemonthtyreinsuranceGetPoliciesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [policyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateTwelvemonthtyreinsuranceGetPolicyGet(policyId?: string, options?: any): AxiosPromise<TwelveMonthTyreInsuranceModelsPolicyModel> {
            return localVarFp.apiPrivateTwelvemonthtyreinsuranceGetPolicyGet(policyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [range] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateTwelvemonthtyreinsuranceGetTyrePatternsGet(range?: string, options?: any): AxiosPromise<Array<TwelveMonthTyreInsuranceModelsTyrePatternListModel>> {
            return localVarFp.apiPrivateTwelvemonthtyreinsuranceGetTyrePatternsGet(range, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateTwelvemonthtyreinsuranceGetTyreRangesGet(options?: any): AxiosPromise<Array<TwelveMonthTyreInsuranceModelsTyreRangeListModel>> {
            return localVarFp.apiPrivateTwelvemonthtyreinsuranceGetTyreRangesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [range] 
         * @param {string} [pattern] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateTwelvemonthtyreinsuranceGetTyreSizesGet(range?: string, pattern?: string, options?: any): AxiosPromise<Array<TwelveMonthTyreInsuranceModelsTyreSizeModel>> {
            return localVarFp.apiPrivateTwelvemonthtyreinsuranceGetTyreSizesGet(range, pattern, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TwelveMonthTyreInsuranceSubmitPolicyViewModel} [policy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateTwelvemonthtyreinsuranceSubmitPolicyPost(policy?: TwelveMonthTyreInsuranceSubmitPolicyViewModel, options?: any): AxiosPromise<TwelveMonthTyreInsuranceModelsPolicyModel> {
            return localVarFp.apiPrivateTwelvemonthtyreinsuranceSubmitPolicyPost(policy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TwelveMonthTyreInsuranceApi - object-oriented interface
 * @export
 * @class TwelveMonthTyreInsuranceApi
 * @extends {BaseAPI}
 */
export class TwelveMonthTyreInsuranceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwelveMonthTyreInsuranceApi
     */
    public apiPrivateTwelvemonthtyreinsuranceGetCountriesGet(options?: AxiosRequestConfig) {
        return TwelveMonthTyreInsuranceApiFp(this.configuration).apiPrivateTwelvemonthtyreinsuranceGetCountriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [countryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwelveMonthTyreInsuranceApi
     */
    public apiPrivateTwelvemonthtyreinsuranceGetDealersGet(countryId?: number, options?: AxiosRequestConfig) {
        return TwelveMonthTyreInsuranceApiFp(this.configuration).apiPrivateTwelvemonthtyreinsuranceGetDealersGet(countryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwelveMonthTyreInsuranceApi
     */
    public apiPrivateTwelvemonthtyreinsuranceGetPoliciesGet(options?: AxiosRequestConfig) {
        return TwelveMonthTyreInsuranceApiFp(this.configuration).apiPrivateTwelvemonthtyreinsuranceGetPoliciesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [policyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwelveMonthTyreInsuranceApi
     */
    public apiPrivateTwelvemonthtyreinsuranceGetPolicyGet(policyId?: string, options?: AxiosRequestConfig) {
        return TwelveMonthTyreInsuranceApiFp(this.configuration).apiPrivateTwelvemonthtyreinsuranceGetPolicyGet(policyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [range] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwelveMonthTyreInsuranceApi
     */
    public apiPrivateTwelvemonthtyreinsuranceGetTyrePatternsGet(range?: string, options?: AxiosRequestConfig) {
        return TwelveMonthTyreInsuranceApiFp(this.configuration).apiPrivateTwelvemonthtyreinsuranceGetTyrePatternsGet(range, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwelveMonthTyreInsuranceApi
     */
    public apiPrivateTwelvemonthtyreinsuranceGetTyreRangesGet(options?: AxiosRequestConfig) {
        return TwelveMonthTyreInsuranceApiFp(this.configuration).apiPrivateTwelvemonthtyreinsuranceGetTyreRangesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [range] 
     * @param {string} [pattern] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwelveMonthTyreInsuranceApi
     */
    public apiPrivateTwelvemonthtyreinsuranceGetTyreSizesGet(range?: string, pattern?: string, options?: AxiosRequestConfig) {
        return TwelveMonthTyreInsuranceApiFp(this.configuration).apiPrivateTwelvemonthtyreinsuranceGetTyreSizesGet(range, pattern, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TwelveMonthTyreInsuranceSubmitPolicyViewModel} [policy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwelveMonthTyreInsuranceApi
     */
    public apiPrivateTwelvemonthtyreinsuranceSubmitPolicyPost(policy?: TwelveMonthTyreInsuranceSubmitPolicyViewModel, options?: AxiosRequestConfig) {
        return TwelveMonthTyreInsuranceApiFp(this.configuration).apiPrivateTwelvemonthtyreinsuranceSubmitPolicyPost(policy, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TyreTreadPatternsApi - axios parameter creator
 * @export
 */
export const TyreTreadPatternsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicTyreRangesGet: async (skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/public/tyre-ranges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tyreTreadPatternId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicTyreTreadPatternsByTyreTreadPatternIdGet: async (tyreTreadPatternId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tyreTreadPatternId' is not null or undefined
            assertParamExists('apiPublicTyreTreadPatternsByTyreTreadPatternIdGet', 'tyreTreadPatternId', tyreTreadPatternId)
            const localVarPath = `/api/public/tyre-tread-patterns/{tyreTreadPatternId}`
                .replace(`{${"tyreTreadPatternId"}}`, encodeURIComponent(String(tyreTreadPatternId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [range] 
         * @param {number} [aspectRatio] 
         * @param {number} [sectionWidth] 
         * @param {number} [rimSize] 
         * @param {string} [brandDesc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicTyreTreadPatternsGet: async (skip?: number, take?: number, range?: string, aspectRatio?: number, sectionWidth?: number, rimSize?: number, brandDesc?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/public/tyre-tread-patterns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (aspectRatio !== undefined) {
                localVarQueryParameter['aspectRatio'] = aspectRatio;
            }

            if (sectionWidth !== undefined) {
                localVarQueryParameter['sectionWidth'] = sectionWidth;
            }

            if (rimSize !== undefined) {
                localVarQueryParameter['rimSize'] = rimSize;
            }

            if (brandDesc !== undefined) {
                localVarQueryParameter['brandDesc'] = brandDesc;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tyreskuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicTyreTreadPatternsTyreSkuByTyreskuIdGet: async (tyreskuId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tyreskuId' is not null or undefined
            assertParamExists('apiPublicTyreTreadPatternsTyreSkuByTyreskuIdGet', 'tyreskuId', tyreskuId)
            const localVarPath = `/api/public/tyre-tread-patterns/tyre-sku/{tyreskuId}`
                .replace(`{${"tyreskuId"}}`, encodeURIComponent(String(tyreskuId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TyreTreadPatternsApi - functional programming interface
 * @export
 */
export const TyreTreadPatternsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = TyreTreadPatternsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicTyreRangesGet(skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TyreTreadPatternsTyresRangesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicTyreRangesGet(skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tyreTreadPatternId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicTyreTreadPatternsByTyreTreadPatternIdGet(tyreTreadPatternId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TyreTreadPatternsSingleTyreTreadPattern>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicTyreTreadPatternsByTyreTreadPatternIdGet(tyreTreadPatternId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [range] 
         * @param {number} [aspectRatio] 
         * @param {number} [sectionWidth] 
         * @param {number} [rimSize] 
         * @param {string} [brandDesc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicTyreTreadPatternsGet(skip?: number, take?: number, range?: string, aspectRatio?: number, sectionWidth?: number, rimSize?: number, brandDesc?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TyreTreadPatternsTyreTreadPatternResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicTyreTreadPatternsGet(skip, take, range, aspectRatio, sectionWidth, rimSize, brandDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tyreskuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicTyreTreadPatternsTyreSkuByTyreskuIdGet(tyreskuId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TyreTreadPatternsSingleTyreTreadPattern>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicTyreTreadPatternsTyreSkuByTyreskuIdGet(tyreskuId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TyreTreadPatternsApi - factory interface
 * @export
 */
export const TyreTreadPatternsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TyreTreadPatternsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicTyreRangesGet(skip?: number, take?: number, options?: any): AxiosPromise<TyreTreadPatternsTyresRangesResponse> {
            return localVarFp.apiPublicTyreRangesGet(skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tyreTreadPatternId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicTyreTreadPatternsByTyreTreadPatternIdGet(tyreTreadPatternId: string, options?: any): AxiosPromise<TyreTreadPatternsSingleTyreTreadPattern> {
            return localVarFp.apiPublicTyreTreadPatternsByTyreTreadPatternIdGet(tyreTreadPatternId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [range] 
         * @param {number} [aspectRatio] 
         * @param {number} [sectionWidth] 
         * @param {number} [rimSize] 
         * @param {string} [brandDesc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicTyreTreadPatternsGet(skip?: number, take?: number, range?: string, aspectRatio?: number, sectionWidth?: number, rimSize?: number, brandDesc?: string, options?: any): AxiosPromise<TyreTreadPatternsTyreTreadPatternResponse> {
            return localVarFp.apiPublicTyreTreadPatternsGet(skip, take, range, aspectRatio, sectionWidth, rimSize, brandDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tyreskuId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicTyreTreadPatternsTyreSkuByTyreskuIdGet(tyreskuId: string, options?: any): AxiosPromise<TyreTreadPatternsSingleTyreTreadPattern> {
            return localVarFp.apiPublicTyreTreadPatternsTyreSkuByTyreskuIdGet(tyreskuId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TyreTreadPatternsApi - object-oriented interface
 * @export
 * @class TyreTreadPatternsApi
 * @extends {BaseAPI}
 */
export class TyreTreadPatternsApi extends BaseAPI {
    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TyreTreadPatternsApi
     */
    public apiPublicTyreRangesGet(skip?: number, take?: number, options?: AxiosRequestConfig) {
        return TyreTreadPatternsApiFp(this.configuration).apiPublicTyreRangesGet(skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tyreTreadPatternId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TyreTreadPatternsApi
     */
    public apiPublicTyreTreadPatternsByTyreTreadPatternIdGet(tyreTreadPatternId: string, options?: AxiosRequestConfig) {
        return TyreTreadPatternsApiFp(this.configuration).apiPublicTyreTreadPatternsByTyreTreadPatternIdGet(tyreTreadPatternId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [range] 
     * @param {number} [aspectRatio] 
     * @param {number} [sectionWidth] 
     * @param {number} [rimSize] 
     * @param {string} [brandDesc] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TyreTreadPatternsApi
     */
    public apiPublicTyreTreadPatternsGet(skip?: number, take?: number, range?: string, aspectRatio?: number, sectionWidth?: number, rimSize?: number, brandDesc?: string, options?: AxiosRequestConfig) {
        return TyreTreadPatternsApiFp(this.configuration).apiPublicTyreTreadPatternsGet(skip, take, range, aspectRatio, sectionWidth, rimSize, brandDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tyreskuId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TyreTreadPatternsApi
     */
    public apiPublicTyreTreadPatternsTyreSkuByTyreskuIdGet(tyreskuId: string, options?: AxiosRequestConfig) {
        return TyreTreadPatternsApiFp(this.configuration).apiPublicTyreTreadPatternsTyreSkuByTyreskuIdGet(tyreskuId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VehicleTyresApi - axios parameter creator
 * @export
 */
export const VehicleTyresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} vehicleId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdTyresByIdDelete: async (vehicleId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiPrivateVehiclesByVehicleIdTyresByIdDelete', 'vehicleId', vehicleId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPrivateVehiclesByVehicleIdTyresByIdDelete', 'id', id)
            const localVarPath = `/api/private/vehicles/{vehicleId}/tyres/{id}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {string} id 
         * @param {VehicleTyresModelsVehicleTyreModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdTyresByIdPost: async (vehicleId: string, id: string, request?: VehicleTyresModelsVehicleTyreModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiPrivateVehiclesByVehicleIdTyresByIdPost', 'vehicleId', vehicleId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPrivateVehiclesByVehicleIdTyresByIdPost', 'id', id)
            const localVarPath = `/api/private/vehicles/{vehicleId}/tyres/{id}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdTyresGet: async (vehicleId: string, skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiPrivateVehiclesByVehicleIdTyresGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/private/vehicles/{vehicleId}/tyres`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {VehicleTyresModelsVehicleTyreModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdTyresPut: async (vehicleId: string, request?: VehicleTyresModelsVehicleTyreModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiPrivateVehiclesByVehicleIdTyresPut', 'vehicleId', vehicleId)
            const localVarPath = `/api/private/vehicles/{vehicleId}/tyres`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehicleTyresApi - functional programming interface
 * @export
 */
export const VehicleTyresApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = VehicleTyresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} vehicleId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateVehiclesByVehicleIdTyresByIdDelete(vehicleId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateVehiclesByVehicleIdTyresByIdDelete(vehicleId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {string} id 
         * @param {VehicleTyresModelsVehicleTyreModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateVehiclesByVehicleIdTyresByIdPost(vehicleId: string, id: string, request?: VehicleTyresModelsVehicleTyreModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateVehiclesByVehicleIdTyresByIdPost(vehicleId, id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateVehiclesByVehicleIdTyresGet(vehicleId: string, skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleTyresModelsVehicleTyresResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateVehiclesByVehicleIdTyresGet(vehicleId, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {VehicleTyresModelsVehicleTyreModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateVehiclesByVehicleIdTyresPut(vehicleId: string, request?: VehicleTyresModelsVehicleTyreModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleTyresModelsVehicleTyreModelWithId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateVehiclesByVehicleIdTyresPut(vehicleId, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VehicleTyresApi - factory interface
 * @export
 */
export const VehicleTyresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehicleTyresApiFp(configuration)
    return {
        /**
         * 
         * @param {string} vehicleId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdTyresByIdDelete(vehicleId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateVehiclesByVehicleIdTyresByIdDelete(vehicleId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {string} id 
         * @param {VehicleTyresModelsVehicleTyreModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdTyresByIdPost(vehicleId: string, id: string, request?: VehicleTyresModelsVehicleTyreModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateVehiclesByVehicleIdTyresByIdPost(vehicleId, id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdTyresGet(vehicleId: string, skip?: number, take?: number, options?: any): AxiosPromise<VehicleTyresModelsVehicleTyresResponse> {
            return localVarFp.apiPrivateVehiclesByVehicleIdTyresGet(vehicleId, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} vehicleId 
         * @param {VehicleTyresModelsVehicleTyreModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByVehicleIdTyresPut(vehicleId: string, request?: VehicleTyresModelsVehicleTyreModel, options?: any): AxiosPromise<VehicleTyresModelsVehicleTyreModelWithId> {
            return localVarFp.apiPrivateVehiclesByVehicleIdTyresPut(vehicleId, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehicleTyresApi - object-oriented interface
 * @export
 * @class VehicleTyresApi
 * @extends {BaseAPI}
 */
export class VehicleTyresApi extends BaseAPI {
    /**
     * 
     * @param {string} vehicleId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTyresApi
     */
    public apiPrivateVehiclesByVehicleIdTyresByIdDelete(vehicleId: string, id: string, options?: AxiosRequestConfig) {
        return VehicleTyresApiFp(this.configuration).apiPrivateVehiclesByVehicleIdTyresByIdDelete(vehicleId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vehicleId 
     * @param {string} id 
     * @param {VehicleTyresModelsVehicleTyreModel} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTyresApi
     */
    public apiPrivateVehiclesByVehicleIdTyresByIdPost(vehicleId: string, id: string, request?: VehicleTyresModelsVehicleTyreModel, options?: AxiosRequestConfig) {
        return VehicleTyresApiFp(this.configuration).apiPrivateVehiclesByVehicleIdTyresByIdPost(vehicleId, id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vehicleId 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTyresApi
     */
    public apiPrivateVehiclesByVehicleIdTyresGet(vehicleId: string, skip?: number, take?: number, options?: AxiosRequestConfig) {
        return VehicleTyresApiFp(this.configuration).apiPrivateVehiclesByVehicleIdTyresGet(vehicleId, skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} vehicleId 
     * @param {VehicleTyresModelsVehicleTyreModel} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehicleTyresApi
     */
    public apiPrivateVehiclesByVehicleIdTyresPut(vehicleId: string, request?: VehicleTyresModelsVehicleTyreModel, options?: AxiosRequestConfig) {
        return VehicleTyresApiFp(this.configuration).apiPrivateVehiclesByVehicleIdTyresPut(vehicleId, request, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VehiclesApi - axios parameter creator
 * @export
 */
export const VehiclesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPrivateVehiclesByIdDelete', 'id', id)
            const localVarPath = `/api/private/vehicles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {VehiclesModelsVehicleModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByIdPost: async (id: string, request?: VehiclesModelsVehicleModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPrivateVehiclesByIdPost', 'id', id)
            const localVarPath = `/api/private/vehicles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [customerProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesGet: async (skip?: number, take?: number, customerProfileId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/vehicles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (customerProfileId !== undefined) {
                localVarQueryParameter['customerProfileId'] = customerProfileId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesManufacturersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/vehicles/manufacturers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesModelsGet: async (manufacturerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/vehicles/models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (manufacturerId !== undefined) {
                localVarQueryParameter['manufacturerId'] = manufacturerId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [profileId] 
         * @param {VehiclesModelsVehicleModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesPut: async (profileId?: string, request?: VehiclesModelsVehicleModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/vehicles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (profileId !== undefined) {
                localVarQueryParameter['profileId'] = profileId;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [modelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesSpecificationsGet: async (modelId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/vehicles/specifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (modelId !== undefined) {
                localVarQueryParameter['modelId'] = modelId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VehiclesApi - functional programming interface
 * @export
 */
export const VehiclesApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = VehiclesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateVehiclesByIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateVehiclesByIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {VehiclesModelsVehicleModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateVehiclesByIdPost(id: string, request?: VehiclesModelsVehicleModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateVehiclesByIdPost(id, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [customerProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateVehiclesGet(skip?: number, take?: number, customerProfileId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehiclesModelsVehiclesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateVehiclesGet(skip, take, customerProfileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateVehiclesManufacturersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehiclesModelsVehiclesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateVehiclesManufacturersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateVehiclesModelsGet(manufacturerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehiclesModelsVehiclesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateVehiclesModelsGet(manufacturerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [profileId] 
         * @param {VehiclesModelsVehicleModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateVehiclesPut(profileId?: string, request?: VehiclesModelsVehicleModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehiclesModelsVehicleModelWithId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateVehiclesPut(profileId, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [modelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateVehiclesSpecificationsGet(modelId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehiclesModelsVehiclesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateVehiclesSpecificationsGet(modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VehiclesApi - factory interface
 * @export
 */
export const VehiclesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VehiclesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateVehiclesByIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {VehiclesModelsVehicleModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesByIdPost(id: string, request?: VehiclesModelsVehicleModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateVehiclesByIdPost(id, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {string} [customerProfileId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesGet(skip?: number, take?: number, customerProfileId?: string, options?: any): AxiosPromise<VehiclesModelsVehiclesResponse> {
            return localVarFp.apiPrivateVehiclesGet(skip, take, customerProfileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesManufacturersGet(options?: any): AxiosPromise<VehiclesModelsVehiclesResponse> {
            return localVarFp.apiPrivateVehiclesManufacturersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [manufacturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesModelsGet(manufacturerId?: number, options?: any): AxiosPromise<VehiclesModelsVehiclesResponse> {
            return localVarFp.apiPrivateVehiclesModelsGet(manufacturerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [profileId] 
         * @param {VehiclesModelsVehicleModel} [request] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesPut(profileId?: string, request?: VehiclesModelsVehicleModel, options?: any): AxiosPromise<VehiclesModelsVehicleModelWithId> {
            return localVarFp.apiPrivateVehiclesPut(profileId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [modelId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateVehiclesSpecificationsGet(modelId?: number, options?: any): AxiosPromise<VehiclesModelsVehiclesResponse> {
            return localVarFp.apiPrivateVehiclesSpecificationsGet(modelId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VehiclesApi - object-oriented interface
 * @export
 * @class VehiclesApi
 * @extends {BaseAPI}
 */
export class VehiclesApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiPrivateVehiclesByIdDelete(id: string, options?: AxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiPrivateVehiclesByIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {VehiclesModelsVehicleModel} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiPrivateVehiclesByIdPost(id: string, request?: VehiclesModelsVehicleModel, options?: AxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiPrivateVehiclesByIdPost(id, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {string} [customerProfileId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiPrivateVehiclesGet(skip?: number, take?: number, customerProfileId?: string, options?: AxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiPrivateVehiclesGet(skip, take, customerProfileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiPrivateVehiclesManufacturersGet(options?: AxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiPrivateVehiclesManufacturersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [manufacturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiPrivateVehiclesModelsGet(manufacturerId?: number, options?: AxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiPrivateVehiclesModelsGet(manufacturerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [profileId] 
     * @param {VehiclesModelsVehicleModel} [request] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiPrivateVehiclesPut(profileId?: string, request?: VehiclesModelsVehicleModel, options?: AxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiPrivateVehiclesPut(profileId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [modelId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VehiclesApi
     */
    public apiPrivateVehiclesSpecificationsGet(modelId?: number, options?: AxiosRequestConfig) {
        return VehiclesApiFp(this.configuration).apiPrivateVehiclesSpecificationsGet(modelId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WarrantyApi - axios parameter creator
 * @export
 */
export const WarrantyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} warrantyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesByWarrantyIdGet: async (warrantyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warrantyId' is not null or undefined
            assertParamExists('apiPrivateWarrantiesByWarrantyIdGet', 'warrantyId', warrantyId)
            const localVarPath = `/api/private/warranties/{warrantyId}`
                .replace(`{${"warrantyId"}}`, encodeURIComponent(String(warrantyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} warrantyMaintenanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesByWarrantyMaintenanceIdGet: async (warrantyMaintenanceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warrantyMaintenanceId' is not null or undefined
            assertParamExists('apiPrivateWarrantiesByWarrantyMaintenanceIdGet', 'warrantyMaintenanceId', warrantyMaintenanceId)
            const localVarPath = `/api/private/warranties/{warrantyMaintenanceId}`
                .replace(`{${"warrantyMaintenanceId"}}`, encodeURIComponent(String(warrantyMaintenanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} warrantyId 
         * @param {number} mileageNotAcheived 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesClaimsCalculateByWarrantyIdByMileageNotAcheivedGet: async (warrantyId: string, mileageNotAcheived: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warrantyId' is not null or undefined
            assertParamExists('apiPrivateWarrantiesClaimsCalculateByWarrantyIdByMileageNotAcheivedGet', 'warrantyId', warrantyId)
            // verify required parameter 'mileageNotAcheived' is not null or undefined
            assertParamExists('apiPrivateWarrantiesClaimsCalculateByWarrantyIdByMileageNotAcheivedGet', 'mileageNotAcheived', mileageNotAcheived)
            const localVarPath = `/api/private/warranties/claims/calculate/{warrantyId}/{mileageNotAcheived}`
                .replace(`{${"warrantyId"}}`, encodeURIComponent(String(warrantyId)))
                .replace(`{${"mileageNotAcheived"}}`, encodeURIComponent(String(mileageNotAcheived)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WarrantyModelWarrantyClaimModel} [warrantyClaim] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesClaimsCreatePost: async (warrantyClaim?: WarrantyModelWarrantyClaimModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/warranties/claims/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(warrantyClaim, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WarrantyModelAddWarrantyModel} [warranty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesCreatePost: async (warranty?: WarrantyModelAddWarrantyModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/warranties/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(warranty, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesCurrenciesGetGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/warranties/currencies/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} warrantyId 
         * @param {WarrantyModelWarrantyModel} [warranty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesEditByWarrantyIdPost: async (warrantyId: string, warranty?: WarrantyModelWarrantyModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warrantyId' is not null or undefined
            assertParamExists('apiPrivateWarrantiesEditByWarrantyIdPost', 'warrantyId', warrantyId)
            const localVarPath = `/api/private/warranties/edit/{warrantyId}`
                .replace(`{${"warrantyId"}}`, encodeURIComponent(String(warrantyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(warranty, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} warrantyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesMaintenancesByWarrantyIdGet: async (warrantyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'warrantyId' is not null or undefined
            assertParamExists('apiPrivateWarrantiesMaintenancesByWarrantyIdGet', 'warrantyId', warrantyId)
            const localVarPath = `/api/private/warranties/maintenances/{warrantyId}`
                .replace(`{${"warrantyId"}}`, encodeURIComponent(String(warrantyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WarrantyModelWarrantyMaintenanceModel} [warrantyMaintenance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesMaintenancesCreatePost: async (warrantyMaintenance?: WarrantyModelWarrantyMaintenanceModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/warranties/maintenances/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(warrantyMaintenance, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesMineGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/warranties/mine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [utcDateOfWarranty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesTreadPatternsGetGet: async (utcDateOfWarranty?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/warranties/tread-patterns/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (utcDateOfWarranty !== undefined) {
                localVarQueryParameter['utcDateOfWarranty'] = (utcDateOfWarranty as any instanceof Date) ?
                    (utcDateOfWarranty as any).toISOString() :
                    utcDateOfWarranty;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [treadPattern] 
         * @param {string} [utcDateOfWarranty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesTyreSizesGetGet: async (treadPattern?: string, utcDateOfWarranty?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/warranties/tyre-sizes/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (treadPattern !== undefined) {
                localVarQueryParameter['treadPattern'] = treadPattern;
            }

            if (utcDateOfWarranty !== undefined) {
                localVarQueryParameter['utcDateOfWarranty'] = (utcDateOfWarranty as any instanceof Date) ?
                    (utcDateOfWarranty as any).toISOString() :
                    utcDateOfWarranty;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarrantyApi - functional programming interface
 * @export
 */
export const WarrantyApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = WarrantyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} warrantyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateWarrantiesByWarrantyIdGet(warrantyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarrantyModelWarrantyModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateWarrantiesByWarrantyIdGet(warrantyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} warrantyMaintenanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateWarrantiesByWarrantyMaintenanceIdGet(warrantyMaintenanceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarrantyModelWarrantyMaintenanceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateWarrantiesByWarrantyMaintenanceIdGet(warrantyMaintenanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} warrantyId 
         * @param {number} mileageNotAcheived 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateWarrantiesClaimsCalculateByWarrantyIdByMileageNotAcheivedGet(warrantyId: string, mileageNotAcheived: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateWarrantiesClaimsCalculateByWarrantyIdByMileageNotAcheivedGet(warrantyId, mileageNotAcheived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WarrantyModelWarrantyClaimModel} [warrantyClaim] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateWarrantiesClaimsCreatePost(warrantyClaim?: WarrantyModelWarrantyClaimModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarrantyModelWarrantyClaimModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateWarrantiesClaimsCreatePost(warrantyClaim, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WarrantyModelAddWarrantyModel} [warranty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateWarrantiesCreatePost(warranty?: WarrantyModelAddWarrantyModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarrantyModelWarrantyModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateWarrantiesCreatePost(warranty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateWarrantiesCurrenciesGetGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WarrantyModelCurrency>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateWarrantiesCurrenciesGetGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} warrantyId 
         * @param {WarrantyModelWarrantyModel} [warranty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateWarrantiesEditByWarrantyIdPost(warrantyId: string, warranty?: WarrantyModelWarrantyModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarrantyModelWarrantyModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateWarrantiesEditByWarrantyIdPost(warrantyId, warranty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} warrantyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateWarrantiesMaintenancesByWarrantyIdGet(warrantyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WarrantyModelWarrantyMaintenanceModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateWarrantiesMaintenancesByWarrantyIdGet(warrantyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WarrantyModelWarrantyMaintenanceModel} [warrantyMaintenance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateWarrantiesMaintenancesCreatePost(warrantyMaintenance?: WarrantyModelWarrantyMaintenanceModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarrantyModelWarrantyMaintenanceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateWarrantiesMaintenancesCreatePost(warrantyMaintenance, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateWarrantiesMineGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WarrantyModelWarrantyModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateWarrantiesMineGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [utcDateOfWarranty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateWarrantiesTreadPatternsGetGet(utcDateOfWarranty?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WarrantyModelTreadPatternLookUpModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateWarrantiesTreadPatternsGetGet(utcDateOfWarranty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [treadPattern] 
         * @param {string} [utcDateOfWarranty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateWarrantiesTyreSizesGetGet(treadPattern?: string, utcDateOfWarranty?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WarrantyModelTyreSizeLookupModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateWarrantiesTyreSizesGetGet(treadPattern, utcDateOfWarranty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WarrantyApi - factory interface
 * @export
 */
export const WarrantyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WarrantyApiFp(configuration)
    return {
        /**
         * 
         * @param {string} warrantyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesByWarrantyIdGet(warrantyId: string, options?: any): AxiosPromise<WarrantyModelWarrantyModel> {
            return localVarFp.apiPrivateWarrantiesByWarrantyIdGet(warrantyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} warrantyMaintenanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesByWarrantyMaintenanceIdGet(warrantyMaintenanceId: string, options?: any): AxiosPromise<WarrantyModelWarrantyMaintenanceModel> {
            return localVarFp.apiPrivateWarrantiesByWarrantyMaintenanceIdGet(warrantyMaintenanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} warrantyId 
         * @param {number} mileageNotAcheived 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesClaimsCalculateByWarrantyIdByMileageNotAcheivedGet(warrantyId: string, mileageNotAcheived: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiPrivateWarrantiesClaimsCalculateByWarrantyIdByMileageNotAcheivedGet(warrantyId, mileageNotAcheived, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WarrantyModelWarrantyClaimModel} [warrantyClaim] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesClaimsCreatePost(warrantyClaim?: WarrantyModelWarrantyClaimModel, options?: any): AxiosPromise<WarrantyModelWarrantyClaimModel> {
            return localVarFp.apiPrivateWarrantiesClaimsCreatePost(warrantyClaim, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WarrantyModelAddWarrantyModel} [warranty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesCreatePost(warranty?: WarrantyModelAddWarrantyModel, options?: any): AxiosPromise<WarrantyModelWarrantyModel> {
            return localVarFp.apiPrivateWarrantiesCreatePost(warranty, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesCurrenciesGetGet(options?: any): AxiosPromise<Array<WarrantyModelCurrency>> {
            return localVarFp.apiPrivateWarrantiesCurrenciesGetGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} warrantyId 
         * @param {WarrantyModelWarrantyModel} [warranty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesEditByWarrantyIdPost(warrantyId: string, warranty?: WarrantyModelWarrantyModel, options?: any): AxiosPromise<WarrantyModelWarrantyModel> {
            return localVarFp.apiPrivateWarrantiesEditByWarrantyIdPost(warrantyId, warranty, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} warrantyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesMaintenancesByWarrantyIdGet(warrantyId: string, options?: any): AxiosPromise<Array<WarrantyModelWarrantyMaintenanceModel>> {
            return localVarFp.apiPrivateWarrantiesMaintenancesByWarrantyIdGet(warrantyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WarrantyModelWarrantyMaintenanceModel} [warrantyMaintenance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesMaintenancesCreatePost(warrantyMaintenance?: WarrantyModelWarrantyMaintenanceModel, options?: any): AxiosPromise<WarrantyModelWarrantyMaintenanceModel> {
            return localVarFp.apiPrivateWarrantiesMaintenancesCreatePost(warrantyMaintenance, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesMineGet(options?: any): AxiosPromise<Array<WarrantyModelWarrantyModel>> {
            return localVarFp.apiPrivateWarrantiesMineGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [utcDateOfWarranty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesTreadPatternsGetGet(utcDateOfWarranty?: string, options?: any): AxiosPromise<Array<WarrantyModelTreadPatternLookUpModel>> {
            return localVarFp.apiPrivateWarrantiesTreadPatternsGetGet(utcDateOfWarranty, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [treadPattern] 
         * @param {string} [utcDateOfWarranty] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateWarrantiesTyreSizesGetGet(treadPattern?: string, utcDateOfWarranty?: string, options?: any): AxiosPromise<Array<WarrantyModelTyreSizeLookupModel>> {
            return localVarFp.apiPrivateWarrantiesTyreSizesGetGet(treadPattern, utcDateOfWarranty, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WarrantyApi - object-oriented interface
 * @export
 * @class WarrantyApi
 * @extends {BaseAPI}
 */
export class WarrantyApi extends BaseAPI {
    /**
     * 
     * @param {string} warrantyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarrantyApi
     */
    public apiPrivateWarrantiesByWarrantyIdGet(warrantyId: string, options?: AxiosRequestConfig) {
        return WarrantyApiFp(this.configuration).apiPrivateWarrantiesByWarrantyIdGet(warrantyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} warrantyMaintenanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarrantyApi
     */
    public apiPrivateWarrantiesByWarrantyMaintenanceIdGet(warrantyMaintenanceId: string, options?: AxiosRequestConfig) {
        return WarrantyApiFp(this.configuration).apiPrivateWarrantiesByWarrantyMaintenanceIdGet(warrantyMaintenanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} warrantyId 
     * @param {number} mileageNotAcheived 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarrantyApi
     */
    public apiPrivateWarrantiesClaimsCalculateByWarrantyIdByMileageNotAcheivedGet(warrantyId: string, mileageNotAcheived: number, options?: AxiosRequestConfig) {
        return WarrantyApiFp(this.configuration).apiPrivateWarrantiesClaimsCalculateByWarrantyIdByMileageNotAcheivedGet(warrantyId, mileageNotAcheived, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarrantyModelWarrantyClaimModel} [warrantyClaim] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarrantyApi
     */
    public apiPrivateWarrantiesClaimsCreatePost(warrantyClaim?: WarrantyModelWarrantyClaimModel, options?: AxiosRequestConfig) {
        return WarrantyApiFp(this.configuration).apiPrivateWarrantiesClaimsCreatePost(warrantyClaim, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarrantyModelAddWarrantyModel} [warranty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarrantyApi
     */
    public apiPrivateWarrantiesCreatePost(warranty?: WarrantyModelAddWarrantyModel, options?: AxiosRequestConfig) {
        return WarrantyApiFp(this.configuration).apiPrivateWarrantiesCreatePost(warranty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarrantyApi
     */
    public apiPrivateWarrantiesCurrenciesGetGet(options?: AxiosRequestConfig) {
        return WarrantyApiFp(this.configuration).apiPrivateWarrantiesCurrenciesGetGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} warrantyId 
     * @param {WarrantyModelWarrantyModel} [warranty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarrantyApi
     */
    public apiPrivateWarrantiesEditByWarrantyIdPost(warrantyId: string, warranty?: WarrantyModelWarrantyModel, options?: AxiosRequestConfig) {
        return WarrantyApiFp(this.configuration).apiPrivateWarrantiesEditByWarrantyIdPost(warrantyId, warranty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} warrantyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarrantyApi
     */
    public apiPrivateWarrantiesMaintenancesByWarrantyIdGet(warrantyId: string, options?: AxiosRequestConfig) {
        return WarrantyApiFp(this.configuration).apiPrivateWarrantiesMaintenancesByWarrantyIdGet(warrantyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WarrantyModelWarrantyMaintenanceModel} [warrantyMaintenance] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarrantyApi
     */
    public apiPrivateWarrantiesMaintenancesCreatePost(warrantyMaintenance?: WarrantyModelWarrantyMaintenanceModel, options?: AxiosRequestConfig) {
        return WarrantyApiFp(this.configuration).apiPrivateWarrantiesMaintenancesCreatePost(warrantyMaintenance, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarrantyApi
     */
    public apiPrivateWarrantiesMineGet(options?: AxiosRequestConfig) {
        return WarrantyApiFp(this.configuration).apiPrivateWarrantiesMineGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [utcDateOfWarranty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarrantyApi
     */
    public apiPrivateWarrantiesTreadPatternsGetGet(utcDateOfWarranty?: string, options?: AxiosRequestConfig) {
        return WarrantyApiFp(this.configuration).apiPrivateWarrantiesTreadPatternsGetGet(utcDateOfWarranty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [treadPattern] 
     * @param {string} [utcDateOfWarranty] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarrantyApi
     */
    public apiPrivateWarrantiesTyreSizesGetGet(treadPattern?: string, utcDateOfWarranty?: string, options?: AxiosRequestConfig) {
        return WarrantyApiFp(this.configuration).apiPrivateWarrantiesTyreSizesGetGet(treadPattern, utcDateOfWarranty, options).then((request) => request(this.axios, this.basePath));
    }
}


