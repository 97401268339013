import axios from "axios";
import * as MyTyresAPI from "@/api-client";
export const axiosInstance = axios.create();
// configuration, base path, axios instance
// const commonParams = [];

// const accessTokenPromise = new Promise<string>((resolve, reject) => {
//     var test = process.env.VUE_APP_ROOT_API;
//     console.log(test)
//     resolve("test");
//   });

const globalConf = new MyTyresAPI.Configuration({
  basePath: process.env.VUE_APP_ROOT_API,
});

const TyreTreadPatterns: MyTyresAPI.TyreTreadPatternsApi =
  new MyTyresAPI.TyreTreadPatternsApi(globalConf);
const Accounts: MyTyresAPI.AccountsApi = new MyTyresAPI.AccountsApi(globalConf);
const CarLincence: MyTyresAPI.CarLincenceApi = new MyTyresAPI.CarLincenceApi(
  globalConf
);
const Dealer: MyTyresAPI.DealerApi = new MyTyresAPI.DealerApi(globalConf);
const Dealers: MyTyresAPI.DealersApi = new MyTyresAPI.DealersApi(globalConf);
const ExternalAccess: MyTyresAPI.ExternalAccessApi =
  new MyTyresAPI.ExternalAccessApi(globalConf);
const MileageLogs: MyTyresAPI.MileageLogsApi = new MyTyresAPI.MileageLogsApi(
  globalConf
);
const Profile: MyTyresAPI.ProfileApi = new MyTyresAPI.ProfileApi(globalConf);
const Security: MyTyresAPI.SecurityApi = new MyTyresAPI.SecurityApi(globalConf);
const SocialAuth: MyTyresAPI.SocialAuthApi = new MyTyresAPI.SocialAuthApi(
  globalConf
);
const TwelveMonthTyreInsurance: MyTyresAPI.TwelveMonthTyreInsuranceApi =
  new MyTyresAPI.TwelveMonthTyreInsuranceApi(globalConf);
const VehicleTyres: MyTyresAPI.VehicleTyresApi = new MyTyresAPI.VehicleTyresApi(
  globalConf
);
const Vehicles: MyTyresAPI.VehiclesApi = new MyTyresAPI.VehiclesApi(globalConf);
const Warranty: MyTyresAPI.WarrantyApi = new MyTyresAPI.WarrantyApi(globalConf);

export {
  TyreTreadPatterns,
  Accounts,
  CarLincence,
  Dealer,
  Dealers,
  ExternalAccess,
  MileageLogs,
  Profile,
  Security,
  SocialAuth,
  TwelveMonthTyreInsurance,
  VehicleTyres,
  Vehicles,
  Warranty,
};
