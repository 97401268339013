
import { VehiclesModelsVehicleModel } from "@/api-client";
import { Vehicles } from "@/network/api";
import { Options, Vue } from "vue-class-component";
import VehicleDetails from "../components/VehicleDetails.vue";
import Spinner from "../components/Spinner.vue";

@Options({
  components: { VehicleDetails, Spinner },
})
export default class MyVehiclesPage extends Vue {
  vehicles: Array<VehiclesModelsVehicleModel> = [];
  selectedVehicle: VehiclesModelsVehicleModel | null = null;
  newVehicle = false;
  triggerNewVehicle = 0;
  visibleSpinner = false;

  created() {
    this.loadInitialData();
  }

  changeSelectedVehicle(vehicle: VehiclesModelsVehicleModel) {
    this.newVehicle = false;
    this.selectedVehicle = { ...vehicle };
    //this.loadInitialData();
  }

  deletedVehicle() {
    this.selectedVehicle = null;
    this.newVehicle = false;
    this.loadInitialData();
  }

  newVehicleView() {
    // Clear selected vehicles and present new vehicle form
    this.selectedVehicle = {
      Name: "",
      ExpiryDate: new Date().toISOString(),
      LicenseNumber: "",
      VinNumber: "",
      Make: "",
      Model: "",
      Specification: "",
      Tare: undefined,
      Colour: "",
      EstimatedMonthlyMileage: undefined,
    };
    this.triggerNewVehicle += 1;
    this.newVehicle = true;
  }

  get clonedVehicle() {
    return { ...this.selectedVehicle };
  }

  loadInitialData() {
    this.visibleSpinner = true;
    setTimeout(() => {
      Vehicles.apiPrivateVehiclesGet()
        .then((res) => {
          this.visibleSpinner = false;
          this.vehicles = res.data.Items as Array<VehiclesModelsVehicleModel>;
        })
        .catch((error) => {
          this.visibleSpinner = false;
          console.log(error);
        });
    }, 500);
  }
}
