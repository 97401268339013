import { Profile } from "@/network/api";
import { Store as VuexStore, Module, GetterTree, MutationTree } from "vuex";
import { RootState } from "..";

export type UserState = {
  profile: string | null;
  rolesData: string;
};

export enum MutationTypes {
  SetUserProfile = "SET_UserProfile",
}

export const state: UserState = {
  profile: null,
  rolesData: "",
};

type Getters = {
  profile(state: UserState): string;
};

export const getters: GetterTree<UserState, RootState> & Getters = {
  profile(state) {
    if (state.profile != null) {
      return state.profile;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user"] == null) {
      return null;
    }
    const profile = store["user"]["profile"];
    if (profile != null) {
      state.profile = profile;
      return profile;
    }

    return null;
  },
};

type Mutations = {
  [MutationTypes.SetUserProfile](state: UserState, userProfile: any): void;
};

export const mutations: MutationTree<UserState> & Mutations = {
  [MutationTypes.SetUserProfile](state, profile) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user"]) {
      store["user"] = {};
    }
    store["user"]["profile"] = profile;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.profile = profile;
  },
};

export const UserModule: Module<UserState, RootState> = {
  state,
  getters,
  mutations,
  actions: {
    getProfile: (context, params) => {
      Profile.apiPrivateProfileGet()
        .then((res) => {
          context.commit(MutationTypes.SetUserProfile, res.data);
          console.log(res);
        })
        .catch((e) => {
          console.error("LOGIN FAILED", e);
        });
    },
    updateProfile: (context, params) => {
      Profile.apiPrivateProfilePost(params)
        .then((res) => {
          context.commit(MutationTypes.SetUserProfile, res.data);
          console.log(res);
        })
        .catch((e) => {
          console.error("UPDATE PROFILE FAILED", e);
        });
    },
    clearProfile: (context, params) => {
      context.commit(MutationTypes.SetUserProfile, null);
    },
    deleteAccount: (context, params) => {
      Profile.apiPrivateProfileDelete()
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.error("DELETE PROFILE FAILED", error);
        });
    },
  },
  namespaced: true,
};
