
import { TwelveMonthTyreInsuranceModelsPolicyListModel } from "@/api-client";
import { TwelveMonthTyreInsurance } from "@/network/api";
import { Options, Vue } from "vue-class-component";
import Spinner from "@/components/Spinner.vue";
import SimpleTypeahead from "@/components/SimpleTypeahead.vue";
import UploadImage from "@/components/UploadImage.vue";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal.vue";
import SubmissionErrorModal from "@/components/SubmissionErrorModal.vue";
import InsuranceDetails from "../components/InsuranceDetails.vue";

@Options({
  components: {
    DeleteConfirmationModal,
    SubmissionErrorModal,
    Spinner,
    UploadImage,
    SimpleTypeahead,
    InsuranceDetails,
  },
  props: {
    id: { default: null },
    policyId: String,
  },
  emits: ["updateDetails", "deletedVehicle", "clearFields"],
})
export default class ViewInsuranceMobilePage extends Vue {
  policies: Array<TwelveMonthTyreInsuranceModelsPolicyListModel> = [];
  selectedPolicy: TwelveMonthTyreInsuranceModelsPolicyListModel | null = null;
  policyId?: string;

  edit = false;

  id: any;
  isMobile = true;
  visibleSpinner = false;
  newPolicy = true;

  created() {
    this.loadInitalData();
  }

  loadInitalData() {
    if (this.id !== "new_insurance") {
      TwelveMonthTyreInsurance.apiPrivateTwelvemonthtyreinsuranceGetPoliciesGet()
        .then((res) => {
          this.policies =
            res.data as Array<TwelveMonthTyreInsuranceModelsPolicyListModel>;
          console.log(this.policies, "policies");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.selectedPolicy = {
        Id: "",
        PolicyNumber: "",
        Vehicle: "",
        Status: "",
        CustomerEmail: "",
      };
      this.newPolicy = true;
    }
  }
}
