
import { Options, Vue } from "vue-class-component";
import UploadService from "../services/FileService";
import Spinner from "./Spinner.vue";

@Options({
  components: {
    Spinner,
  },
  props: {
    initialImage: String,
    alt: String,
  },
  emits: ["imagePath"],
})
export default class UploadImage extends Vue {
  showProgress = false;
  currentImage?: File | null = null;
  alt?: string = "";
  initialImage?: string | null = null;
  previewImage?: string | null = this.initialImage ?? null;
  progress = 0;
  message = "";

  created () {
    this.$watch("initialImage", () => {
      this.previewImage = this.initialImage;
    });
  }

  selectImage() {
    var fileInput = this.$refs.file as any;
    var file = fileInput.files.item(0) as File;
    this.currentImage = file;
    this.progress = 0;
    this.message = "";
    this.previewImage = URL.createObjectURL(this.currentImage);
    this.upload();
  }

  upload() {
    this.showProgress = true;
    this.progress = 0;
    UploadService.upload(this.currentImage, (event: any) => {
      this.progress = Math.round((100 * event.loaded) / event.total);
    })
      .then((response) => {
        this.showProgress = false;
        var path = response.data.RelativePath;
        this.$emit("imagePath", path);
      })
      .catch((err) => {
        this.showProgress = false;
        this.progress = 0;
        this.message = "Could not upload the image! " + err;
        this.currentImage = undefined;
      });
  }
}
