import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";

import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

import { ConfigureAxios } from "@/network/auth-interceptor";
import { UserModule } from "./store/modules/user";
import { AuthModule } from "./store/modules/auth";
import dayjs from "dayjs";
import VueGapi from "vue-gapi";

import VueAppleLogin from "@/social-auth-providers/apple-signin";
import VueFacebookLogin from "@/social-auth-providers/facebook-signin";

ConfigureAxios();

store.registerModule("user", UserModule);
store.registerModule("auth", AuthModule);

const app = createApp(App);

app.use(store, key);
app.use(router);
app.use(BootstrapVue3);

// app.use(VueAppleLogin, {
//   clientId: 'com.cliftonclothing.website',
//   scope: 'name email',
//   redirectURI: 'https://c724-41-162-112-68.ngrok.io/login',
//   state: Date().toString(),
//   usePopup: true,
// });

app.use(VueAppleLogin, {
  clientId: "com.mydunlop.vue",
  scope: "name email",
  redirectURI: process.env.VUE_APP_ROOT + "/login",
  state: Date().toString(),
  usePopup: true,
});

app.use(VueFacebookLogin, {
  appId: "292483674837731",
  cookie: true,
  xfbml: true,
  version: "v12.0",
});

app.use(VueGapi, {
  clientId:
    "901116828326-t7qn0kh91oqnkueg5irdh73se0tbp9lg.apps.googleusercontent.com",
  scope: "https://www.googleapis.com/auth/userinfo.email",
});

app.config.globalProperties.$filters = {
  date(input: Date, formatter: string) {
    if (formatter) {
      return dayjs(input).format(formatter);
    }
    return dayjs(input).format("DD/MM/YYYY");
  },
};

app.config.globalProperties.$dayjs = dayjs;

app.mount("#app");
