
import {
  DealersModelsDealerForMapModel,
  ProfileModelsProfileModel,
  WarrantyModelCurrency,
  WarrantyModelWarrantyClaimModel,
  WarrantyModelWarrantyModel,
} from "@/api-client";
import { Warranty, Dealers } from "@/network/api";
import { Options, Vue } from "vue-class-component";
import SimpleTypeahead from "./SimpleTypeahead.vue";
import UploadImage from "./UploadImage.vue";
import SuccessModal from "@/components/SuccessModal.vue";
import { store } from "@/store";

@Options({
  components: {
    SuccessModal,
    UploadImage,
    SimpleTypeahead,
  },
  props: {
    selectedWarranty: { default: null },
    isMobile: Boolean,
  },
  emits: ["setVisibleSpinner", "warrantyClaimed", "cancelClaim"],
})
export default class ClaimWarrantyView extends Vue {
  user: ProfileModelsProfileModel = { FirstName: "", Email: "" };

  isMobile?: boolean;

  currencies: Array<WarrantyModelCurrency> = [];
  dealers: Array<DealersModelsDealerForMapModel> = [];

  currency?: WarrantyModelCurrency = undefined;
  dealer?: DealersModelsDealerForMapModel = {};

  termsAndDetailsConfirmed = false;

  selectedWarranty: WarrantyModelWarrantyModel = {};

  treadDepths: Array<number> = [0, 0, 0, 0];

  warrantyClaim: WarrantyModelWarrantyClaimModel = {
    TreadDepths: [0, 0, 0, 0],
    TyreWearPhotoUrls: ["", "", "", "", "", "", "", ""],
    TyreInfo: [{}, {}, {}, {}],
  };

  get parseDate() {
    return new Date(this.selectedWarranty?.DateOfPurchase!);
  }

  created() {
    this.getCurrencies();

    this.user = store.getters["user/profile"] as ProfileModelsProfileModel;

    // this.$watch("triggerNewWarranty", () => {
    //   if (this.newWarranty) {
    //     this.edit = true;
    //     this.clearFields();
    //   }
    // });
  }

  updateTyreImageOnePath(path: string) {
    if (!this.warrantyClaim.TyreWearPhotoUrls) {
      this.warrantyClaim.TyreWearPhotoUrls = ["", "", "", "", "", "", "", ""];
    }
    this.warrantyClaim.TyreWearPhotoUrls[0] = path;
  }
  updateTyreImageTwoPath(path: string) {
    if (!this.warrantyClaim.TyreWearPhotoUrls) {
      this.warrantyClaim.TyreWearPhotoUrls = ["", "", "", "", "", "", "", ""];
    }
    this.warrantyClaim.TyreWearPhotoUrls[1] = path;
  }
  updateTyreImageThreePath(path: string) {
    if (!this.warrantyClaim.TyreWearPhotoUrls) {
      this.warrantyClaim.TyreWearPhotoUrls = ["", "", "", "", "", "", "", ""];
    }
    this.warrantyClaim.TyreWearPhotoUrls[2] = path;
  }
  updateTyreImageFourPath(path: string) {
    if (!this.warrantyClaim.TyreWearPhotoUrls) {
      this.warrantyClaim.TyreWearPhotoUrls = ["", "", "", "", "", "", "", ""];
    }
    this.warrantyClaim.TyreWearPhotoUrls[3] = path;
  }
  updateTyreImageFivePath(path: string) {
    if (!this.warrantyClaim.TyreWearPhotoUrls) {
      this.warrantyClaim.TyreWearPhotoUrls = ["", "", "", "", "", "", "", ""];
    }
    this.warrantyClaim.TyreWearPhotoUrls[4] = path;
  }
  updateTyreImageSixPath(path: string) {
    if (!this.warrantyClaim.TyreWearPhotoUrls) {
      this.warrantyClaim.TyreWearPhotoUrls = ["", "", "", "", "", "", "", ""];
    }
    this.warrantyClaim.TyreWearPhotoUrls[5] = path;
  }
  updateTyreImageSevenPath(path: string) {
    if (!this.warrantyClaim.TyreWearPhotoUrls) {
      this.warrantyClaim.TyreWearPhotoUrls = ["", "", "", "", "", "", "", ""];
    }
    this.warrantyClaim.TyreWearPhotoUrls[6] = path;
  }
  updateTyreImageEightPath(path: string) {
    if (!this.warrantyClaim.TyreWearPhotoUrls) {
      this.warrantyClaim.TyreWearPhotoUrls = ["", "", "", "", "", "", "", ""];
    }
    this.warrantyClaim.TyreWearPhotoUrls[7] = path;
  }

  onCountrySelect() {
    this.getDealers();
  }

  onDealerSelect(item: DealersModelsDealerForMapModel) {
    this.dealer = item;
  }

  warrantyClaimed() {
    this.$emit("warrantyClaimed");
  }

  cancelClaim() {
    this.$emit("cancelClaim");
  }

  claimWarranty() {
    this.warrantyClaim.CountryId = this.currency?.CountryId;
    this.warrantyClaim.DealerId = this.dealer?.SourceId;
    this.warrantyClaim.TyreWearPhotoUrls;

    this.warrantyClaim.TyreInfo = this.selectedWarranty.TyreInfo;

    this.warrantyClaim.IsClaimedInDifferentCountry =
      this.currency?.CountryId == this.selectedWarranty.CountryId;

    var now = new Date();

    this.warrantyClaim.DateOfClaim = now.toISOString().replace("Z", "");

    this.warrantyClaim.WarrantyId = this.selectedWarranty.Id;

    this.$emit("setVisibleSpinner", true);

    Warranty.apiPrivateWarrantiesClaimsCreatePost(this.warrantyClaim)
      .then((res) => {
        this.$emit("setVisibleSpinner", false);
        console.log(res);
        if (res) {
          this.$emit("warrantyClaimed");
        } else {
          document.getElementById("triggerSubmissonModal")!.click();
        }
      })
      .catch((error) => {
        this.$emit("setVisibleSpinner", false);
        console.error(error);
      });
  }

  getCurrencies() {
    this.$emit("setVisibleSpinner", true);
    Warranty.apiPrivateWarrantiesCurrenciesGetGet()
      .then((res) => {
        this.currencies = res.data as Array<WarrantyModelCurrency>;
        this.$emit("setVisibleSpinner", false);
        this.currency = this.currencies[3] ?? undefined;
        if (this.selectedWarranty.CountryId) {
          this.currencies.forEach((c) => {
            if (c.CountryId == this.selectedWarranty.CountryId) {
              this.currency = c;
            }
          });
        }
        this.getDealers();
      })
      .catch((error) => {
        console.log(error);
        this.$emit("setVisibleSpinner", false);
      });
  }

  getDealers() {
    if (!this.currency) {
      return;
    }
    this.$emit("setVisibleSpinner", true);
    Dealers.apiPublicCountriesByCountryIdCountryDealersGet(
      this.currency?.MyTyresCountryId!,
      undefined,
      undefined,
      true
    )
      .then((res) => {
        this.dealers = res.data.Items as Array<DealersModelsDealerForMapModel>;
        this.$emit("setVisibleSpinner", false);
        if (this.selectedWarranty.DealerId) {
          this.dealers.forEach((d) => {
            if (d.Id == this.selectedWarranty.DealerId) {
              this.dealer = d;
            }
          });
        }
        console.log(this.dealers, "dealers");
      })
      .catch((error) => {
        console.log(error);
        this.$emit("setVisibleSpinner", false);
      });
  }
}
