
import { Options, Vue } from "vue-class-component";
import { TyreTreadPatterns } from "@/network/api";

import { computed } from "vue";
import { useStore } from "vuex";

import { store } from "@/store";

@Options({
  props: {
    msg: String,
  },
})
export default class HelloWorld extends Vue {
  msg!: string;
  count = 0;
  user: any;
  publicTyres: any = null;

  get token() {
    return store.getters.token;
  }

  get userProfile() {
    return store.getters["user/profileData"];
  }

  created() {
    this.testnet();
  }

  setToken() {
    store.dispatch("setToken", "New Token");
  }

  testnet() {
    TyreTreadPatterns.apiPublicTyreRangesGet().then((res) => {
      this.publicTyres = res.data.Items?.map((i) => i.Name);
    });
  }
}
