
import {
  CarLicenceModelsQuoteResponseModel,
  VehiclesModelsVehicleModel,
} from "@/api-client";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    vehicle: { default: null },
    quote: { default: null },
  },
  emits: ["showSpinner", "hideSpinner", "cancelQuote", "acceptQuote"],
})
export default class RenewalQuote extends Vue {
  vehicle?: VehiclesModelsVehicleModel;
  quote?: CarLicenceModelsQuoteResponseModel;

  created() {
    // Load data if car already selected
    this.loadInitialData();

    // Reload data if different vehicle selected & reset mileage view
    this.$watch("vehicleId", () => {
      this.loadInitialData();
    });
  }

  loadInitialData() {}

  cancel() {
    this.$emit("cancelQuote");
  }

  approveQuote() {
    this.$emit("acceptQuote");
  }
}
