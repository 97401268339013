
import { Options, Vue } from "vue-class-component";
import Spinner from "../components/Spinner.vue";
import { VehiclesModelsVehicleModel } from "@/api-client";
import { Vehicles } from "@/network/api";
import LicenceRenewal from "../components/LicenceRenewal.vue";

@Options({
  components: { LicenceRenewal, Spinner },
})
export default class LicenceRenewalPage extends Vue {
  vehicles: Array<VehiclesModelsVehicleModel> = [];
  selectedVehicle: VehiclesModelsVehicleModel | null = null;
  visibleSpinner = false;

  created() {
    //redirect to home page  
    window.location.href = "/";
  }

  selectVehicle(vehicle: VehiclesModelsVehicleModel) {
    this.selectedVehicle = { ...vehicle };
  }

  hideForm() {
    this.selectedVehicle = null;
  }

  get clonedVehicle() {
    return { ...this.selectedVehicle };
  }

  loadInitialData() {
    this.visibleSpinner = true;
    this.selectedVehicle = null;
    setTimeout(() => {
      Vehicles.apiPrivateVehiclesGet()
        .then((res) => {
          this.vehicles = res.data.Items as Array<VehiclesModelsVehicleModel>;
          this.visibleSpinner = false;
        })
        .catch((error) => {
          console.log(error);
          this.visibleSpinner = false;
        });
    }, 500);
  }
}
