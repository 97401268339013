import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";
export interface RootState {
  version: string;
}

export const key: InjectionKey<Store<RootState>> = Symbol();

export const store = createStore<RootState>({
  state: {
    version: "",
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
