
import { store } from "@/store";
import { Options, Vue } from "vue-class-component";
import router from "../router";

@Options({
  props: { currentRoute: String },
})
export default class NavBar extends Vue {
  currentRoute = "Login";

  get isLoggedIn() {
    return store.getters["auth/isLoggedIn"];
  }

  get profile() {
    return store.getters["user/profile"];
  }
  async logout() {
    await store.dispatch("auth/logout");
    router.push({ path: "/login" });
  }
}
