
import { WarrantyModelWarrantyModel } from "@/api-client";
import { Warranty } from "@/network/api";
import { Options, Vue } from "vue-class-component";
import Spinner from "@/components/Spinner.vue";
import SimpleTypeahead from "@/components/SimpleTypeahead.vue";
import UploadImage from "@/components/UploadImage.vue";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal.vue";
import SubmissionErrorModal from "@/components/SubmissionErrorModal.vue";
import WarrantyDetails from "../components/WarrantyDetails.vue";

@Options({
  components: {
    DeleteConfirmationModal,
    SubmissionErrorModal,
    Spinner,
    UploadImage,
    SimpleTypeahead,
    WarrantyDetails,
  },
  props: {
    id: { default: null },
    selectedWarranty: String,
  },
  emits: ["updateDetails", "deletedVehicle", "clearFields"],
})
export default class ViewInsuranceMobilePage extends Vue {
  warranty: Array<WarrantyModelWarrantyModel> = [];
  selectedWarranty: WarrantyModelWarrantyModel | null = null;
  id: any;
  isMobile = true;
  newWarranty = false;

  created() {
    this.loadInitalData();
  }

  loadInitalData() {
    if (this.id !== "new_warranty") {
      Warranty.apiPrivateWarrantiesMineGet()
        .then((res) => {
          this.warranty = res.data![
            this.id
          ] as Array<WarrantyModelWarrantyModel>;
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.newWarranty = true;
    }
  }
}
