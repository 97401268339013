
import { MileageLogsModelsMileageLogModelWithId } from "@/api-client";
import { MileageLogs } from "@/network/api";
import { Options, Vue } from "vue-class-component";

@Options({
  props: { selectedLog: { default: null }, vehicleId: String },
  emits: ["cancel", "delete", "showSpinner", "hideSpinner"],
})
export default class ViewMileageLog extends Vue {
  selectedLog!: MileageLogsModelsMileageLogModelWithId;
  vehicleId?: string;
  buttonText = "Edit";

  get parseDate() {
    return new Date(this.selectedLog.TripDate);
  }

  expiryDateChanged(dateChangeEvent: InputEvent) {
    this.selectedLog.TripDate = (
      dateChangeEvent.target as HTMLInputElement
    ).value;
  }

  toggleFormDisplay() {
    // Toggle save/ edit
    this.buttonText === "Save"
      ? (this.buttonText = "Edit")
      : (this.buttonText = "Save");
  }

  handleForm() {
    if (this.buttonText === "Edit") {
      // Enable fields & save/cancel buttons
      this.toggleFormDisplay();
    } else if (this.buttonText === "Save") {
      // Save update existing log & reset buttons/ disable fields
      this.saveLog();
      this.toggleFormDisplay();
      //removed this.$emit("cancel")- in ViewMileageLogs this triggered  @cancel="currentView = 'all-logs'"
    }
  }

  saveLog() {
    if (
      this.selectedLog.Name &&
      this.selectedLog.DistanceInKm >= 0 &&
      this.selectedLog.TripDate
    ) {
      this.$emit("showSpinner");
      MileageLogs.apiPrivateVehiclesByVehicleIdMileageLogsByIdPost(
        this.selectedLog.Id!,
        this.vehicleId!,
        this.selectedLog
      )
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
      this.$emit("hideSpinner");
    } else {
      document.getElementById("triggerSubmissonModal")!.click();
    }
  }
}
