
import { MileageLogsModelsMileageLogModelWithId } from "@/api-client";
import { MileageLogs } from "@/network/api";
import { Options, Vue } from "vue-class-component";
import AddMileageLog from "./AddMileageLog.vue";
import ViewMileageLog from "./ViewMileageLog.vue";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal.vue";

@Options({
  components: { ViewMileageLog, AddMileageLog, DeleteConfirmationModal },
  props: {
    vehicleId: String,
    newVehicle: Boolean,
  },
  emits: ["showSpinner", "hideSpinner"],
})
export default class ViewMileageLogs extends Vue {
  mileageLogs: Array<MileageLogsModelsMileageLogModelWithId> = [];
  vehicleId?: string;
  newVehicle?: boolean;
  currentView = "all-logs";
  selectedLog: MileageLogsModelsMileageLogModelWithId = {
    Id: "",
    Name: "",
    DistanceInKm: 0,
    TripDate: "",
  };

  created() {
    // Load data if car already selected
    this.loadInitialData();

    // Reload data if different vehicle selected & reset mileage view
    this.$watch("vehicleId", () => {
      this.loadInitialData();
      this.currentView = "all-logs";
    });
  }

  loadInitialData() {
    if (this.vehicleId) {
      this.$emit("showSpinner");
      setTimeout(() => {
        MileageLogs.apiPrivateVehiclesByVehicleIdMileageLogsGet(this.vehicleId!)
          .then((res) => {
            this.mileageLogs = res.data.Items as any;
            console.log(this.mileageLogs, "loading mileage");
            this.$emit("hideSpinner");
          })
          .catch((error) => {
            console.log(error);
            this.$emit("hideSpinner");
          });
      }, 500);
    }
  }

  deleteMileageLog(log: MileageLogsModelsMileageLogModelWithId) {
    this.$emit("showSpinner");
    MileageLogs.apiPrivateVehiclesByVehicleIdMileageLogsByIdDelete(
      log.Id!,
      this.vehicleId!
    )
      .then((res) => {
        this.loadInitialData();
        console.log(res);
        this.$emit("hideSpinner");
      })
      .catch((error) => {
        console.error(error);
        this.$emit("hideSpinner");
      });
  }
}
