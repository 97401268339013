
import { Options, Vue } from "vue-class-component";

@Options({
  props: { message: String },
  // emits: ["confirmed"],
})
export default class SuccessModal extends Vue {
  messsage = "Operation completed successfully";
}
