
import { ProfileModelsProfileModel } from "@/api-client";
import { store } from "@/store";
import { Options, Vue } from "vue-class-component";
import { Dealers } from "@/network/api";
import router from "@/router";
import Spinner from "../components/Spinner.vue";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal.vue";

@Options({
  components: { Spinner, DeleteConfirmationModal },
})
export default class MyProfilePage extends Vue {
  userProfile: ProfileModelsProfileModel = { FirstName: "", Email: "" };
  clonedProfile: ProfileModelsProfileModel = { ...this.userProfile };
  countries: Array<any> = [];
  buttonText = "Save";
  visibleSpinner = false;

  genders: Array<string> = ["Male", "Female", "Other"];
  selectedGender?: string;

  created() {
    this.visibleSpinner = true;
    // Fetch country list
    Dealers.apiPublicCountriesGet().then((res) => {
      this.countries = res.data.Items as Array<any>;
      this.visibleSpinner = false;
    });
  }

  mounted() {
    console.log("Getting profile");
    this.userProfile = store.getters["user/profile"];
    this.clonedProfile = { ...this.userProfile };
  }

  handleForm() {
    if (this.buttonText === "Edit") {
      // Enable fields & save/cancel buttons
      this.buttonText = "Save";
    } else if (this.buttonText === "Save") {
      if (this.userProfile.FirstName && this.userProfile.Email) {
        this.visibleSpinner = true;

        // Update user profile
        store.dispatch("user/updateProfile", this.userProfile);

        // Reset buttons & disable fields/spinner
        this.buttonText = "Edit";
        this.visibleSpinner = false;
      } else {
        document.getElementById("triggerSubmissonModal")!.click();
      }
    }
  }

  handleCancel() {
    if (this.buttonText === "Save") {
      this.userProfile = { ...this.clonedProfile };
      this.buttonText = "Edit";
    } else {
      router.push({ path: "/" });
    }
  }

  async deleteAccount() {
    await store.dispatch("user/deleteAccount");
    await store.dispatch("auth/logout");
    router.push({ path: "/login" });
  }
}
