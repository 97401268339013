import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_TyreSizingModal = _resolveComponent("TyreSizingModal")!
  const _component_SubmissionErrorModal = _resolveComponent("SubmissionErrorModal")!
  const _component_TyreManufactureInfoModal = _resolveComponent("TyreManufactureInfoModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavBar, { currentRoute: _ctx.currentRoute }, null, 8, ["currentRoute"]),
    _createVNode(_component_router_view),
    _createVNode(_component_Footer),
    _createVNode(_component_TyreSizingModal),
    _createVNode(_component_SubmissionErrorModal),
    _createVNode(_component_TyreManufactureInfoModal)
  ], 64))
}