import { Accounts, Security, SocialAuth } from "@/network/api";
import NodeRSA from "node-rsa";
import { Store as VuexStore, Module, GetterTree, MutationTree } from "vuex";
import { RootState, store } from "..";

// interface TokenResponse {}

export enum SocialProviders {
  Google,
  Facebook,
  Apple,
}

export enum MutationTypes {
  SetToken = "SET_Auth_Token",
}

export type AuthState = {
  token: string | null;
  isLoggedIn: boolean;
};

export const state: AuthState = {
  token: null,
  isLoggedIn: getTokenState(),
};

type Getters = {
  token(state: AuthState, rootState: RootState): string;
  isLoggedIn(state: AuthState): boolean;
};

function getTokenState(_state?: AuthState) {
  if (_state && _state!.token != null) {
    return _state!.token;
  }
  const vuex = localStorage.getItem("vuex");
  const store = JSON.parse(vuex!);
  if (store == null) {
    return null;
  }
  if (store["auth"] == null) {
    return null;
  }
  const tkn = store["auth"]["token"];
  if (tkn != null) {
    if (_state) {
      _state!.token = tkn;
    }
    return tkn;
  }
  return null;
}

export const getters: GetterTree<AuthState, RootState> & Getters = {
  token(state) {
    return getTokenState(state);
  },
  isLoggedIn(state) {
    // if (state.isLoggedIn )
    // var test = getTokenState(state) == null ? false : true;
    // console.log(`User Logged in : ${test}`)
    return state.isLoggedIn; // state.isLoggedIn;
  },
};

type Mutations = {
  [MutationTypes.SetToken](state: AuthState, token: any): void;
};

export const mutations: MutationTree<AuthState> & Mutations = {
  [MutationTypes.SetToken](state: AuthState, token: any) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["auth"]) {
      store["auth"] = {};
    }
    store["auth"]["token"] = token;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.token = { ...token };
    if (token) {
      state.isLoggedIn = true;
    } else {
      state.isLoggedIn = false;
    }
  },
};

let _refreshingToken: Promise<void> | null = null;

export const AuthModule: Module<AuthState, RootState> = {
  state,
  getters,
  mutations,
  actions: {
    login: async (context, params) => {
      return new Promise<void>((resolve, reject) => {
        const key = new NodeRSA(
          process.env.VUE_APP_PUBLIC_KEY!,
          "pkcs1-public",
          {
            encryptionScheme: "pkcs1",
          }
        );

        const encryptedPassword = key.encrypt(params.password!, "base64");

        return Security.apiSecurityTokenPost({
          Username: params.email!,
          Password: encryptedPassword,
        })
          .then(async (res) => {
            // Maybe add some promises here so the front end can know when the state set is complete
            context.commit(MutationTypes.SetToken, res.data);
            context
              .dispatch("user/getProfile", null, { root: true })
              .then(() => {
                resolve();
              });
          })
          .catch((e) => {
            console.error("LOGIN FAILED", e);
            reject();
          });
      });
    },
    logout: async (context, params) => {
      return new Promise<void>((resolve, reject) => {
        context.commit(MutationTypes.SetToken, null);
        context.dispatch("user/clearProfile", null, { root: true }).then(() => {
          resolve();
        });
      });
    },
    forgotComplete: async (context, params) => {
      return new Promise<void>((resolve, reject) => {
        Security.apiSecurityForgotPasswordCompletePost(params)
          .then(async (res) => {
            if (res.status == 200) {
              context.commit(MutationTypes.SetToken, res.data);
              context
                .dispatch("user/getProfile", null, { root: true })
                .then(() => {
                  resolve();
                });
            }
          })
          .catch((e) => {
            console.error("RESET FAILED", e);
            reject();
          });
      });
    },
    refresh: async (context, params) => {
      return (_refreshingToken ??= new Promise<void>((resolve, reject) => {
        const refreshToken = (state.token! as any)["refresh_token"];
        Security.apiSecurityRefreshPost({
          refresh_token: refreshToken,
        })
          .then(async (res) => {
            // Maybe add some promises here so the front end can know when the state set is complete
            context.commit(MutationTypes.SetToken, res.data);
            await context.dispatch("user/getProfile", null, { root: true });
            console.log("Resolve now");
            resolve();
          })
          .catch((e) => {
            console.error("LOGIN FAILED", e);
            reject();
          });
      }).finally(() => (_refreshingToken = null)));
    },
    socialAuth: async (context, params) => {
      return new Promise<void>((resolve, reject) => {
        switch (params.provider as SocialProviders) {
          case SocialProviders.Google:
            SocialAuth.apiPublicSocialAuthGooglePost({ Token: params.token })
              .then(async (res) => {
                context.commit(MutationTypes.SetToken, res.data);
                await context.dispatch("user/getProfile", null, { root: true });
                resolve();
              })
              .catch((error) => {
                console.error(error);
                reject();
              });

            break;
          case SocialProviders.Facebook:
            SocialAuth.apiPublicSocialAuthFacebookPost({ Token: params.token })
              .then(async (res) => {
                context.commit(MutationTypes.SetToken, res.data);
                await context.dispatch("user/getProfile", null, { root: true });
                resolve();
              })
              .catch((error) => {
                console.error(error);
                reject();
              });
            break;
          case SocialProviders.Apple:
            SocialAuth.apiPublicSocialAuthApplePost({
              Token: params.token,
              FirstName: params.firstname,
              LastName: params.lastname,
            })
              .then(async (res) => {
                context.commit(MutationTypes.SetToken, res.data);
                await context.dispatch("user/getProfile", null, { root: true });
                resolve();
              })
              .catch((error) => {
                console.error(error);
                reject();
              });
            break;
        }
      });
    },
    register: (context, params) => {
      const key = new NodeRSA(process.env.VUE_APP_PUBLIC_KEY!, "pkcs1-public", {
        encryptionScheme: "pkcs1",
      });

      const encryptedPassword = key.encrypt(params.password!, "base64");

      Accounts.apiPublicAccountsPost({
        FirstName: "Test",
        LastName: "User",
        CountryId: "d5d7d2a0-30a1-437b-bfd4-97a718522bcf",
        Email: params.email!,
        Password: encryptedPassword,
        DateOfBirth: "",
      }).then((res) => {
        console.log(res);
      });
    },
  },
  namespaced: true,
};
