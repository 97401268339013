import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33a67061"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = { class: "button-container d-block" }
const _hoisted_3 = {
  key: 0,
  class: "upload-spinner"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "position-relative" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = {
  key: 2,
  class: "alert alert-secondary",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createElementVNode("input", {
        type: "file",
        accept: "image/*",
        ref: "file",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectImage && _ctx.selectImage(...args)))
      }, null, 544)
    ]),
    (_ctx.showProgress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Spinner, { visible: _ctx.showProgress }, null, 8, ["visible"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.previewImage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", {
              href: _ctx.previewImage,
              target: "_blank"
            }, [
              _createElementVNode("img", {
                class: "preview img-fluid position-relative my-3",
                src: _ctx.previewImage,
                alt: _ctx.alt,
                height: "200"
              }, null, 8, _hoisted_7)
            ], 8, _hoisted_6)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.message)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.message), 1))
      : _createCommentVNode("", true)
  ]))
}