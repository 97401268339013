
import { Vue } from "vue-class-component";
import { Accounts, Dealers } from "@/network/api";
import NodeRSA from "node-rsa";
import { ModelsRegisterAppUserRequest } from "@/api-client";

export default class RegistrationPage extends Vue {
  newUser: ModelsRegisterAppUserRequest = {
    FirstName: "",
    Email: "",
    Password: "",
    DateOfBirth: "",
    CountryId: "",
  };
  countries: Array<any> = [];

  created() {
    // Fetch country list
    Dealers.apiPublicCountriesGet().then((res) => {
      this.countries = res.data.Items as Array<any>;
    });
  }

  register() {
    // login call will use this
    // extract this into vuex

    var messageContainer = document.getElementById(
      "registrationMessagesContainer"
    );
    try {
      if (messageContainer != null && messageContainer != undefined) {
        messageContainer.textContent = "";
      }
    } catch {}

    if (
      this.newUser.FirstName &&
      this.newUser.Email &&
      this.newUser.Password &&
      this.newUser.DateOfBirth &&
      this.newUser.CountryId
    ) {
      const key = new NodeRSA(process.env.VUE_APP_PUBLIC_KEY!, "pkcs1-public", {
        encryptionScheme: "pkcs1",
      });
      var encryptedPassword = key.encrypt(this.newUser.Password!, "base64");

      Accounts.apiPublicAccountsPost({
        FirstName: this.newUser.FirstName,
        LastName: this.newUser.LastName,
        CountryId: this.newUser.CountryId,
        Email: this.newUser.Email,
        Password: encryptedPassword,
        DateOfBirth: this.newUser.DateOfBirth,
        IsMyDunlopDashboardRequest: true,
      })
        .then((res) => {
          console.log(res);
          if (messageContainer != null && messageContainer != undefined) {
            var p = document.createElement("p");
            p.textContent =
              "You have successfully registered your account. Please check your email.";
            messageContainer?.append(p);
          }
        })
        .catch((error) => {
          if (
            error.response?.data?.Errors?.length > 0 &&
            messageContainer != null &&
            messageContainer != undefined
          ) {
            var heading = document.createElement("p");
            heading.style.color = "red";
            heading.textContent = "Registration Failed:";
            messageContainer?.append(heading);
            for (var i = 0; i < error.response?.data?.Errors?.length; i++) {
              var p = document.createElement("p");
              p.style.color = "red";
              p.textContent = error.response?.data?.Errors[i].Description;
              messageContainer?.append(p);
            }
          }
        });
    } else {
      document.getElementById("triggerSubmissonModal")!.click();
    }
  }

  dateOfBirthChanged(dateChangeEvent: InputEvent) {
    this.newUser.DateOfBirth = (
      dateChangeEvent.target as HTMLInputElement
    ).value;
  }
}
