
import { VehicleTyresModelsVehicleTyreModelWithId } from "@/api-client";
import { VehicleTyres } from "@/network/api";
import { Options, Vue } from "vue-class-component";
import AddTyres from "./AddTyres.vue";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal.vue";

@Options({
  components: { AddTyres, DeleteConfirmationModal },
  props: { vehicleId: String },
  emits: ["showSpinner", "hideSpinner"],
})
export default class ViewTyres extends Vue {
  vehicleId!: string;
  tyres: Array<VehicleTyresModelsVehicleTyreModelWithId> = [];
  selectedTyre: VehicleTyresModelsVehicleTyreModelWithId = {
    RangeName: "",
    AspectRatio: "",
    RimSize: "",
    SectionWidth: "",
    FitmentDateUtc: "",
  };
  currentView = "tyres";
  buttonText = "Edit";

  created() {
    // Load data if car already selected
    this.loadInitialData();

    // Reload data if different vehicle selected
    this.$watch("vehicleId", () => {
      this.loadInitialData();
      this.currentView = "tyres";
    });

    this.$watch("selectedTyre", () => {
      if (this.buttonText === "Save") {
        this.toggleFormDisplay();
      }
    });
  }

  get parseDate() {
    return new Date(this.selectedTyre.FitmentDateUtc!);
  }

  expiryDateChanged(dateChangeEvent: InputEvent) {
    this.selectedTyre.FitmentDateUtc = (
      dateChangeEvent.target as HTMLInputElement
    ).value;
  }

  toggleFormDisplay() {
    // Toggle save/ edit
    this.buttonText === "Save"
      ? (this.buttonText = "Edit")
      : (this.buttonText = "Save");
  }

  handleForm() {
    if (this.buttonText === "Edit") {
      // Enable fields & save/cancel buttons
      this.toggleFormDisplay();
    } else if (this.buttonText === "Save") {
      // Save new update existing tyre & reset buttons/ disable fields
      this.saveTyre();
      this.toggleFormDisplay();
    }
  }

  saveTyre() {
    this.$emit("showSpinner");
    VehicleTyres.apiPrivateVehiclesByVehicleIdTyresByIdPost(
      this.vehicleId,
      this.selectedTyre.Id!,
      this.selectedTyre
    )
      .then((res) => {
        console.log(res);
        this.$emit("hideSpinner");
      })
      .catch((error) => {
        console.log(error);
        this.$emit("hideSpinner");
      });
  }

  deleteTyre() {
    if (this.vehicleId) {
      this.$emit("showSpinner");
      VehicleTyres.apiPrivateVehiclesByVehicleIdTyresByIdDelete(
        this.vehicleId,
        this.selectedTyre.Id!
      )
        .then((res) => {
          console.log(res);
          this.loadInitialData();
          this.$emit("hideSpinner");
        })
        .catch((error) => {
          console.log(error);
          this.$emit("hideSpinner");
        });
    }
  }

  cancelNewTyre() {
    this.currentView = "tyres";
    this.loadInitialData();
  }

  loadInitialData() {
    if (this.vehicleId) {
      this.$emit("showSpinner");
      setTimeout(() => {
        VehicleTyres.apiPrivateVehiclesByVehicleIdTyresGet(this.vehicleId)
          .then((res) => {
            if (res.data.Items!.length > 0) {
              this.tyres = res.data
                .Items as Array<VehicleTyresModelsVehicleTyreModelWithId>;
              this.selectedTyre = this.tyres[0];
              console.log("tyres loaded", this.tyres);
            } else {
              this.tyres = [];
              this.selectedTyre = {
                RangeName: "",
                AspectRatio: "",
                RimSize: "",
                SectionWidth: "",
                FitmentDateUtc: "",
              };
              console.log("There are no tyres on this vehicle");
            }
          })
          .catch((error) => {
            console.error(error);
          });
        this.$emit("hideSpinner");
      }, 500);
    }
  }
}
