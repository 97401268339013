
import { Vue, Options } from "vue-class-component";
import NodeRSA from "node-rsa";
import { Security } from "../network/api";
import OtpInput from "@/components/OtpInput.vue";
import { store } from "../store";
import router from "../router";

@Options({
  components: {
    OtpInput,
  },
})
export default class ForgotPasswordPage extends Vue {
  email?: string | null;
  password?: string | null;
  passwordConfirm?: string | null;

  resetTab = true;
  _loading = false;
  otp = "";

  handleOnComplete(_otp: any) {
    this.otp = _otp;
  }

  async resetPassword() {
    console.log("RESET");

    if (this.resetTab) {
      if (!this._loading) {
        this._loading = true;
        this.passwordConfirm = this.password;
        if (this.password == this.passwordConfirm) {
          const key = new NodeRSA(
            process.env.VUE_APP_PUBLIC_KEY!,
            "pkcs1-public",
            {
              encryptionScheme: "pkcs1",
            }
          );
          var encryptedPassword = key.encrypt(this.password!, "base64");

          Security.apiSecurityForgotPasswordRequestPost({
            Email: this.email!,
            Password: encryptedPassword,
          })
            .then((res) => {
              if (res.status == 200) {
                // Enter code now
                this.resetTab = false;
              }
              console.log(res);
            })
            .finally(() => {
              this._loading = false;
            });
        } else {
          this._loading = false;
        }
      }
    } else {
      if (!this._loading) {
        this._loading = true;
        await store
          .dispatch("auth/forgotComplete", {
            Email: this.email!,
            Code: this.otp,
          })
          .then(async () => {
            const returnUrl = router.currentRoute.value.query[
              "returnUrl"
            ] as string;
            await router.push({ path: returnUrl || "/" });
          })
          .finally(() => {
            this._loading = false;
          });
      }
    }
  }
}
