
import {
  DealersModelsDealerForMapModel,
  ProfileModelsProfileModel,
  VehiclesModelsVehicleModel,
  WarrantyModelAddWarrantyModel,
  WarrantyModelCurrency,
  WarrantyModelTreadPatternLookUpModel,
  WarrantyModelTyreSizeLookupModel,
  WarrantyModelWarrantyModel,
  WarrantyModelWarrantyTyre,
} from "@/api-client";
import { Warranty, Vehicles, Dealers } from "@/network/api";
import { Options, Vue } from "vue-class-component";
import ViewMileageLogs from "./ViewMileageLogs.vue";
import AddMileageLog from "./AddMileageLog.vue";
import ViewTyres from "./ViewTyres.vue";
import Spinner from "./Spinner.vue";
import SimpleTypeahead from "./SimpleTypeahead.vue";
import UploadImage from "./UploadImage.vue";
import router from "@/router";

import ClaimWarrantyView from "../components/WarrantyClaim.vue";
import MaintenanceLogView from "../components/MaintenanceLog.vue";

import SuccessModal from "@/components/SuccessModal.vue";
import SubmissionErrorModal from "@/components/SubmissionErrorModal.vue";
import { store } from "@/store";

@Options({
  components: {
    ViewMileageLogs,
    AddMileageLog,
    ViewTyres,
    SuccessModal,
    SubmissionErrorModal,
    Spinner,
    UploadImage,
    SimpleTypeahead,
    ClaimWarrantyView,
    MaintenanceLogView,
  },
  props: {
    selectedWarranty: { default: null },
    newWarranty: Boolean,
    isMobile: Boolean,
    triggerNewWarranty: Number,
  },
  emits: ["updateDetails", "clearFields"],
  computed: {
    isEditable() {
      return this.edit;
    },
  },
})
export default class WarrantyClaimView extends Vue {
  warrantyId = "";

  user: ProfileModelsProfileModel = { FirstName: "", Email: "" };

  newWarranty?: boolean;
  edit = false;
  triggerNewWarranty = 0;
  isMobile?: boolean;

  isNewVehicle = false;
  newVehicleName?: string | null = null;

  isClaim = false;
  isMaintenance = false;
  isMaintenanceDetail = false;

  visibleSpinner = false;

  currencies: Array<WarrantyModelCurrency> = [];
  dealers: Array<DealersModelsDealerForMapModel> = [];
  vehicles: Array<VehiclesModelsVehicleModel> = [];
  tyrePatterns: Array<WarrantyModelTreadPatternLookUpModel> = [];
  tyreSizes: Array<WarrantyModelTyreSizeLookupModel> = [];

  currency?: WarrantyModelCurrency = undefined;
  dealer?: DealersModelsDealerForMapModel = {};

  vehicle?: VehiclesModelsVehicleModel | null = null;

  termsAndDetailsConfirmed = false;
  purchaseDate?: string | null = null;

  tyrePattern?: WarrantyModelTreadPatternLookUpModel = {};
  tyreSize?: WarrantyModelTyreSizeLookupModel = {};

  tyres: Array<WarrantyModelWarrantyTyre> = [{}, {}, {}, {}];

  selectedWarranty?: WarrantyModelWarrantyModel | null = null;

  currentTab = "details";
  maintenanceList: any = null;
  maintenanceDetail: any = null;

  warranty: WarrantyModelWarrantyModel = {
    Id: undefined,
    CountryName: undefined,
    DealerName: undefined,
    ReferenceNumber: undefined,
    StatusId: undefined,
    Status: undefined,
    InvoiceSimpleFileId: undefined,
    WheelAlignmentCertificateSimpleFileId: undefined,
    TyreMileageWarranty: undefined,
    Comment: undefined,
    Username: undefined,
    WheelAlignmentCertificateUrl: undefined,
    InvoiceUrl: undefined,
    MyTyresUserProfileId: undefined,
    CountryId: undefined,
    DealerId: undefined,
    TotalPurchasePrice: undefined,
    DateOfPurchase: undefined,
    CounterHandName: undefined,
    CounterHandCellNumber: undefined,
    UserEmail: undefined,
    VehicleName: undefined,
    VehicleLicenceNumber: undefined,
    VehicleId: undefined,
    CurrentVehicleMileage: undefined,
    WheelAlignmentCertificatePhotoUrl: undefined,
    InvoicePhotoUrl: undefined,
    TreadPattern: undefined,
    TyreSize: undefined,
    WarrantyMaintenances: undefined,
    TyreInfo: undefined,
  };

  warrantyUpload: WarrantyModelAddWarrantyModel = {
    MyTyresUserProfileId: undefined,
    CountryId: undefined,
    DealerId: undefined,
    TotalPurchasePrice: undefined,
    DateOfPurchase: undefined,
    CounterHandName: undefined,
    CounterHandCellNumber: undefined,
    UserEmail: undefined,
    VehicleName: undefined,
    VehicleLicenceNumber: undefined,
    VehicleId: undefined,
    CurrentVehicleMileage: undefined,
    WheelAlignmentCertificatePhotoUrl: undefined,
    InvoicePhotoUrl: undefined,
    TreadPattern: undefined,
    TyreSize: undefined,
    TyreInfo: [],
  };

  get parseDate() {
    return new Date(this.warranty?.DateOfPurchase!);
  }

  created() {
    this.handleTabChange("details");
    this.getCurrencies();
    this.getVehicles();
    this.getPatterns();

    this.user = store.getters["user/profile"] as ProfileModelsProfileModel;
    this.edit = this.newWarranty == true;

    this.$watch("triggerNewWarranty", () => {
      this.handleTabChange("details");
      if (this.newWarranty) {
        this.edit = true;

        this.clearFields();
      }
    });

    this.$watch("selectedWarranty", () => {
      this.handleTabChange("details");
      if (
        this.selectedWarranty == undefined ||
        this.selectedWarranty["Id"] == undefined
      ) {
        return;
      }
      this.edit = false;
      this.visibleSpinner = true;
      setTimeout(() => {
        this.getWarranty();
      }, 100);
    });
  }

  handleTabChange(tab: string) {
    this.currentTab = tab;
  }

  setVisibleSpinner(isVisible: boolean) {
    this.visibleSpinner = isVisible;
  }

  purchaseDateChanged(dateChangeEvent: InputEvent) {
    this.warranty.DateOfPurchase =
      (dateChangeEvent.target as HTMLInputElement).value + "T00:00:00";
  }

  updateInvoiceImagePath(path: string) {
    this.warrantyUpload.InvoicePhotoUrl = path;
  }

  updateAlignmentCertImagePath(path: string) {
    this.warrantyUpload.WheelAlignmentCertificatePhotoUrl = path;
  }

  onCountrySelect() {
    this.getDealers();
  }

  onRangeSelect() {
    this.getPatterns();
  }

  onPatternSelect() {
    this.getSizes();
  }

  onDealerSelect(item: DealersModelsDealerForMapModel) {
    this.dealer = item;
  }
  onDealerInput(item: any) {
    if (item.input != undefined && item.input.length > 0) {
      var dealer = this.validateSelectedDealer(item.input);
      if (dealer != null && dealer != undefined) {
        this.dealer = dealer;
      } else {
        this.dealer = {};
      }
    }
  }
  getMaintenance() {
    if (this.warranty?.Id == undefined) {
      return;
    }
    Warranty.apiPrivateWarrantiesMaintenancesByWarrantyIdGet(
      this.warranty.Id
    ).then((res) => {
      this.maintenanceList = res.data;
    });
  }
  viewMaintenanceLog(log: any) {
    this.isMaintenanceDetail = true;
    this.maintenanceDetail = log;
  }
  getWarranty() {
    this.clearContainerMessages();
    if (this.selectedWarranty != null) {
      this.isClaim = false;
      this.isMaintenance = false;
      this.isMaintenanceDetail = false;
      this.edit =
        this.selectedWarranty.Status?.toLowerCase() == "awaiting correction";
      this.maintenanceList = [];
      this.maintenanceDetail = null;

      this.warranty = this.selectedWarranty;
      this.purchaseDate = this.warranty.DateOfPurchase!.split("T")[0];
      this.getMaintenance();

      this.currencies.forEach((c) => {
        if (c.CountryId == this.warranty.CountryId) {
          this.currency = c;
          this.getDealers();
        }
      });

      this.dealer = {};
      this.dealers.forEach((d) => {
        if (d.SourceId == this.warranty.DealerId) {
          this.dealer = d;
          (this.$refs.dealer_typeahead_ref as any).selectItem(this.dealer);
        }
      });

      this.vehicles.forEach((v) => {
        if (v.Id == this.warranty.VehicleId) {
          this.vehicle = v;
        }
      });

      this.tyrePatterns.forEach((p) => {
        if (p.Tread == this.warranty.TreadPattern) {
          this.tyrePattern = p;
          this.getSizes();
        }
      });

      this.tyres = this.warranty.TyreInfo ?? [];

      (this.$refs.invoice_url_ref as any).previewImage =
        this.warranty.InvoicePhotoUrl;
      (this.$refs.wheel_alignment_url_ref as any).previewImage =
        this.warranty.WheelAlignmentCertificatePhotoUrl;

      this.warrantyUpload.WheelAlignmentCertificatePhotoUrl =
        this.warranty.WheelAlignmentCertificatePhotoUrl;
      this.warrantyUpload.InvoicePhotoUrl = this.warranty.InvoicePhotoUrl;
    }
  }

  cancelChanges() {
    this.$emit("clearFields");
    this.edit = false;
    this.isClaim = false;
    this.isMaintenance = false;
    this.isMaintenanceDetail = false;
    this.maintenanceList = [];
    this.maintenanceDetail = null;
    if (this.newWarranty) {
      this.clearFields();
    } else {
      this.getWarranty;
    }
  }

  clearFields() {
    this.isClaim = false;
    this.isMaintenance = false;
    this.isMaintenanceDetail = false;
    this.maintenanceList = [];
    this.maintenanceDetail = null;
    this.vehicle = null;
    this.isNewVehicle = false;
    this.newVehicleName = null;
    this.termsAndDetailsConfirmed = false;
    this.purchaseDate = null;
    this.tyres = [{}, {}, {}, {}];

    this.dealer = {};

    this.warranty = {
      Id: undefined,
      CountryName: undefined,
      DealerName: undefined,
      ReferenceNumber: undefined,
      StatusId: undefined,
      Status: undefined,
      InvoiceSimpleFileId: undefined,
      WheelAlignmentCertificateSimpleFileId: undefined,
      TyreMileageWarranty: undefined,
      Comment: undefined,
      Username: undefined,
      WheelAlignmentCertificateUrl: undefined,
      InvoiceUrl: undefined,
      MyTyresUserProfileId: undefined,
      CountryId: undefined,
      DealerId: undefined,
      TotalPurchasePrice: undefined,
      DateOfPurchase: undefined,
      CounterHandName: undefined,
      CounterHandCellNumber: undefined,
      UserEmail: undefined,
      VehicleName: undefined,
      VehicleLicenceNumber: undefined,
      VehicleId: undefined,
      CurrentVehicleMileage: undefined,
      WheelAlignmentCertificatePhotoUrl: undefined,
      InvoicePhotoUrl: undefined,
      TreadPattern: undefined,
      TyreSize: undefined,
      WarrantyMaintenances: undefined,
      TyreInfo: this.tyres,
    };

    this.warrantyUpload = {
      MyTyresUserProfileId: undefined,
      CountryId: undefined,
      DealerId: undefined,
      TotalPurchasePrice: undefined,
      DateOfPurchase: undefined,
      CounterHandName: undefined,
      CounterHandCellNumber: undefined,
      UserEmail: undefined,
      VehicleName: undefined,
      VehicleLicenceNumber: undefined,
      VehicleId: undefined,
      CurrentVehicleMileage: undefined,
      WheelAlignmentCertificatePhotoUrl: undefined,
      InvoicePhotoUrl: undefined,
      TreadPattern: undefined,
      TyreSize: undefined,
      TyreInfo: this.tyres,
    };

    this.getCurrencies();
    this.getPatterns();
  }

  validateWarranty(warranty: WarrantyModelAddWarrantyModel) {
    var errorContainer = document.getElementById(
      "warrantySaveValidationErrorsContainer"
    );
    if (errorContainer != null && errorContainer != undefined) {
      errorContainer.textContent = "";
    }
    var errors = [];

    if (
      warranty.TotalPurchasePrice == undefined ||
      warranty.TotalPurchasePrice < 0
    ) {
      errors.push("Please enter valid value for Total Tyre Price");
    }
    if (
      warranty.InvoicePhotoUrl == undefined ||
      warranty.InvoicePhotoUrl == ""
    ) {
      errors.push("Please upload a valid Invoice Photo");
    }
    if (
      warranty.WheelAlignmentCertificatePhotoUrl == undefined ||
      warranty.WheelAlignmentCertificatePhotoUrl == ""
    ) {
      errors.push("Please upload a valid Wheel Alignment Photo");
    }
    if (
      warranty.DealerId == null ||
      warranty.DealerId == undefined ||
      warranty.DealerId < 1
    ) {
      errors.push("Please select a Dealer from the list of provided Dealers");
    }
    if (
      this.purchaseDate == undefined ||
      this.purchaseDate == null ||
      this.purchaseDate == ""
    ) {
      errors.push("Please enter a valid Date of Purchase");
    }
    if (warranty.VehicleName == undefined || warranty.VehicleName == "") {
      errors.push("Please specify a valid Vehicle");
    }
    if (
      warranty.CurrentVehicleMileage == undefined ||
      warranty.CurrentVehicleMileage < 0
    ) {
      errors.push(
        "Please enter a valid value for Vehicle Mileage At Time of Purchase"
      );
    }
    if (warranty.TyreInfo != undefined && warranty.TyreInfo.length > 0) {
      for (var i = 0; i < warranty.TyreInfo?.length; i++) {
        if (
          warranty.TyreInfo[i]?.SerialNumber == undefined ||
          warranty.TyreInfo[i]?.SerialNumber == "" ||
          warranty.TyreInfo[i]?.ManufactureDate == undefined ||
          warranty.TyreInfo[i]?.ManufactureDate == ""
        ) {
          errors.push(
            "Please enter the DOT Number and Manufacture Date for all 4 tyres"
          );
          break;
        }
      }
    }
    if (
      this.termsAndDetailsConfirmed == undefined ||
      this.termsAndDetailsConfirmed === false
    ) {
      errors.push("Please accepts the Terms and Conditions to save the policy");
    }

    if (errors.length > 0) {
      for (var i = 0; i < errors.length; i++) {
        var p = document.createElement("p");
        p.style.color = "red";
        p.textContent = errors[i];
        errorContainer?.append(p);
      }
      return false;
    }
    return true;
  }

  validateSelectedDealer(dealerName?: string) {
    for (var i = 0; i < this.dealers.length; i++) {
      if (this.dealers[i].Name === dealerName) {
        return this.dealers[i];
      }
    }
    return null;
  }

  clearContainerMessages() {
    try {
      var saveErrors = document.getElementById(
        "warrantySaveValidationErrorsContainer"
      );
      var submitErrors = document.getElementById(
        "warrantySubmissionMessagesContainer"
      );
      if (saveErrors != null && saveErrors != undefined) {
        saveErrors.textContent = "";
      }
      if (submitErrors != null && submitErrors != undefined) {
        submitErrors.textContent = "";
      }
    } catch (errors) {
      console.log(errors);
    }
  }

  saveWarranty() {
    this.warrantyUpload.CountryId = this.currency?.CountryId;
    this.warrantyUpload.DealerId = this.dealer?.SourceId;
    this.warrantyUpload.TotalPurchasePrice = this.warranty.TotalPurchasePrice;
    this.warrantyUpload.DateOfPurchase = this.warranty.DateOfPurchase;
    this.warrantyUpload.CounterHandName = this.warranty.CounterHandName;
    this.warrantyUpload.CounterHandCellNumber =
      this.warranty.CounterHandCellNumber;
    this.warrantyUpload.UserEmail = this.user.Email;

    // If Else-If is for the ability to switch between input from droplist/ textfield
    if (this.isNewVehicle == true) {
      // User entering new vehicle - use tempVehicle object
      const createTempNewVehicle: VehiclesModelsVehicleModel =
        this.createNewVehicleObject();
      this.warrantyUpload.VehicleName = createTempNewVehicle?.Name;
      this.warrantyUpload.VehicleLicenceNumber =
        createTempNewVehicle?.LicenseNumber;
      this.warrantyUpload.VehicleId = undefined;
    } else if (this.isNewVehicle == false) {
      // User selecting an existing vehicle
      this.warrantyUpload.VehicleName = this.vehicle?.Name;
      this.warrantyUpload.VehicleLicenceNumber = this.vehicle?.LicenseNumber;
      this.warrantyUpload.VehicleId = this.vehicle?.Id;
    }

    this.warrantyUpload.CurrentVehicleMileage =
      this.warranty.CurrentVehicleMileage;
    this.warrantyUpload.TreadPattern = this.tyrePattern?.Tread;
    this.warrantyUpload.TyreSize = this.tyreSize?.Size;

    this.tyres.forEach((t) => {
      t.TyreSize = this.tyreSize?.Size;
      t.TreadPattern = this.tyrePattern?.Tread;
    });

    this.warrantyUpload.TyreInfo = this.tyres;

    var apiDiv = document.getElementById("warrantySubmissionMessagesContainer");
    if (apiDiv != null && apiDiv != undefined) {
      apiDiv.textContent = "";
    }

    if (!this.validateWarranty(this.warrantyUpload)) {
      return;
    }

    if (this.newWarranty) {
      this.visibleSpinner = true;

      Warranty.apiPrivateWarrantiesCreatePost(this.warrantyUpload)
        .then((res) => {
          this.visibleSpinner = false;
          console.log(res);
          if (res) {
            this.cancelChanges();
            if (this.isMobile) {
              router.push({ name: "Warranty" });
            }
            this.$emit("updateDetails");
          } else {
            document.getElementById("triggerSubmissonModal")!.click();
          }
          if (apiDiv != undefined) {
            var p = document.createElement("p");
            p.textContent =
              "Succesfully created warranty: " + res.data.ReferenceNumber;
            p.style.fontWeight = "bold";
            apiDiv?.append(p);
          }
        })
        .catch((error) => {
          this.visibleSpinner = false;
          console.error(error);
          if (apiDiv != undefined) {
            var p = document.createElement("p");
            p.textContent =
              "Failed to create warranty: " + error.response?.data;
            p.style.fontWeight = "bold";
            apiDiv?.append(p);
          }
        });
    } else {
      if (this.warranty?.Status?.toLowerCase() == "awaiting correction") {
        if (this.warranty?.Id == undefined) {
          return;
        }
        this.visibleSpinner = true;
        Warranty.apiPrivateWarrantiesEditByWarrantyIdPost(
          this.warranty.Id,
          this.warrantyUpload
        )
          .then((res) => {
            this.visibleSpinner = false;
            console.log(res);

            if (res) {
              this.cancelChanges();
              if (this.isMobile) {
                router.push({ name: "Warranty" });
              }
              this.$emit("updateDetails");
            } else {
              document.getElementById("triggerSubmissonModal")!.click();
            }
            if (apiDiv != undefined) {
              var p = document.createElement("p");
              p.textContent =
                "Succesfully edited warranty: " + res.data.ReferenceNumber;
              p.style.fontWeight = "bold";
              apiDiv?.append(p);
            }
          })
          .catch((error) => {
            this.visibleSpinner = false;
            console.error(error);
            if (apiDiv != undefined) {
              var p = document.createElement("p");
              p.textContent =
                "Failed to edit warranty: " + error.response?.data;
              p.style.fontWeight = "bold";
              apiDiv?.append(p);
            }
          });
      }
    }
    this.$emit("clearFields");
  }

  logMaintenance() {
    this.isClaim = false;
    this.isMaintenance = true;
    this.isMaintenanceDetail = false;
    this.maintenanceList = [];
    this.maintenanceDetail = null;
  }

  claimWarranty() {
    this.isClaim = true;
    this.isMaintenance = false;
    this.isMaintenanceDetail = false;
    this.maintenanceList = [];
    this.maintenanceDetail = null;
  }

  cancelClaim() {
    this.isClaim = false;
    this.isMaintenance = false;
    this.isMaintenanceDetail = false;
    this.maintenanceList = [];
    this.maintenanceDetail = null;
  }

  cancelLog() {
    this.isClaim = false;
    this.isMaintenance = false;
    this.isMaintenanceDetail = false;
    this.maintenanceList = [];
    this.maintenanceDetail = null;
  }

  warrantyClaimed() {
    this.isClaim = false;
    this.isMaintenance = false;
    this.isMaintenanceDetail = false;
    this.maintenanceList = [];
    this.maintenanceDetail = null;
    if (this.isMobile) {
      //TODO do the mobile things
    } else {
      this.$emit("updateDetails");
    }
  }

  maintenanceLogged() {
    this.isClaim = false;
    this.isMaintenance = false;
    this.isMaintenanceDetail = false;
    if (this.isMobile) {
      //TODO do the mobile things
    } else {
      this.$emit("updateDetails");
    }
  }

  getCurrencies() {
    this.visibleSpinner = true;
    Warranty.apiPrivateWarrantiesCurrenciesGetGet()
      .then((res) => {
        this.currencies = res.data as Array<WarrantyModelCurrency>;
        this.visibleSpinner = false;
        this.currency = this.currencies[3] ?? undefined;
        if (this.warranty.CountryId) {
          this.currencies.forEach((c) => {
            if (c.CountryId == this.warranty.CountryId) {
              this.currency = c;
            }
          });
        }
        this.getDealers();
      })
      .catch((error) => {
        console.log(error);
        this.visibleSpinner = false;
      });
  }

  getDealers() {
    if (!this.currency) {
      return;
    }
    this.visibleSpinner = true;
    Dealers.apiPublicCountriesByCountryIdCountryDealersGet(
      this.currency?.MyTyresCountryId!,
      undefined,
      undefined,
      true
    )
      .then((res) => {
        this.dealers = res.data.Items as Array<DealersModelsDealerForMapModel>;
        this.visibleSpinner = false;
      })
      .catch((error) => {
        console.log(error);
        this.visibleSpinner = false;
      });
  }

  getVehicles() {
    this.visibleSpinner = true;
    Vehicles.apiPrivateVehiclesGet()
      .then((res) => {
        this.visibleSpinner = false;
        this.vehicles = res.data.Items as Array<VehiclesModelsVehicleModel>;
        this.vehicle = this.vehicles[0] ?? null;
        console.log(this.vehicles, "vehicles");
      })
      .catch((error) => {
        this.visibleSpinner = false;
        console.log(error);
      });
  }

  getPatterns() {
    this.visibleSpinner = true;
    Warranty.apiPrivateWarrantiesTreadPatternsGetGet()
      .then((res) => {
        this.tyrePatterns =
          res.data as Array<WarrantyModelTreadPatternLookUpModel>;
        this.tyrePattern = this.tyrePatterns[0] ?? null;
        console.log(this.tyrePatterns, "patterns");
        this.getSizes();
      })
      .catch((error) => {
        this.visibleSpinner = false;
        console.log(error);
      });
  }

  getSizes() {
    this.visibleSpinner = true;
    Warranty.apiPrivateWarrantiesTyreSizesGetGet(this.tyrePattern?.Tread)
      .then((res) => {
        this.visibleSpinner = false;
        this.tyreSizes = res.data as Array<WarrantyModelTyreSizeLookupModel>;
        this.tyreSize = this.tyreSizes[0] ?? null;
        this.tyreSizes.forEach((s) => {
          if (s.Size == this.warranty.TyreSize) {
            this.tyreSize = s;
          }
        });
        console.log(this.tyreSizes, "sizes");
      })
      .catch((error) => {
        this.visibleSpinner = false;
        console.log(error);
      });
  }

  clearVehicleInput() {
    //Switching between vehicle list and text field - clear any previoulsy stored values
    if (this.isNewVehicle == true) this.vehicle = null;
    else if (this.isNewVehicle == false) this.newVehicleName = null;
  }

  createNewVehicleObject(): VehiclesModelsVehicleModel {
    // User opted to type in a vehicle name instead of select an existing vehicle - instantiate temporary vehicle object

    const tempNewVehicle: VehiclesModelsVehicleModel = {
      //Required Field (Name) - set to Vehicle Name from text field or "Name_Temp" if null
      Name:
        this.newVehicleName !== null && this.newVehicleName !== undefined
          ? this.newVehicleName
          : "Name_Temp",
      //Required Field (LicenseNumber) - Set to "LicenseNumber_Temp"
      LicenseNumber: "LicenseNumber_Temp",
      VinNumber: undefined,
      //Required Field (ExpiryDate) - Set to the current date
      ExpiryDate: new Date().toISOString(),
      NextReminderDate: undefined,
      ImageRelativeUrl: undefined,
      Make: undefined,
      MakeId: undefined,
      Model: undefined,
      ModelId: undefined,
      Specification: undefined,
      SpecificationId: undefined,
      EstimatedMonthlyMileage: undefined,
      Colour: undefined,
      VehicleType: undefined,
      EngineNumber: undefined,
      Tare: undefined,
    };

    // Log the created object (you can send it to an API or use it as needed)
    console.log(tempNewVehicle);

    // You can return the tempNewVehicle object if needed
    return tempNewVehicle;
  }
}
