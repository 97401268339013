
import { Options, Vue } from "vue-class-component";
import DashboardCard from "../components/DashboardCard.vue";

@Options({
  components: { DashboardCard },
})
export default class DashboardPage extends Vue {
  dashboardCardArray = [
    {
      id: 1,
      title: "MY PROFILE",
      comingSoon: false,
      lightSrc: "profile.png",
      darkSrc: "profile-dark.png",
      routeName: "MyProfile",
      className: "my-profile",
    },
    {
      id: 3,
      title: "MY VEHICLES & MY TYRES",
      comingSoon: false,
      lightSrc: "myvehicles.png",
      darkSrc: "myvehicles-dark.png",
      routeName: "MyVehicles",
      className: "my-vehicles",
    },
    // {
    //   id: 2,
    //   title: "MY TYRES",
    //   comingSoon: false,
    //   lightSrc: "mytyres.png",
    //   darkSrc: "mytyres-dark.png",
    //   routeName: "Login",
    //   className: "my-tyres",
    // },
    {
      id: 4,
      title: "INSTANT TYRE PRICING",
      comingSoon: false,
      lightSrc: "click2fit.png",
      darkSrc: "click2fit-dark.png",
      routeName: "https://www.dunloptyres.co.za/click-2-fit",
      className: "tyre-fitment",
    },
    {
      id: 5,
      title: "REGISTER / MANAGE INSURANCE POLICIES",
      comingSoon: false,
      lightSrc: "dunlopsure-insurance.png",
      darkSrc: "dunlopsure-insurance.png",
      routeName: "DunlopSure",
      className: "dunlop-sure",
    },
    {
      id: 6,
      title: "REGISTER / MANAGE YOUR WARRANTIES",
      comingSoon: false,
      lightSrc: "dunlopsure-mileage_warranty.png",
      darkSrc: "dunlopsure-mileage_warranty.png",
      routeName: "Warranty",
      className: "mileage-warranty",
    },
  ];
}
