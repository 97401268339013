import { store } from "@/store";
import axios, { AxiosStatic } from "axios";

// login call will use this
// import NodeRSA from 'node-rsa';
// const key = new NodeRSA(process.env.VUE_APP_PUBLIC_KEY!, 'pkcs1-public', {encryptionScheme:"pkcs1"});
// var encryptedPassword = key.encrypt(password, 'base64');

export function ConfigureAxios() {
  axios.interceptors.request.use(
    (req) => {
      const url = new URL(req.url!);
      if (url.pathname.includes("/api/private")) {
        const tkn = store.getters["auth/token"];
        req.headers!.Authorization = `Bearer ${tkn.access_token}`;
        console.log(`Adding Auth Header: ${url.pathname}`);
        return req;
      }

      if (!url.pathname.includes("/api/private")) return req;
      if (axios.defaults.headers.common["Authorization"]) return req;
      throw { message: "the token is not available" };
    },
    (error) => {
      console.log("ded");
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      console.info(response);
      return response;
    },
    async (error) => {
      const status = error.response ? error.response.status : null;
      if (status === 401) {
        return store.dispatch("auth/refresh").then((tkn) => {
          return axios.request(error.config);
        });
      }
      return Promise.reject(error);
    }
  );
}
