
import { MileageLogsModelsMileageLogModelWithId } from "@/api-client";
import { MileageLogs } from "@/network/api";
import { Options, Vue } from "vue-class-component";

@Options({
  props: { vehicleId: String },
  emits: ["cancel", "reload"],
})
export default class AddMileageLog extends Vue {
  mileageLogs: MileageLogsModelsMileageLogModelWithId = {
    Name: "",
    DistanceInKm: 0,
    TripDate: new Date().toISOString(),
  };
  vehicleId?: string;

  get parseDate() {
    console.log("returning parsed tyre date");
    return new Date(this.mileageLogs.TripDate);
  }

  expiryDateChanged(dateChangeEvent: InputEvent) {
    this.mileageLogs.TripDate =
      (dateChangeEvent.target as HTMLInputElement).value + "T00:00:00";
  }

  addMileageLog() {
    if (
      this.mileageLogs.Name &&
      this.mileageLogs.DistanceInKm >= 0 &&
      this.mileageLogs.TripDate
    ) {
      MileageLogs.apiPrivateVehiclesByVehicleIdMileageLogsPut(
        this.vehicleId!,
        this.mileageLogs
      )
        .then((res) => {
          console.log(res);
          this.$emit("reload");
          this.$emit("cancel");
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      document.getElementById("triggerSubmissonModal")!.click();
    }
  }
}
