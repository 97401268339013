import axios from "axios";
class FileService {
  upload(file: any, onUploadProgress: any) {
    const formData = new FormData();

    formData.append("file", file);

    const url = process.env.VUE_APP_ROOT_API + "/files";

    return axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }
}
export default new FileService();
