
import { Options, Vue } from "vue-class-component";
import NavBar from "@/components/NavBar.vue"; // @ is an alias to /src
import Footer from "@/components/Footer.vue";
import TyreSizingModal from "@/components/TyreSizingModal.vue";
import SubmissionErrorModal from "@/components/SubmissionErrorModal.vue";
import TyreManufactureInfoModal from "@/components/TyreManufactureInfoModal.vue";
import router from "./router";

@Options({
  components: {
    NavBar,
    Footer,
    TyreSizingModal,
    SubmissionErrorModal,
    TyreManufactureInfoModal,
  },
})
export default class App extends Vue {
  currentRoute = "Login";

  created() {
    router.afterEach(() => {
      this.currentRoute = router.currentRoute.value.name as string;
    });
  }
}
