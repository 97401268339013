
import { Vue } from "vue-class-component";
import { store } from "@/store";
import router from "@/router";
import { SocialProviders } from "../store/modules/auth";

export default class LoginPage extends Vue {
  email?: string | null = null;
  password?: string | null = null;
  loginError = false;

  fbResponse(data: any) {
    console.log("FB response", data);
    store
      .dispatch("auth/socialAuth", {
        provider: SocialProviders.Facebook,
        token: data.authResponse.accessToken,
      })
      .then(async (res) => {
        console.log("push route");
        const returnUrl = router.currentRoute.value.query[
          "returnUrl"
        ] as string;
        await router.push({ path: returnUrl || "/" });
        // router.push({ name: "Dashboard" });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  appleSuccess(data: any) {
    console.log(data);
    var fName = "";
    var lName = "";

    if (data.userData != null) {
      fName = data.userData.name.firstName;
      lName = data.userData.name.lastName;
    }

    store
      .dispatch("auth/socialAuth", {
        provider: SocialProviders.Apple,
        token: data.authorization.code,
        firstname: fName,
        lastname: lName,
      })
      .then(async (res) => {
        console.log("push route");
        const returnUrl = router.currentRoute.value.query[
          "returnUrl"
        ] as string;
        await router.push({ path: returnUrl || "/" });
        // router.push({ name: "Dashboard" });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async login() {
    await store
      .dispatch("auth/login", {
        email: this.email,
        password: this.password,
      })
      .then(async (res) => {
        const returnUrl = router.currentRoute.value.query[
          "returnUrl"
        ] as string;
        await router.push({ path: returnUrl || "/" });
      })
      .catch((err) => {
        this.loginError = true;
        console.error(this.loginError, "error true");
        console.error(err, "error true");
      });
  }

  googleLogin() {
    this.$gapi.login().then(({ currentUser, hasGrantedScopes }) => {
      const user = this.$gapi.getUserData();
      store
        .dispatch("auth/socialAuth", {
          provider: SocialProviders.Google,
          token: user!.idToken,
        })
        .then(async (res) => {
          console.log("push route");
          const returnUrl = router.currentRoute.value.query[
            "returnUrl"
          ] as string;
          await router.push({ path: returnUrl || "/" });
          // router.push({ name: "Dashboard" });
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }

  register() {
    store.dispatch("auth/register", {
      email: this.email,
      password: this.password,
    });
  }
}
