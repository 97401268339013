
import { TwelveMonthTyreInsuranceModelsPolicyListModel } from "@/api-client";
import { TwelveMonthTyreInsurance } from "@/network/api";
import { Options, Vue } from "vue-class-component";
import InsuranceDetails from "../components/InsuranceDetails.vue";
import Spinner from "../components/Spinner.vue";

@Options({
  components: { InsuranceDetails, Spinner },
})
export default class DunlopSurePage extends Vue {
  policies: Array<TwelveMonthTyreInsuranceModelsPolicyListModel> = [];
  selectedPolicyId?: string | null = null;
  selectedPolicy: TwelveMonthTyreInsuranceModelsPolicyListModel | null = null;
  isMobile = false;
  newPolicy = true;
  triggerNewPolicy = 0;
  visibleSpinner = false;

  created() {
    this.$watch("selectedPolicy", () => {
      this.selectedPolicyId = this.selectedPolicy?.Id;
    });
    this.loadInitialData();
  }

  changeSelectedPolicy(policy: TwelveMonthTyreInsuranceModelsPolicyListModel) {
    this.newPolicy = false;
    this.selectedPolicy = { ...policy };
    //this.loadInitialData();
  }

  newPolicyView() {
    // Clear selected policies and present new policy form
    this.selectedPolicy = {
      Id: "",
      PolicyNumber: "",
      Vehicle: "",
      Status: "",
      CustomerEmail: "",
    };
    this.triggerNewPolicy += 1;
    this.newPolicy = true;
  }

  clearPolicyView() {
    this.selectedPolicy = null;
    this.newPolicy = false;
    this.loadInitialData();
  }

  get clonedPolicy() {
    return { ...this.selectedPolicy };
  }

  loadInitialData() {
    this.visibleSpinner = true;
    setTimeout(() => {
      TwelveMonthTyreInsurance.apiPrivateTwelvemonthtyreinsuranceGetPoliciesGet()
        .then((res) => {
          this.policies =
            res.data as Array<TwelveMonthTyreInsuranceModelsPolicyListModel>;
          console.log(this.policies, "policies");
          this.visibleSpinner = false;
        })
        .catch((error) => {
          console.log(error);
          this.visibleSpinner = false;
        });
    }, 500);

    this.selectedPolicy = null;
    this.newPolicy = false;
  }
}
