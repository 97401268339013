
import { WarrantyModelWarrantyModel } from "@/api-client";
import { Warranty } from "@/network/api";
import { Options, Vue } from "vue-class-component";
import WarrantyDetails from "../components/WarrantyDetails.vue";
import Spinner from "../components/Spinner.vue";

@Options({
  components: { WarrantyDetails, Spinner },
})
export default class WarrantyPage extends Vue {
  warranties: Array<WarrantyModelWarrantyModel> = [];
  selectedWarrantyId?: string | null = null;
  selectedWarranty: WarrantyModelWarrantyModel | null = null;
  isMobile = false;
  newWarranty = false;
  displayForm = false;
  triggerNewWarranty = 0;
  visibleSpinner = false;

  created() {
    this.$watch("selectedWarranty", () => {
      this.selectedWarrantyId = this.selectedWarranty?.Id;
    });
    this.loadInitialData();
  }

  changeSelectedWarranty(policy: WarrantyModelWarrantyModel) {
    this.newWarranty = false;
    this.selectedWarranty = { ...policy };
    this.displayForm = true;
    //this.loadInitialData();
  }

  newWarrantyView() {
    this.selectedWarranty = null;
    this.triggerNewWarranty += 1;
    this.clearWarrantyView();
    this.newWarranty = true;
    this.displayForm = true;
  }

  clearWarrantyView() {
    this.selectedWarranty = null;
    this.newWarranty = false;
    this.loadInitialData();
  }

  get clonedWarranty() {
    return { ...this.selectedWarranty };
  }

  loadInitialData() {
    this.visibleSpinner = true;
    setTimeout(() => {
      Warranty.apiPrivateWarrantiesMineGet()
        .then((res) => {
          this.warranties = res.data as Array<WarrantyModelWarrantyModel>;
          console.log(this.warranties, "warranties");
          this.visibleSpinner = false;
        })
        .catch((error) => {
          console.log(error);
          this.visibleSpinner = false;
        });
    }, 5000);
    this.displayForm = false;
  }
}
